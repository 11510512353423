window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.ai_tutor_chat = window.locales.am.ai_tutor_chat || {};window.locales.am.ai_tutor_chat.ai_tutor_chat = window.locales.am.ai_tutor_chat.ai_tutor_chat || {};window.locales.am.ai_tutor_chat.ai_tutor_chat = {...window.locales.am.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "ስለ ኮርስ ሥራ ጥያቄ አለዎት? አጋዥ ስልጠና እና ተጨማሪ ልምምድ ለማግኘት ከ AI አማካሪ ጋር ይወያዩ። የውይይት <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">መልእክት ታሪክ</a> የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ።",
    "message_ai_tutor": "መልዕክት {botName}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.ai_tutor_chat = window.locales.ar.ai_tutor_chat || {};window.locales.ar.ai_tutor_chat.ai_tutor_chat = window.locales.ar.ai_tutor_chat.ai_tutor_chat || {};window.locales.ar.ai_tutor_chat.ai_tutor_chat = {...window.locales.ar.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "هل لديك سؤال حول الدورات الدراسية؟ قم بالدردشة مع AI Advisor للحصول على مساعدة تعليمية وممارسة إضافية. لاحظ أنه يمكن استخدام <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">سجل رسائل</a> المحادثة لتحسين تفاعلات الروبوت المستقبلية.",
    "message_ai_tutor": "الرسالة {botName}"
}
};window.locales.en = window.locales.en || {};window.locales.en.ai_tutor_chat = window.locales.en.ai_tutor_chat || {};window.locales.en.ai_tutor_chat.ai_tutor_chat = window.locales.en.ai_tutor_chat.ai_tutor_chat || {};window.locales.en.ai_tutor_chat.ai_tutor_chat = {...window.locales.en.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "Have a question? Chat with {botName} for help with coursework or extra practice. Note that conversation <a class='text-white underline' href='{faqUrl}' target='_blank'>message history</a> may be used to improve future bot interactions.",
    "message_ai_tutor": "Message {botName}"
}
};window.locales.es = window.locales.es || {};window.locales.es.ai_tutor_chat = window.locales.es.ai_tutor_chat || {};window.locales.es.ai_tutor_chat.ai_tutor_chat = window.locales.es.ai_tutor_chat.ai_tutor_chat || {};window.locales.es.ai_tutor_chat.ai_tutor_chat = {...window.locales.es.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "¿Tiene alguna pregunta sobre los cursos? Chatea con AI Advisor para obtener ayuda de tutoría y práctica adicional. Tenga en cuenta que <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">el historial de mensajes</a> de conversación se puede utilizar para mejorar futuras interacciones con el bot.",
    "message_ai_tutor": "Mensaje {botName}"
}
};window.locales.it = window.locales.it || {};window.locales.it.ai_tutor_chat = window.locales.it.ai_tutor_chat || {};window.locales.it.ai_tutor_chat.ai_tutor_chat = window.locales.it.ai_tutor_chat.ai_tutor_chat || {};window.locales.it.ai_tutor_chat.ai_tutor_chat = {...window.locales.it.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "Hai una domanda sui corsi? Chatta con AI Advisor per aiuto tutoraggio e pratica extra. Tieni presente che <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">la cronologia dei messaggi di</a> conversazione può essere utilizzata per migliorare le future interazioni dei bot.",
    "message_ai_tutor": "Messaggio {botName}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.ai_tutor_chat = window.locales.zh.ai_tutor_chat || {};window.locales.zh.ai_tutor_chat.ai_tutor_chat = window.locales.zh.ai_tutor_chat.ai_tutor_chat || {};window.locales.zh.ai_tutor_chat.ai_tutor_chat = {...window.locales.zh.ai_tutor_chat.ai_tutor_chat, ...{
    "welcome_message": "对课程作业有疑问吗？与 AI Advisor 聊天以获得辅导帮助和额外练习。请注意，对话<a class=\"text-white underline\" href=\"{faqUrl}\", target=\"_blank\">消息历史</a>记录可用于改进未来的机器人交互。",
    "message_ai_tutor": "消息{botName}"
}
};