import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'Tooltip';
import { AudioControlBarButton } from './AudioControlBarButton';

type Props = {
    onPress: () => void;
    disabled?: boolean;
};

export const EndButton: FC<Props> = ({ onPress, disabled }) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.audioControlBar' });
    return (
        <Tooltip content={t('endDiscussion')}>
            <div>
                <AudioControlBarButton onPress={onPress} disabled={disabled}>
                    <FontAwesomeIcon className="text-md" icon={faTimes} />
                    <span className="sr-only">{t('endDiscussion')}</span>
                </AudioControlBarButton>
            </div>
        </Tooltip>
    );
};
