/* eslint-disable max-lines-per-function */
import { generateGuid } from 'guid';
import { type AnyObject, type Nullable } from '@Types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type BotClientContext } from '../types/Context.types';
import { type OutgoingMessageInputs, type OutgoingMessage, type PretendAiMessage } from '../types/ChatMessage.types';
import { createOutgoingMessage } from '../utils/createOutgoingMessage';
import { isOutgoingMessage, isPretendAiMessage } from '../utils/ChatMessage';
import {
    getActiveConversationId,
    getActiveConversationMessages,
    getErroredAiMessage,
    getRetriableMessage,
    chatActions,
    getMessageIsInActiveConversationSelector,
} from '../redux/chat';
import { useAskTutorBot } from './useAskTutorBot';

// eslint-disable-next-line max-lines-per-function
export function useConversation(
    clientContext: BotClientContext,
    relevantCohortId: Nullable<string>,
    initialMessage?: OutgoingMessage | PretendAiMessage | null,
    endConversationOnClose?: boolean,
) {
    const dispatch = useDispatch();
    const { sendMessage, closeSocket, cancelCurrentStreamingMessage } = useAskTutorBot(clientContext);

    const activeConversationId = useSelector(getActiveConversationId);
    const chatHistory = useSelector(getActiveConversationMessages);

    const erroredAiMessage = useSelector(getErroredAiMessage);
    const retriableMessage = useSelector(getRetriableMessage);

    const startNewConversation = useCallback(() => {
        dispatch(
            chatActions.setActiveConversation({
                conversationId: generateGuid(),
                conversationMetadata: { uiContext: clientContext.uiContext },
            }),
        );
        closeSocket();
    }, [dispatch, closeSocket, clientContext.uiContext]);

    // This function has the wrong name now, but I don't know what else to call it
    // besides `sendMessage`, which would interfere with the `sendMessage` from
    // `useAskTutorBot`.
    const askUserMessage = useCallback(
        (inputs: OutgoingMessageInputs, supportTab?: boolean) => {
            if (!activeConversationId) {
                throw new Error('No active conversation');
            }
            if (erroredAiMessage) {
                // If we got here from `retry`, then the error message is already removed. But,
                // if the user decided to not retry the last message and instead enter a new one,
                // then we can get here when there is still a previous errored message. In that case,
                // remove the errored message so that the "retry" UI is removed from the screen.
                dispatch(chatActions.hideMessage({ messageId: erroredAiMessage.id }));
            }

            const message = createOutgoingMessage({
                inputs,
                conversationId: activeConversationId,
                contentType: 'text',
            });

            if (supportTab) {
                // add this to the message so that we can log where the message was sent from
                (message as AnyObject).supportTab = true;
            }

            // This message will be sent on the next tick. See `send queuedOutgoingMessage`
            sendMessage({
                message,
                relevantCohortId,
            });
        },
        [erroredAiMessage, dispatch, activeConversationId, sendMessage, relevantCohortId],
    );

    const retry = useCallback(() => {
        if (!retriableMessage) {
            throw new Error('No retriable message');
        }
        if (!activeConversationId) {
            throw new Error('No active conversation');
        }
        // There are 2 cases in which a user can retry a message:
        // 1. The last message was an AI message that errored out. In this case there will be an erroredAiMessage
        // 2. The user quit out of a conversation before getting a response, reopened the conversation,
        //      and clicked to resend their last message. In this case there will be no erroredAiMessage.
        //      (We haven't actually implemented the ability to resend in this situation yet, but there is a checkbox for it on https://trello.com/c/8UpltOpo)
        if (erroredAiMessage) dispatch(chatActions.hideMessage({ messageId: erroredAiMessage.id }));
        dispatch(chatActions.hideMessage({ messageId: retriableMessage.id }));
        // This message will be sent on the next tick. See `send queuedOutgoingMessage`
        sendMessage({
            message: createOutgoingMessage({
                inputs: {
                    content: retriableMessage.content,
                    role: retriableMessage.role,
                    payload: retriableMessage.payload,
                } as OutgoingMessageInputs,
                conversationId: activeConversationId,
                isRetryOfMessageId: retriableMessage.id,
                contentType: retriableMessage.contentType,
            }),
            relevantCohortId,
        });
    }, [retriableMessage, erroredAiMessage, dispatch, activeConversationId, sendMessage, relevantCohortId]);

    // Open a new conversation if we don't have one, and close the active conversation when the component unmounts
    // if endConversationOnClose is true
    useEffect(() => {
        if (!activeConversationId) {
            dispatch(
                chatActions.setActiveConversation({
                    conversationId: generateGuid(),
                    conversationMetadata: { uiContext: clientContext.uiContext },
                }),
            );
        }
    }, [dispatch, activeConversationId, clientContext.uiContext]);

    // unmount
    useEffect(
        () => () => {
            closeSocket().then(() => {
                if (endConversationOnClose) {
                    dispatch(chatActions.reset());
                }
            });
        },
        [endConversationOnClose, closeSocket, dispatch],
    );

    const getInitialMessageIsInActiveConversationSelector = useMemo(
        () => getMessageIsInActiveConversationSelector(initialMessage?.id),
        [initialMessage],
    );
    const initialMessageIsInActiveConversation = useSelector(getInitialMessageIsInActiveConversationSelector);

    // send initial message
    useEffect(() => {
        if (initialMessageIsInActiveConversation) return;
        if (!activeConversationId) return;

        // Wait for the conversation to be initialized before trying to send the message
        if (isOutgoingMessage(initialMessage)) {
            sendMessage({
                message: initialMessage,
                relevantCohortId,
            });
        }

        if (isPretendAiMessage(initialMessage)) {
            dispatch(chatActions.addMessage({ message: initialMessage }));
        }
    }, [
        initialMessage,
        sendMessage,
        relevantCohortId,
        activeConversationId,
        chatHistory,
        initialMessageIsInActiveConversation,
        dispatch,
    ]);

    return {
        retry,
        askUserMessage,
        startNewConversation,
        cancelCurrentStreamingMessage,
    };
}
