import { type ErrorLogService } from 'ErrorLogging';
import { HelpScoutCollectionId } from '../types/AnswerSource.types';

const helpScoutCollectionTitles = {
    'Student Handbook': [
        HelpScoutCollectionId.QuanticStudentHandbook,
        HelpScoutCollectionId.ValarStudentHandbook,
        HelpScoutCollectionId.ExecEdStudentHandbook,
    ],
    'Student Resources': [
        HelpScoutCollectionId.ValarStudentResources,
        HelpScoutCollectionId.ExecEdStudentResources,
        HelpScoutCollectionId.ValarResourcesStudentResources,
    ],
    // Public Quantic site uses these collection titles:
    'MBA & EMBA Resources': [HelpScoutCollectionId.QuanticStudentResourcesMbaEmba],
    'MSBA Resources': [HelpScoutCollectionId.QuanticStudentResourcesMsba],
    'MSSE Resources': [HelpScoutCollectionId.QuanticStudentResourcesMsse],
    // Restricted Quantic site uses these collection titles:
    'Student Resources - MBA & EMBA': [HelpScoutCollectionId.QuanticResourcesStudentResourcesMbaEmba],
    'Student Resources - MSBA': [HelpScoutCollectionId.QuanticResourcesStudentResourcesMsba],
    'Student Resources - MSSE': [HelpScoutCollectionId.QuanticResourcesStudentResourcesMsse],
    General: [
        HelpScoutCollectionId.QuanticGeneral,
        HelpScoutCollectionId.ValarGeneral,
        HelpScoutCollectionId.ExecEdGeneral,
    ],
    Applicants: [
        HelpScoutCollectionId.QuanticApplicants,
        HelpScoutCollectionId.ValarApplicants,
        HelpScoutCollectionId.ExecEdApplicants,
    ],
};

export function helpScoutCollectionTitle(
    collectionId: HelpScoutCollectionId,
    errorLogService: typeof ErrorLogService | null = null,
): string {
    let title = '';

    Object.entries(helpScoutCollectionTitles).forEach(([helpscoutCollectionTitle, includedCollectionIds]) => {
        if (includedCollectionIds.includes(collectionId)) {
            title = helpscoutCollectionTitle;
        }
    });

    if (title === '' && errorLogService) {
        errorLogService.notifyInProd(new Error(`Unknown HelpScout collection ID: ${collectionId}`), null, {
            collectionId,
        });
    }
    return title;
}
