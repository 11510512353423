import {
    UI_CONTEXT_EXPLAIN_SCREEN,
    UI_CONTEXT_LESSON_PLAYER_SUPPORT,
    UI_CONTEXT_REVIEW_LESSON,
    type BotUiContext,
} from 'TutorBotConversation';

export function isLessonPlayerAlgorithmUiContext(uiContext: BotUiContext): boolean {
    return [UI_CONTEXT_EXPLAIN_SCREEN, UI_CONTEXT_LESSON_PLAYER_SUPPORT, UI_CONTEXT_REVIEW_LESSON].includes(uiContext);
}
