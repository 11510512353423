import { getConversationMetadataMap } from 'TutorBotChat';
import { createSelector } from '@reduxjs/toolkit';
import { isLessonPlayerConversationMetadata } from '../typeGuards';
import { type LessonPlayerConversationMetadata } from '../TutorBotAiTutorChat.types';

// Filter the conversationMetadataMap to include only LessonPlayerConversationMetadata
export const getLessonPlayerConversationMetadataMap = createSelector(getConversationMetadataMap, metadataMap =>
    Object.entries(metadataMap).reduce((filteredMap, entry) => {
        const [conversationId, conversationMetadata] = entry;
        if (isLessonPlayerConversationMetadata(conversationMetadata)) {
            filteredMap[conversationId] = conversationMetadata;
        }
        return filteredMap;
    }, {} as Record<string, LessonPlayerConversationMetadata>),
);

export const getConversationIdForFrameId = (frameId: string) =>
    createSelector(
        getLessonPlayerConversationMetadataMap,
        metadataMap => Object.entries(metadataMap).find(([_, metadata]) => metadata.frameId === frameId)?.[0] || null,
    );
