import Button from 'Button';
import clsx from 'clsx';
import { type Ref } from 'react';
import { TUTOR_BOT_ROUND_BUTTON_SIZE } from 'TutorBotConversation';

type Props = {
    onPress?: () => void;
    children: React.ReactNode;
    controlState?: 'highlight' | 'default';
    disabled?: boolean;
    buttonRef?: Ref<React.ReactElement>;
    className?: string;
};

export function AudioControlBarButton({
    onPress,
    children,
    controlState = 'default',
    disabled,
    buttonRef,
    className,
}: Props) {
    return (
        <Button
            ref={buttonRef}
            className={clsx(TUTOR_BOT_ROUND_BUTTON_SIZE, 'border border-solid', {
                'border-slate-grey-200 bg-slate-grey-200 active:bg-slate-grey-300': controlState === 'default',
                'border-coral-100 bg-coral-100 active:bg-coral-200': controlState === 'highlight',
                className,
            })}
            isIconOnly
            onPress={onPress}
            color="default"
            radius="full"
            variant="flat"
            disableRipple
            isDisabled={disabled}
        >
            {children}
        </Button>
    );
}
