import { type ReactNode, memo } from 'react';

type Props = {
    spacingBeneathLowestButton: number;
    Main: ReactNode;
    Button: ReactNode;
    Bottom?: ReactNode;
};

function BottomAlignedWithButtonComponent({ Main, Button, Bottom, spacingBeneathLowestButton }: Props) {
    return (
        <div className="flex h-full flex-col justify-end">
            <div className="mb-3 flex flex-1 flex-col overflow-y-auto py-1 scrollbar-hide sm:mb-0">{Main}</div>

            <div>
                <div className="flex w-full justify-center">{Button}</div>

                <div className="flex w-full items-center justify-center" style={{ height: spacingBeneathLowestButton }}>
                    {Bottom || <span>&nbsp;</span>}
                </div>
            </div>
        </div>
    );
}

export const BottomAlignedWithButton = memo(
    BottomAlignedWithButtonComponent,
) as typeof BottomAlignedWithButtonComponent;
