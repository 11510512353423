import { useCurrentUser } from 'FrontRoyalAngular';
import { useStudentDashboardContent } from 'LearnerContentCache';
import { isIncluded } from 'ProgramInclusion';
import { useMemo } from 'react';
import { type StudentDashboardContentResponse } from 'StudentDashboard';
import { getProgramInclusion } from 'Users';

function useGetExamFromDashboardContent(dashboardContent: StudentDashboardContentResponse, includedInProgram: boolean) {
    return useMemo(() => {
        // We are only checking if the user has an active exam in their currently active program. This is probably
        // ok, since AI Advisor is only going to have access to the content from their currently active program. So, it
        // wouldn't work to switch to a different program and then try to ask questions about your exam.
        // We only check for active exams when users are included in programs so we don't lock AI Advisor for admins
        // who are testing exams.
        if (!includedInProgram) return null;

        const streams = dashboardContent.result.flatMap(resultObj => resultObj.lesson_streams) ?? [];
        const exams = streams.filter(s => s.exam);
        return exams.find(exam => exam.lesson_streams_progress?.inProgress) || null;
    }, [dashboardContent, includedInProgram]);
}

export function useInProgressExam() {
    const user = useCurrentUser();
    const includedInProgram = useMemo(() => isIncluded(getProgramInclusion(user)), [user]);
    const dashboardContent = useStudentDashboardContent();
    const inProgressExam = useGetExamFromDashboardContent(dashboardContent, includedInProgram);

    return { inProgressExam };
}
