import { type TutorBotChatLockedConfig } from './types/TutorBotChatStatusConfig.types';

export function getUnavailableConfig({ botName }: { botName: string }): TutorBotChatLockedConfig {
    return {
        isLocked: true as const,
        welcomeModalTexts: [{ key: 'chat.chat.locked_unavailable', params: { botName } }],
        greetingMessages: [],
        inputLabel: null,
    };
}
