window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.ai_advisor_chat = window.locales.am.ai_advisor_chat || {};window.locales.am.ai_advisor_chat.greeting_message = window.locales.am.ai_advisor_chat.greeting_message || {};window.locales.am.ai_advisor_chat.greeting_message = {...window.locales.am.ai_advisor_chat.greeting_message, ...{
    "niceToMeetYou": "ስለተዋወቅን ደስ ብሎኛል፣ {name}!",
    "getToKnowYou": "እርስዎን በማወቄ እና ለሚኖሯችሁ የአካዳሚክ ጥያቄዎች መልስ ለመስጠት ጓጉቻለሁ። የአካዳሚክ እና የድጋፍ ቡድኖቻችን እኔን ለመደገፍ እዚህ አሉ፣ ስለዚህ ከፈለጉ እነሱን ለማግኘት አያመንቱ።",
    "newAroundHere": "እኔም እዚህ አካባቢ አዲስ ነኝ፣ እና ክህሎቶቼ እያደጉ ናቸው፣ ስለዚህ ምን እያደረግኩ እንደሆነ ለማየት ደጋግመው ይመልከቱ!",
    "doYouHaveAQuestion": "ይህን ሁሉ ግምት ውስጥ በማስገባት ለእኔ አንድ ጥያቄ አለህ?",
    "helloAgain": "ሠላም እንደገና!",
    "goodDay": "እንደምን ዋልክ.",
    "welcomeBack": "እንኳን ደህና መጣህ!",
    "dayOfWeek": "መልካም {dayOfWeek}፣ እና እንኳን በደህና ተመልሰው መጡ!",
    "welcomeBackName": "እንኳን ደህና መጡ፣ {name}!",
    "hello": "ሀሎ!",
    "hi": "ሃይ!",
    "greetingsName": "ሰላም፣ {name}!",
    "hiThere": "ሃይ እንዴት ናችሁ!",
    "helloHuman": "ሰላም, የሰው ጓደኛ!",
    "morning": "ምልካም እድል!",
    "afternoon": "እንደምን አረፈድክ!",
    "evening": "አንደምን አመሸህ!",
    "salutations": "ሰላምታ!",
    "niceToSeeYou": "እንደገና በማየቴ ደስ ብሎኛል!",
    "helloAndNiceToSeeYou": "ሰላም፣ እና እንደገና በማየቴ ደስ ብሎኛል!",
    "niceToSeeYouName": "እንደገና በማየቴ ደስ ብሎኛል፣ {name}!",
    "greetingsAndHappyDay": "ሰላምታ እና መልካም {dayOfWeek}!",
    "greatToHaveYou": "{name}፣ እርስዎን በመመለስዎ በጣም ጥሩ!",
    "heyThere": "ሰላም፣ {name}!",
    "doYouHave": "ለእኔ ጥያቄ አለህ?",
    "whatCanI": "በምን ልረዳህ እችላለሁ?",
    "whatQuery": "የትኛው ጥያቄ ነው መንገዴን ያመጣህ?",
    "whatTopic": "የትኛውን ርዕስ ማሰስ ይፈልጋሉ?",
    "whatSubjectMatter": "ዛሬ የማወቅ ጉጉትህን ያነሳሳው የትኛው ርዕሰ ጉዳይ ነው?",
    "whatSubject": "ዛሬ ልንወያይበት የሚገባው ጉዳይ ምንድን ነው?",
    "whatTopicRequires": "ትኩረታችንን የሚፈልገው የትኛው ርዕስ ነው?",
    "howMayI": "ዛሬ የአካዳሚክ ጉዞዎን እንዴት መደገፍ እችላለሁ?",
    "anythingICanHelp": "ልረዳህ የምችለው ነገር አለ?",
    "whatQuestion": "ምን ጥያቄ አመጣህ?",
    "howCanI": "እንዴት ልረዳ እችላለሁ?",
    "whatInformation": "ዛሬ ምን መረጃ እንድታገኝ ልረዳህ እችላለሁ?",
    "areaOfInterest": "ማሰስ የሚፈልጉት የተወሰነ የፍላጎት ቦታ አለ?",
    "whatChallenges": "ምን አይነት ተግዳሮቶች አጋጥመውኛል እርዳታ ልሰጥባቸው እችላለሁ?",
    "whichConcept": "ወደ የትኛው ፅንሰ-ሀሳብ ወይም ርዕስ አንድ ላይ ለመጥለቅ ይፈልጋሉ?",
    "whatsOnYourMind": "ለማብራራት ወይም ለማብራራት የምረዳው በአእምሮዎ ውስጥ ያለው ምንድን ነው?",
    "0": "እሁድ",
    "1": "ሰኞ",
    "2": "ማክሰኞ",
    "3": "እሮብ",
    "4": "ሐሙስ",
    "5": "አርብ",
    "6": "ቅዳሜ",
    "anyQuestions": "ስለ ኮርስ ስራ ወይም ፕሮግራምዎ እኔ ልረዳው የምችለው ጥያቄ አለ?",
    "myMission": "ሰላም፣ {name} ! የእኔ ተልእኮ {institutionName} ተማሪዎች በትምህርታቸው እንዲሳካላቸው መርዳት ነው።",
    "haveWonderfulDay": "ግሩም ቀን ይሁንልህ! 👋",
    "hopeYouWillApply": "ለቀጣዩ ቡድናችን እንደሚያመለክቱ ተስፋ አደርጋለሁ! 👋",
    "completeInterview": "የመግቢያ ቃለ መጠይቁን ማጠናቀቅዎን አይርሱ፣ እና መልካም ቀን! 👋",
    "hopeYouWillJoin": "ከእኛ ጋር እንደምትቀላቀል ተስፋ አደርጋለሁ! 👍",
    "seeYouSoon": "አንግናኛለን! 👋",
    "congratulationsOnAcceptance": "ወደ {institutionName} ፣ {name} ስለተቀበልክ እንኳን ደስ አለህ! {botName} በ {startDate} ላይ በክፍል መጀመሪያ ላይ ይከፈታል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.ai_advisor_chat = window.locales.ar.ai_advisor_chat || {};window.locales.ar.ai_advisor_chat.greeting_message = window.locales.ar.ai_advisor_chat.greeting_message || {};window.locales.ar.ai_advisor_chat.greeting_message = {...window.locales.ar.ai_advisor_chat.greeting_message, ...{
    "niceToMeetYou": "تشرفت بلقائك ، {name}!",
    "getToKnowYou": "أنا متحمس للتعرف عليك والإجابة على أي أسئلة أكاديمية قد تكون لديك. فريقنا الأكاديمي وفريق الدعم موجودون هنا لدعمي ، لذلك لا تتردد في التواصل معهم إذا كنت بحاجة إلى ذلك.",
    "newAroundHere": "أنا أيضًا جديد هنا ، ومهاراتي مستمرة في النمو ، لذا تحقق مرة أخرى بشكل متكرر لمعرفة ما أنا بصدد القيام به!",
    "doYouHaveAQuestion": "مع وضع كل ذلك في الاعتبار ، هل لديك سؤال لي؟",
    "helloAgain": "مرحبا مجددا!",
    "goodDay": "يوم جيد.",
    "welcomeBack": "مرحبًا بعودتك!",
    "dayOfWeek": "{dayOfWeek} سعيد ، ومرحبا بكم من جديد!",
    "welcomeBackName": "مرحبًا بك مرة أخرى ، {name}!",
    "hello": "مرحبًا!",
    "hi": "أهلاً!",
    "greetingsName": "تحياتي ، {name}!",
    "hiThere": "أهلاً!",
    "helloHuman": "مرحبًا ، صديق بشري!",
    "morning": "صباح الخير!",
    "afternoon": "مساء الخير!",
    "evening": "مساء الخير!",
    "salutations": "التحية!",
    "niceToSeeYou": "جميل ان اراك مرة اخرى!",
    "helloAndNiceToSeeYou": "مرحبا ، ومن الجيد رؤيتك مرة أخرى!",
    "niceToSeeYouName": "تشرفت برؤيتك مرة أخرى ، {name}!",
    "greetingsAndHappyDay": "تحية طيبة {dayOfWeek} وسعيد!",
    "greatToHaveYou": "{name} ، رائع بعودتك!",
    "heyThere": "مرحبًا {name}!",
    "doYouHave": "هل لديك سؤال بالنسبة لي؟",
    "whatCanI": "بماذا يمكنني مساعدتك؟",
    "whatQuery": "ما هو الاستعلام الذي أتى بك إلى طريقي؟",
    "whatTopic": "ما الموضوع الذي ترغب في استكشافه؟",
    "whatSubjectMatter": "ما الموضوع الذي أثار فضولك اليوم؟",
    "whatSubject": "ما الموضوع الذي يجب أن نتناوله اليوم؟",
    "whatTopicRequires": "ما الموضوع الذي يتطلب انتباهنا؟",
    "howMayI": "كيف يمكنني دعم رحلتك الأكاديمية اليوم؟",
    "anythingICanHelp": "هل يمكنني مساعدتك في حل أي شيء؟",
    "whatQuestion": "ما السؤال الذي تحضره؟",
    "howCanI": "كيف يمكنني أن أقدم المساعدة؟",
    "whatInformation": "ما هي المعلومات التي يمكنني مساعدتك في الكشف عنها اليوم؟",
    "areaOfInterest": "هل هناك مجال اهتمام معين تود استكشافه؟",
    "whatChallenges": "ما التحديات التي تواجهها والتي يمكنني تقديم المساعدة بشأنها؟",
    "whichConcept": "ما هو المفهوم أو الموضوع الذي ترغب في الغوص فيه معًا؟",
    "whatsOnYourMind": "ما الذي يدور في ذهنك ويمكنني المساعدة في توضيحه أو شرحه؟",
    "0": "الأحد",
    "1": "الاثنين",
    "2": "يوم الثلاثاء",
    "3": "الأربعاء",
    "4": "يوم الخميس",
    "5": "جمعة",
    "6": "السبت",
    "anyQuestions": "هل لديك أي أسئلة حول الدورات الدراسية أو برنامجك يمكنني المساعدة فيها؟",
    "myMission": "مرحبًا {name} ! مهمتي هي مساعدة طلاب {institutionName} على النجاح في دراساتهم.",
    "haveWonderfulDay": "اتمنى لك يوم جميل! 👋",
    "hopeYouWillApply": "آمل أن تتقدم إلى مجموعتنا القادمة! 👋",
    "completeInterview": "لا تنس إكمال مقابلة القبول الخاصة بك، واستمتع بيوم رائع! 👋",
    "hopeYouWillJoin": "أتمنى أن تنضم إلينا! 👍",
    "seeYouSoon": "اراك قريبا! 👋",
    "congratulationsOnAcceptance": "أهنئك على قبولك في {institutionName} ، {name} ! يتم فتح {botName} عند بدء الفصل الدراسي في {startDate} ."
}
};window.locales.en = window.locales.en || {};window.locales.en.ai_advisor_chat = window.locales.en.ai_advisor_chat || {};window.locales.en.ai_advisor_chat.greeting_message = window.locales.en.ai_advisor_chat.greeting_message || {};window.locales.en.ai_advisor_chat.greeting_message = {...window.locales.en.ai_advisor_chat.greeting_message, ...{
                               
    "niceToMeetYou": "Nice to meet you, {name}!",
    "getToKnowYou": "I'm excited to get to know you and answer any academic questions you might have. Our Academic and Support teams are here to back me up, so don't hesitate to reach out to them if you need to.",
    "newAroundHere": "I'm also new around here, and my skills are continuing to grow, so check back frequently to see what I'm up to!",
    "doYouHaveAQuestion": "With all that in mind, do you have a question for me?",

                          
    "helloAgain": "Hello again!",
    "goodDay": "Good day.",
    "welcomeBack": "Welcome back!",
    "dayOfWeek": "Happy {dayOfWeek}, and welcome back!",
    "welcomeBackName": "Welcome back, {name}!",
    "hello": "Hello!",
    "hi": "Hi!",
    "greetingsName": "Greetings, {name}!",
    "hiThere": "Hi there!",
    "helloHuman": "Hello, human friend!",
    "morning": "Good morning!",
    "afternoon": "Good afternoon!",
    "evening": "Good evening!",
    "salutations": "Salutations!",
    "niceToSeeYou": "Nice to see you again!",
    "helloAndNiceToSeeYou": "Hello, and nice to see you again!",
    "niceToSeeYouName": "Nice to see you again, {name}!",
    "greetingsAndHappyDay": "Greetings and happy {dayOfWeek}!",
    "greatToHaveYou": "{name}, great to have you back!",
    "heyThere": "Hey there, {name}!",

                                 
    "doYouHave": "Do you have a question for me?",
    "whatCanI": "What can I assist you with?",
    "whatQuery": "What query brings you my way?",
    "whatTopic": "What topic would you like to explore?",
    "anyQuestions": "Any questions about coursework or your program that I can help with?",
    "whatSubjectMatter": "What subject matter has piqued your curiosity today?",
    "whatSubject": "What subject should we tackle today?",
    "whatTopicRequires": "What topic requires our attention?",
    "howMayI": "How may I support your academic journey today?",
    "anythingICanHelp": "Anything I can help you solve?",
    "whatQuestion": "What question do you bring?",
    "howCanI": "How can I help?",
    "whatInformation": "What information can I help you uncover today?",
    "areaOfInterest": "Is there a specific area of interest you'd like to explore?",
    "whatChallenges": "What challenges are you facing that I can provide assistance with?",
    "whichConcept": "Which concept or topic would you like to dive into together?",
    "whatsOnYourMind": "What's on your mind that I can help clarify or explain?",

                       
    "0": "Sunday",
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",

                                          
    "myMission": "Hi, {name}! My mission is to help {institutionName} students succeed in their studies.",
    "haveWonderfulDay": "Have a wonderful day! 👋",
    "hopeYouWillApply": "I hope you'll apply to our next cohort! 👋",
    "completeInterview": "Don't forget to complete your admissions interview, and have a wonderful day! 👋",
    "hopeYouWillJoin": "I hope you'll join us! 👍",
    "congratulationsOnAcceptance": "Congratulations on your acceptance to {institutionName}, {name}! {botName} unlocks at class start on {startDate}.",
    "seeYouSoon": "See you soon! 👋"
}
};window.locales.es = window.locales.es || {};window.locales.es.ai_advisor_chat = window.locales.es.ai_advisor_chat || {};window.locales.es.ai_advisor_chat.greeting_message = window.locales.es.ai_advisor_chat.greeting_message || {};window.locales.es.ai_advisor_chat.greeting_message = {...window.locales.es.ai_advisor_chat.greeting_message, ...{
    "niceToMeetYou": "¡Encantado de conocerte, {name}!",
    "getToKnowYou": "Estoy emocionado de conocerte y responder cualquier pregunta académica que puedas tener. Nuestros equipos académico y de apoyo están aquí para respaldarme, así que no dude en comunicarse con ellos si lo necesita.",
    "newAroundHere": "También soy nuevo por aquí y mis habilidades continúan creciendo, ¡así que visítanos con frecuencia para ver lo que estoy haciendo!",
    "doYouHaveAQuestion": "Con todo eso en mente, ¿tienes alguna pregunta para mí?",
    "helloAgain": "¡Hola de nuevo!",
    "goodDay": "Buen día.",
    "welcomeBack": "¡Bienvenido de nuevo!",
    "dayOfWeek": "¡Feliz {dayOfWeek} y bienvenido de nuevo!",
    "welcomeBackName": "¡Bienvenido de nuevo, {name}!",
    "hello": "¡Hola!",
    "hi": "¡Hola!",
    "greetingsName": "¡Saludos, {name}!",
    "hiThere": "¡Hola!",
    "helloHuman": "¡Hola, amigo humano!",
    "morning": "¡Buen día!",
    "afternoon": "¡Buenas tardes!",
    "evening": "¡Buenas noches!",
    "salutations": "¡Saludos!",
    "niceToSeeYou": "¡Qué gusto verte de nuevo!",
    "helloAndNiceToSeeYou": "¡Hola, y encantado de verte de nuevo!",
    "niceToSeeYouName": "¡Encantado de verte de nuevo, {name}!",
    "greetingsAndHappyDay": "¡Saludos y feliz {dayOfWeek}!",
    "greatToHaveYou": "{name}, ¡qué bueno tenerte de regreso!",
    "heyThere": "¡Hola, {name}!",
    "doYouHave": "Tienes alguna pregunta para mi?",
    "whatCanI": "¿En qué puedo ayudarte?",
    "whatQuery": "¿Qué consulta te trae por mi camino?",
    "whatTopic": "¿Qué tema te gustaría explorar?",
    "whatSubjectMatter": "¿Qué tema ha despertado tu curiosidad hoy?",
    "whatSubject": "¿Qué tema debemos abordar hoy?",
    "whatTopicRequires": "¿Qué tema requiere nuestra atención?",
    "howMayI": "¿Cómo puedo apoyar su viaje académico hoy?",
    "anythingICanHelp": "¿Algo que pueda ayudarte a resolver?",
    "whatQuestion": "que pregunta traes?",
    "howCanI": "¿Cómo puedo ayudar?",
    "whatInformation": "¿Qué información puedo ayudarte a descubrir hoy?",
    "areaOfInterest": "¿Hay un área específica de interés que le gustaría explorar?",
    "whatChallenges": "¿A qué desafíos se enfrenta con los que puedo brindarle asistencia?",
    "whichConcept": "¿En qué concepto o tema les gustaría profundizar juntos?",
    "whatsOnYourMind": "¿Qué tienes en mente que pueda ayudarte a aclarar o explicar?",
    "0": "Domingo",
    "1": "Lunes",
    "2": "Martes",
    "3": "Miércoles",
    "4": "Jueves",
    "5": "Viernes",
    "6": "Sábado",
    "anyQuestions": "¿Alguna pregunta sobre el trabajo del curso o su programa con la que pueda ayudar?",
    "myMission": "¡Hola, {name} ! Mi misión es ayudar a los estudiantes {institutionName} a tener éxito en sus estudios.",
    "haveWonderfulDay": "¡Ten un día maravilloso! 👋",
    "hopeYouWillApply": "¡Espero que postules a nuestra próxima cohorte! 👋",
    "completeInterview": "¡No olvides completar tu entrevista de admisión y que tengas un día maravilloso! 👋",
    "hopeYouWillJoin": "¡Espero que te unas a nosotros! 👍",
    "seeYouSoon": "¡Nos vemos pronto! 👋",
    "congratulationsOnAcceptance": "¡Felicitaciones por tu aceptación en {institutionName} , {name} ! {botName} se desbloquea al inicio de la clase el {startDate} ."
}
};window.locales.it = window.locales.it || {};window.locales.it.ai_advisor_chat = window.locales.it.ai_advisor_chat || {};window.locales.it.ai_advisor_chat.greeting_message = window.locales.it.ai_advisor_chat.greeting_message || {};window.locales.it.ai_advisor_chat.greeting_message = {...window.locales.it.ai_advisor_chat.greeting_message, ...{
    "niceToMeetYou": "Piacere di conoscerti, {name}!",
    "getToKnowYou": "Sono entusiasta di conoscerti e rispondere a qualsiasi domanda accademica che potresti avere. I nostri team accademici e di supporto sono qui per sostenermi, quindi non esitare a contattarli se necessario.",
    "newAroundHere": "Sono anche nuovo da queste parti e le mie capacità continuano a crescere, quindi controlla spesso per vedere cosa sto facendo!",
    "doYouHaveAQuestion": "Con tutto ciò in mente, hai una domanda per me?",
    "helloAgain": "Ciao di nuovo!",
    "goodDay": "Buona giornata.",
    "welcomeBack": "Bentornato!",
    "dayOfWeek": "Buon {dayOfWeek} e bentornato!",
    "welcomeBackName": "Bentornato, {name}!",
    "hello": "Ciao!",
    "hi": "CIAO!",
    "greetingsName": "Saluti, {name}!",
    "hiThere": "Ciao!",
    "helloHuman": "Ciao, amico umano!",
    "morning": "Buongiorno!",
    "afternoon": "Buon pomeriggio!",
    "evening": "Buonasera!",
    "salutations": "Saluti!",
    "niceToSeeYou": "Che bello rivederti!",
    "helloAndNiceToSeeYou": "Ciao, e piacere di rivederti!",
    "niceToSeeYouName": "Piacere di rivederti, {name}!",
    "greetingsAndHappyDay": "Saluti e felice {dayOfWeek}!",
    "greatToHaveYou": "{name}, è un piacere riaverti!",
    "heyThere": "Ciao, {name}!",
    "doYouHave": "Hai una domanda per me?",
    "whatCanI": "In cosa posso aiutarti?",
    "whatQuery": "Quale domanda ti porta a modo mio?",
    "whatTopic": "Quale argomento vorresti approfondire?",
    "whatSubjectMatter": "Quale argomento ha stuzzicato la tua curiosità oggi?",
    "whatSubject": "Quale argomento dobbiamo affrontare oggi?",
    "whatTopicRequires": "Quale argomento richiede la nostra attenzione?",
    "howMayI": "Come posso sostenere il tuo percorso accademico oggi?",
    "anythingICanHelp": "Qualcosa che posso aiutarti a risolvere?",
    "whatQuestion": "Che domanda porti?",
    "howCanI": "Come posso aiutare?",
    "whatInformation": "Quali informazioni posso aiutarti a scoprire oggi?",
    "areaOfInterest": "C'è un'area di interesse specifica che vorresti esplorare?",
    "whatChallenges": "Quali sfide stai affrontando per le quali posso fornire assistenza?",
    "whichConcept": "In quale concetto o argomento vorreste tuffarvi insieme?",
    "whatsOnYourMind": "Cosa hai in mente che posso aiutarti a chiarire o spiegare?",
    "0": "Domenica",
    "1": "Lunedi",
    "2": "Martedì",
    "3": "Mercoledì",
    "4": "Giovedì",
    "5": "Venerdì",
    "6": "Sabato",
    "anyQuestions": "Hai domande sui corsi o sul tuo programma con cui posso aiutarti?",
    "myMission": "Ciao, {name} ! La mia missione è aiutare gli studenti {institutionName} ad avere successo nei loro studi.",
    "haveWonderfulDay": "Buona giornata! 👋",
    "hopeYouWillApply": "Spero che farai domanda per il nostro prossimo gruppo! 👋",
    "completeInterview": "Non dimenticare di completare il colloquio di ammissione e trascorri una splendida giornata! 👋",
    "hopeYouWillJoin": "Spero che ti unirai a noi! 👍",
    "seeYouSoon": "Arrivederci! 👋",
    "congratulationsOnAcceptance": "Congratulazioni per la tua accettazione in {institutionName} , {name} ! {botName} si sblocca all'inizio del corso il {startDate} ."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.ai_advisor_chat = window.locales.zh.ai_advisor_chat || {};window.locales.zh.ai_advisor_chat.greeting_message = window.locales.zh.ai_advisor_chat.greeting_message || {};window.locales.zh.ai_advisor_chat.greeting_message = {...window.locales.zh.ai_advisor_chat.greeting_message, ...{
    "niceToMeetYou": "很高兴认识你，{name}！",
    "getToKnowYou": "我很高兴认识你并回答你可能有的任何学术问题。我们的学术和支持团队随时为我提供支持，所以如果您需要，请随时与他们联系。",
    "newAroundHere": "我也是新来的，我的技能在不断提高，所以请经常回来查看我在做什么！",
    "doYouHaveAQuestion": "考虑到所有这些，你有什么问题要问我吗？",
    "helloAgain": "再一次问好！",
    "goodDay": "再会。",
    "welcomeBack": "欢迎回来！",
    "dayOfWeek": "{dayOfWeek} 快乐，欢迎回来！",
    "welcomeBackName": "欢迎回来，{name}！",
    "hello": "你好！",
    "hi": "你好！",
    "greetingsName": "你好，{name}！",
    "hiThere": "你好呀！",
    "helloHuman": "你好，人类朋友！",
    "morning": "早上好！",
    "afternoon": "下午好！",
    "evening": "晚上好！",
    "salutations": "敬礼！",
    "niceToSeeYou": "很高兴再次见到你！",
    "helloAndNiceToSeeYou": "你好，很高兴再次见到你！",
    "niceToSeeYouName": "很高兴再次见到你，{name}！",
    "greetingsAndHappyDay": "问候和快乐 {dayOfWeek}！",
    "greatToHaveYou": "{name}，很高兴你回来了！",
    "heyThere": "嘿，{name}！",
    "doYouHave": "你有什么问题要问我吗？",
    "whatCanI": "我能帮你什么？",
    "whatQuery": "什么问题给你带来了我的方式？",
    "whatTopic": "您想探索什么主题？",
    "whatSubjectMatter": "今天是什么主题激起了您的好奇心？",
    "whatSubject": "我们今天应该处理什么主题？",
    "whatTopicRequires": "什么主题需要我们注意？",
    "howMayI": "我如何支持你今天的学术之旅？",
    "anythingICanHelp": "有什么我可以帮你解决的吗？",
    "whatQuestion": "你提出什么问题？",
    "howCanI": "我能提供什么帮助？",
    "whatInformation": "今天我能帮你揭开哪些信息？",
    "areaOfInterest": "是否有您想探索的特定兴趣领域？",
    "whatChallenges": "您面临哪些挑战，我可以提供帮助？",
    "whichConcept": "您想一起探讨哪个概念或主题？",
    "whatsOnYourMind": "你有什么想法我可以帮助澄清或解释？",
    "0": "星期日",
    "1": "周一",
    "2": "周二",
    "3": "周三",
    "4": "周四",
    "5": "星期五",
    "6": "周六",
    "anyQuestions": "关于课程作业或您的项目有任何问题我可以帮助解决吗？",
    "myMission": "嗨， {name} ！我的使命是帮助{institutionName}学生取得学业成功。",
    "haveWonderfulDay": "祝你有美好的一天！ 👋",
    "hopeYouWillApply": "我希望你能申请加入我们的下一批！ 👋",
    "completeInterview": "别忘了完成你的招生面试，祝你有美好的一天！ 👋",
    "hopeYouWillJoin": "我希望你能加入我们！ 👍",
    "seeYouSoon": "再见！ 👋",
    "congratulationsOnAcceptance": "恭喜您被{institutionName}录取， {name} ！ {botName}将于{startDate}课程开始时解锁。"
}
};