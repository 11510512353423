import { useCallback, useEffect, useState } from 'react';
import { type Participant, RoomEvent } from 'livekit-client';
import { type ConversationContext, type BotUiContext, logTutorBotMessage } from 'TutorBotConversation';
import { type Dispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { angularInjectorProvider } from 'Injector';
import { isRemoteParticipant } from '../../utils/participant';
import { createTranscribedAudioMessage } from '../../utils/AudioTranscriptMessage';
import {
    type TranscribedAudioMessageSentEventExtraPayloadProperties,
    type VoiceAgentMessageSentEventExtraPayloadProperties,
    type TranscribedAudioMessage,
} from '../../TutorBotVoiceAgent.types';
import { voiceAgentSlice } from '../../redux/voiceAgent/voiceAgentSlice';
import { getAlgorithm, getBotVersion, getConversationContext, getVoiceAgentMessages } from '../../redux/voiceAgent';
import { type TranscriptionMessage } from './useStoreAudioTranscriptions.types';
import { useRoomEventListener } from '../useRoomEventListener';

// ConversationMessagesState simply bundles up a bunch of properties that are defined at
// the top level of the hook and then need to be bucket-brigaded down so that we're not
// repeating the same long list of arguments over and over
type ConversationMessagesState = {
    allTranscriptionMessages: TranscriptionMessage[];
    storedTutorBotMessages: TranscribedAudioMessage[];
    uiContext: BotUiContext;
    msBeforeAssumingMessageIsComplete: number;
    conversationContext: ConversationContext | undefined;
    botVersion: string | undefined;
    algorithm: string | undefined;
    dispatch: Dispatch;
};

type ValidConversationMessagesState = Omit<
    ConversationMessagesState,
    'conversationContext' | 'botVersion' | 'algorithm'
> & {
    conversationContext: NonNullable<ConversationMessagesState['conversationContext']>;
    botVersion: NonNullable<ConversationMessagesState['botVersion']>;
    algorithm: NonNullable<ConversationMessagesState['algorithm']>;
};

function isValidConversationMessagesState(
    conversationMessagesState: ConversationMessagesState,
): conversationMessagesState is ValidConversationMessagesState {
    return (
        conversationMessagesState.conversationContext !== undefined &&
        conversationMessagesState.botVersion !== undefined &&
        conversationMessagesState.algorithm !== undefined
    );
}

/*
useStoreTranscriptionMessages is the third and final step in storing audio transcription messages.

We have already listened for the events from livekit and combined the segments into messages
for each participant. useStoreTranscriptionMessages determines when the messages are complete
and then stores them in redux and logs them via EventLogger
*/

function max(arr: number[]): number {
    return arr.sort().reverse()[0];
}

// We never really know if another segment is coming, but if nothing has been updated for 5 seconds,
// then we will assume that this message is complete.
// There could be edge cases like losing a network or something where 5 seconds is not long enough, and
// more text comes in for a message after we've already logged it. But, the amount of time that can pass
// in the normal case is limited because:
//      1. If this is a human message, then when the human stops talking, the agent will start talking. This will mark the
//          end of this human message.
//      2. If this is an agent message, then the agent will never stop talking for a long time and then start up again.
const DEFAULT_MS_BEFORE_ASSUMING_MESSAGE_IS_COMPLETE = 5 * 1000;
function messageIsProbablyComplete(message: TranscriptionMessage, msBeforeAssumingMessageIsComplete: number): boolean {
    const fiveSecondsAgo = Date.now() - msBeforeAssumingMessageIsComplete;
    const lastReceivedTime = max(message.segments.map(s => s.lastReceivedTime));
    if (lastReceivedTime > fiveSecondsAgo) return false;
    return true;
}

function messageHasNonFinalSegments(message: TranscriptionMessage): boolean {
    return message.segments.some(s => !s.final);
}

function getExtraPayloadProperties({
    participant,
    conversationContext,
    botVersion,
    algorithm,
    hasNonFinalSegments,
}: {
    participant: Participant;
    conversationContext: ConversationContext;
    botVersion: string;
    algorithm: string;
    hasNonFinalSegments: boolean;
}): TranscribedAudioMessageSentEventExtraPayloadProperties {
    const baseProperties = { conversationContext };
    if (isRemoteParticipant(participant)) {
        const extraPayloadProperties: VoiceAgentMessageSentEventExtraPayloadProperties = {
            ...baseProperties,
            botVersion,
            algorithm,

            // If the message has non-final segments when we try to log it, then empirically it seems that either
            // 1. The ai was interrupted by the human
            // 2. The ai was interrupted by the end of the conversation
            canceled: hasNonFinalSegments ? 'interrupted' : undefined,
        };
        return extraPayloadProperties;
    }
    return baseProperties;
}

function storeTranscriptionMessage({
    transcriptionMessage,
    conversationMessagesState,
}: {
    transcriptionMessage: TranscriptionMessage;
    conversationMessagesState: ConversationMessagesState;
}) {
    const { uiContext, dispatch } = conversationMessagesState;
    const conversationContext = conversationMessagesState.conversationContext || {};
    const botVersion = conversationMessagesState.botVersion || 'unknown';
    const algorithm = conversationMessagesState.algorithm || 'unknown';

    const message: TranscribedAudioMessage = createTranscribedAudioMessage({
        // surprisingly, transcriptionMessage.id is not a uuid. See comment in
        // mergeTranscriptionSegmentsIntoMessages.ts -> initializeTranscriptionMessage
        id: transcriptionMessage.id,
        role: isRemoteParticipant(transcriptionMessage.participant) ? 'ai' : 'human',
        conversationId: transcriptionMessage.conversationId,
        content: transcriptionMessage.text.trim(), // remove trailing whitespace
        conversationContext,
    });
    logTutorBotMessage({
        message,
        activeConversationId: transcriptionMessage.conversationId,
        uiContext,
        extraPayloadProperties: getExtraPayloadProperties({
            participant: transcriptionMessage.participant,
            conversationContext,
            botVersion,
            algorithm,
            hasNonFinalSegments: messageHasNonFinalSegments(transcriptionMessage),
        }),
    });
    dispatch(voiceAgentSlice.actions.addMessage({ message }));
}

function storeTranscriptionMessages({
    conversationMessagesState,
    onlyStoreCompleteMessages,
}: {
    conversationMessagesState: ConversationMessagesState;
    onlyStoreCompleteMessages: boolean;
}) {
    const { storedTutorBotMessages, allTranscriptionMessages, msBeforeAssumingMessageIsComplete } =
        conversationMessagesState;

    const storedIds = storedTutorBotMessages.map(m => m.id);
    const unstoredTranscriptionMessages = allTranscriptionMessages.filter(m => !storedIds.includes(m.id));

    let incompleteMessageSkipped = false;
    unstoredTranscriptionMessages.forEach(transcriptionMessage => {
        // I don't know if this can happen in the wild, but here is the danger I'm trying to avoid:
        //  1. A segment is updated for message 1, and marked as non-final
        //  2. A segment is updated for message 2
        //  3. After the first segment for message 2 is updated, a further update comes in for message 1
        //  4. After a timeout, we observe that message 2 has not been updated in a while, so we log it
        //     but we do not yet log message 1
        //  5. A bit later we log message 1, but out of order.
        // This check on incompleteMessageSkipped makes it so that at #4, since message 1 was incomplete,
        //      we would wait before logging message 2. See spec for 'when an older message was updated recently but a newer one was not'
        if (incompleteMessageSkipped) return;
        if (
            onlyStoreCompleteMessages &&
            !messageIsProbablyComplete(transcriptionMessage, msBeforeAssumingMessageIsComplete)
        ) {
            incompleteMessageSkipped = true;
            return;
        }

        storeTranscriptionMessage({
            transcriptionMessage,
            conversationMessagesState,
        });
    });
}

function useStoreCompleteMessagesEveryFewSeconds({
    msBeforeAssumingMessageIsComplete,
    storeMessagesCallback,
    lastStoreCallAt,
}: {
    msBeforeAssumingMessageIsComplete: number;
    lastStoreCallAt: Date;
    storeMessagesCallback: (params: { onlyStoreCompleteMessages: boolean }) => void;
}) {
    useEffect(() => {
        const storeCompleteTranscriptionMessages = () => storeMessagesCallback({ onlyStoreCompleteMessages: true });
        if (new Date().getTime() - lastStoreCallAt.getTime() > msBeforeAssumingMessageIsComplete) {
            storeCompleteTranscriptionMessages();
        }
        const timeToNextCall = msBeforeAssumingMessageIsComplete - (new Date().getTime() - lastStoreCallAt.getTime());
        const timeoutId = setTimeout(storeCompleteTranscriptionMessages, timeToNextCall);

        return () => clearTimeout(timeoutId);
    }, [storeMessagesCallback, lastStoreCallAt, msBeforeAssumingMessageIsComplete]);
}

function maybeStoreTranscriptionMessages({
    conversationMessagesState,
    onlyStoreCompleteMessages,
}: {
    conversationMessagesState: ConversationMessagesState;
    onlyStoreCompleteMessages: boolean;
}) {
    if (conversationMessagesState.allTranscriptionMessages.length === 0) return;

    if (!isValidConversationMessagesState(conversationMessagesState)) {
        const ErrorLogService = angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService');
        ErrorLogService.notifyInProd(
            'storeMessagesCallback triggered without a valid conversationMessagesState',
            null,
            {
                conversationContext: conversationMessagesState.conversationContext,
                botVersion: conversationMessagesState.botVersion,
                algorithm: conversationMessagesState.algorithm,
            },
        );
    }

    storeTranscriptionMessages({ conversationMessagesState, onlyStoreCompleteMessages });
}

function useConversationMessagesState({
    conversationId,
    allTranscriptionMessages,
    uiContext,
    msBeforeAssumingMessageIsComplete,
}: {
    conversationId: string | null;
    allTranscriptionMessages: TranscriptionMessage[];
    uiContext: BotUiContext;
    msBeforeAssumingMessageIsComplete: number;
}): ConversationMessagesState {
    const storedTutorBotMessages = useSelector(getVoiceAgentMessages(conversationId));
    const conversationContext = useSelector(getConversationContext(conversationId));
    const botVersion = useSelector(getBotVersion(conversationId));
    const algorithm = useSelector(getAlgorithm(conversationId));
    const dispatch = useDispatch();
    return {
        allTranscriptionMessages,
        storedTutorBotMessages,
        uiContext,
        msBeforeAssumingMessageIsComplete,
        conversationContext,
        botVersion,
        algorithm,
        dispatch,
    };
}

export function useStoreTranscriptionMessages({
    transcriptionMessages,
    uiContext,
    conversationId,
    msBeforeAssumingMessageIsComplete = DEFAULT_MS_BEFORE_ASSUMING_MESSAGE_IS_COMPLETE,
}: {
    transcriptionMessages: TranscriptionMessage[];
    uiContext: BotUiContext;
    conversationId: string | null;
    msBeforeAssumingMessageIsComplete?: number;
}) {
    const conversationMessagesState = useConversationMessagesState({
        conversationId,
        allTranscriptionMessages: transcriptionMessages,
        uiContext,
        msBeforeAssumingMessageIsComplete,
    });
    const [lastStoreCallAt, setLastStoreCallAt] = useState<Date>(new Date());
    const storeMessagesCallback = useCallback(
        ({ onlyStoreCompleteMessages }: { onlyStoreCompleteMessages: boolean }) => {
            maybeStoreTranscriptionMessages({ conversationMessagesState, onlyStoreCompleteMessages });
            setLastStoreCallAt(new Date());
        },
        [conversationMessagesState],
    );

    // We check for complete messages and store them to redux
    // 1. after msBeforeAssumingMessageIsComplete
    // 2. When the user disconnects from the room
    useStoreCompleteMessagesEveryFewSeconds({
        msBeforeAssumingMessageIsComplete,
        lastStoreCallAt,
        storeMessagesCallback,
    });
    useRoomEventListener(RoomEvent.Disconnected, () => storeMessagesCallback({ onlyStoreCompleteMessages: false }));
}
