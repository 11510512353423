import { memo, type FC } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';
import { generateGuid } from 'guid';
import { getHasChangedTopic } from '../redux/chat';
import {
    type GreetingMessageConfigWithSubsequentGreeting,
    type GreetingMessageConfig,
} from '../types/GreetingMessageConfig.types';
import { NewTopicMessage } from './NewTopicMessage';
import { InitialGreetingMessage } from './InitialGreetingMessage';
import { SubsequentGreetingMessage } from './SubsequentGreetingMessage';
import { LockedAiAdvisorMessage } from './LockedAiAdvisorMessage';
import { useChatContext } from '../Chat/ChatContext';

type Props = {
    name: string;
};

function isConfigWithSubsequentGreeting(
    config: GreetingMessageConfig | undefined,
): config is GreetingMessageConfigWithSubsequentGreeting {
    // If we have subsequentGreetingLocales we know we will have subsequentPromptLocales. The type guarantees it
    return !!config?.subsequentGreetingLocales;
}

const GreetingMessageComponent: FC<Props> = ({ name }) => {
    const {
        tutorBotStatusConfig: { isLocked },
        greetingMessageConfig,
    } = useChatContext();
    const hasSeenInitialGreetingLocalStorageKey = greetingMessageConfig?.hasSeenInitialGreetingLocalStorageKey || null;
    const initialGreetingLocales = greetingMessageConfig?.initialGreetingLocales;
    const hasChangedTopic = useSelector(getHasChangedTopic);
    const [hasSeenInitialGreeting, setHasSeenInitialGreeting] = useLocalStorage(
        // Since useLocaleStorge is a hook, we can't not call it when there is no key. Generate a guid to make
        // sure nothing gets returned. We won't ever set this key
        hasSeenInitialGreetingLocalStorageKey || generateGuid(),
    );

    return isLocked ? (
        <LockedAiAdvisorMessage />
    ) : !hasSeenInitialGreeting && initialGreetingLocales ? (
        <InitialGreetingMessage
            name={name}
            initialGreetingLocales={initialGreetingLocales}
            setHasSeenInitialGreeting={hasSeenInitialGreetingLocalStorageKey ? setHasSeenInitialGreeting : null}
        />
    ) : hasChangedTopic ? (
        <NewTopicMessage />
    ) : (
        isConfigWithSubsequentGreeting(greetingMessageConfig) && (
            <SubsequentGreetingMessage
                name={name}
                subsequentGreetingLocales={greetingMessageConfig.subsequentGreetingLocales}
                subsequentPromptLocales={greetingMessageConfig.subsequentPromptLocales}
            />
        )
    );
};

export const GreetingMessage = memo(GreetingMessageComponent) as typeof GreetingMessageComponent;
