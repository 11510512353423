import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { type ConversationContext } from 'TutorBotConversation';
import { voiceAgentSlice } from '../redux/voiceAgent';

export function useStoreConversationContext({
    conversationId,
    conversationContext,
}: {
    conversationId: string | null;
    conversationContext: ConversationContext;
}) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!conversationId) return;
        dispatch(
            voiceAgentSlice.actions.updateConversationMetadata({
                conversationId,
                conversationContext,
            }),
        );
    }, [conversationId, conversationContext, dispatch]);
}
