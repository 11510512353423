import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    type UseFormGetValues,
    type UseFormSetValue,
    type UseFormHandleSubmit,
    FormProvider,
    useForm,
    yupResolver,
} from 'FrontRoyalReactHookForm';
import { useEffect, useMemo, useRef, type KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { faCommentLines } from '@fortawesome/pro-thin-svg-icons';
import { faCircleStop, faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useTheme } from 'Theme';
import { AutoGrowTextAreaField } from 'AutoGrowTextAreaField';
import { twMerge } from 'tailwind-merge';
import { useChatContext } from '../Chat/ChatContext';
import { type MessageFormValues } from '../types/OtherInternalTypes.types';
import { type TutorBotChatStatusConfig } from '../types/TutorBotChatStatusConfig.types';

type Props = {
    onSubmit: (data: MessageFormValues) => void;
    onCancelClick: () => void;
    className?: string;
    maxLength?: number;
    disableInput?: boolean;
    canCancel?: boolean;
    hideCharacterCount?: boolean;
};

function useInputFocus({ disableInput }: Pick<Props, 'disableInput'>) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const { isMobile } = useIsMobileMixin();

    useEffect(() => {
        if (isMobile || window.CORDOVA) return;

        if (!disableInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [disableInput, isMobile]);

    return inputRef;
}

function useMessageForm({ maxLength }: { maxLength: number }) {
    const formValidationSchema = Yup.object()
        .shape({
            message: Yup.string().required().max(maxLength),
        })
        .required();
    const formFunctions = useForm<MessageFormValues>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: { message: '' },
        mode: 'all',
    });

    const message = formFunctions.watch('message');
    const errors = formFunctions.formState.errors;
    const messageInvalid = !!errors?.message;
    const messageTooLong = messageInvalid && errors.message!.type === 'max';

    return {
        formFunctions,
        handleSubmit: formFunctions.handleSubmit,
        getValues: formFunctions.getValues,
        setFieldValue: formFunctions.setFieldValue,
        hasMessage: !!message?.length,
        messageInvalid,
        messageTooLong,
        message,
    };
}

function useInputLabel({ tutorBotStatusConfig }: { tutorBotStatusConfig: TutorBotChatStatusConfig }) {
    const { t } = useTranslation('back_royal');

    const inputLabel = useMemo(() => {
        const inputLabelLocale = tutorBotStatusConfig.inputLabel;
        const key = inputLabelLocale?.key;
        const params = inputLabelLocale?.params;
        return key ? t(key, params || {}) : '';
    }, [t, tutorBotStatusConfig]);

    return inputLabel;
}

function useInputButtonClasses() {
    const { bgColor } = useTheme();
    return clsx(
        'm-0 block border-none bg-transparent p-0 transition-all hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-70',
        {
            'quantic:text-coral valar:text-blue': ['beige', 'white', 'beige-pattern', 'demo-pattern'].includes(bgColor),
            'text-turquoise': bgColor === 'turquoise',
            'text-purple': bgColor === 'purple',
            'text-blue': bgColor === 'blue',
        },
    );
}

function CharacterCount({
    hasMessage,
    messageTooLong,
    message,
    maxLength,
    hide = false,
}: {
    hasMessage: boolean;
    messageTooLong: boolean;
    message: string;
    maxLength: Props['maxLength'];
    hide?: boolean;
}) {
    return (
        <div
            data-testid="character-count"
            className={twMerge(
                'absolute -bottom-[26px] text-xs ltr:right-5 rtl:left-5',
                messageTooLong ? 'text-red' : 'text-beige-beyond-dark',
                // hidden on mobile to avoid overlap or if the hide prop is true
                hide ? 'hidden' : 'hidden sm:block',
            )}
        >
            {hasMessage && (
                <>
                    <span className="rtl:hidden">
                        {message.length} / {maxLength}
                    </span>
                    <span className="ltr:hidden">
                        {maxLength} / {message.length}
                    </span>
                </>
            )}
        </div>
    );
}

function FormButtons({
    hasMessage,
    isLocked,
    messageInvalid,
    disableInput,
    canCancel,
    onCancelClick,
}: Pick<Props, 'disableInput' | 'canCancel' | 'onCancelClick'> & {
    hasMessage: boolean;
    messageInvalid: boolean;
    isLocked: boolean;
}) {
    const { t } = useTranslation('back_royal');
    const inputButtonClasses = useInputButtonClasses();

    return (
        <>
            {hasMessage && (
                <button
                    data-testid="submit"
                    className={inputButtonClasses}
                    type="submit"
                    disabled={isLocked || messageInvalid || disableInput}
                >
                    <FontAwesomeIcon className="text-xl rtl:-scale-x-100" fontStyle="solid" icon={faPaperPlaneTop} />
                    <span className="sr-only">{t('chat.chat.submit_message')}</span>
                </button>
            )}
            {canCancel && (
                <button
                    data-testid="stop"
                    onClick={onCancelClick}
                    className={inputButtonClasses}
                    type="button"
                    title={t('chat.chat.cancel_response')!}
                >
                    <FontAwesomeIcon className="text-xl" fontStyle="solid" icon={faCircleStop} />
                    <span className="sr-only">{t('chat.chat.cancel_response')}</span>
                </button>
            )}
        </>
    );
}

function MessageFormInternal({
    handleSubmit,
    onSubmit,
    setFieldValue,
    disableInput,
    tutorBotStatusConfig,
    getValues,
    hasMessage,
    messageInvalid,
    canCancel,
    onCancelClick,
}: Pick<Props, 'onSubmit' | 'disableInput' | 'onCancelClick' | 'canCancel'> & {
    tutorBotStatusConfig: TutorBotChatStatusConfig;
    handleSubmit: UseFormHandleSubmit<MessageFormValues>;
    setFieldValue: UseFormSetValue<MessageFormValues>;
    getValues: UseFormGetValues<MessageFormValues>;
    hasMessage: boolean;
    messageInvalid: boolean;
}) {
    const onSubmitInner = (data: MessageFormValues) => {
        onSubmit({ ...data });
        setFieldValue('message', '');
    };
    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== 'Enter') return;

        e.preventDefault();
        onSubmitInner(getValues());
    };
    const inputRef = useInputFocus({ disableInput });
    const inputLabel = useInputLabel({ tutorBotStatusConfig });

    return (
        <form
            data-testid="message-form"
            className="flex h-full rounded-12 border border-tutorbot-border bg-white px-4 py-3 text-beige-for-text shadow-smallish"
            onSubmit={handleSubmit(onSubmitInner)}
        >
            <div className={clsx('grow-0 basis-6', { 'opacity-40': disableInput })}>
                <FontAwesomeIcon className="text-xl" icon={faCommentLines} />
            </div>
            <div className="flex flex-1 items-center px-4">
                <AutoGrowTextAreaField
                    data-testid="message-input"
                    ref={inputRef}
                    name="message"
                    aria-label={inputLabel}
                    className="max-h-[400px] overflow-y-auto bg-transparent placeholder-[#776f65] outline-none scrollbar-hide disabled:cursor-not-allowed disabled:placeholder-gray-300"
                    placeholder={inputLabel}
                    onKeyDown={handleKeyDown}
                    disabled={disableInput}
                />
            </div>
            <div className="grow-0 basis-6">
                <FormButtons
                    hasMessage={hasMessage}
                    isLocked={tutorBotStatusConfig.isLocked}
                    messageInvalid={messageInvalid}
                    disableInput={disableInput}
                    canCancel={canCancel}
                    onCancelClick={onCancelClick}
                />
            </div>
        </form>
    );
}

export function MessageForm({
    onSubmit,
    onCancelClick,
    className,
    maxLength = 2000,
    disableInput = false,
    canCancel = false,
    hideCharacterCount = false,
}: Props) {
    const { tutorBotStatusConfig } = useChatContext();

    const {
        handleSubmit,
        getValues,
        setFieldValue,
        formFunctions,
        message,
        hasMessage,
        messageInvalid,
        messageTooLong,
    } = useMessageForm({ maxLength });

    return (
        <FormProvider {...formFunctions}>
            <div className={clsx('relative', 'text-base', className)}>
                <MessageFormInternal
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    setFieldValue={setFieldValue}
                    disableInput={disableInput}
                    tutorBotStatusConfig={tutorBotStatusConfig}
                    getValues={getValues}
                    canCancel={canCancel}
                    onCancelClick={onCancelClick}
                    hasMessage={hasMessage}
                    messageInvalid={messageInvalid}
                />
                <CharacterCount
                    hasMessage={hasMessage}
                    messageTooLong={messageTooLong}
                    message={message}
                    maxLength={maxLength}
                    hide={hideCharacterCount}
                />
            </div>
        </FormProvider>
    );
}
