import { type ReactNode, type FC } from 'react';
import { twMerge } from 'tailwind-merge';

const variantBaseClasses = {
    h1: 'text-[25px] font-semibold text-black',
    h2: 'text-lg font-semibold text-black',
    p: 'text-base leading-[1.4em] text-slate-grey',
    small: 'text-xs leading-[1.4em] text-slate-grey',
};

type Props = {
    as?: keyof typeof variantBaseClasses;
    variant?: keyof typeof variantBaseClasses;
    className?: string;
    children: ReactNode;
    color?: 'black' | 'slate-grey' | 'white';
    uppercase?: boolean;
    weight?: 'normal' | 'medium' | 'semibold' | 'bold';
};

export const Text: FC<Props> = ({
    // If no variant is provided, then the variant will default to the value of `as`. For accessibility
    // reasons, we support having `as` and `variant` be different, meaning that the tag type will not
    // match the styling. For more details about when you'd want to use that, see https://trello.com/c/SbjQGEJS#comment-66ec4684b8d004e18a425339
    as: Component = 'p',
    variant,
    uppercase = false,
    color,
    children,
    className,
    weight,
}) => (
    <Component
        className={twMerge(
            variantBaseClasses[variant || Component],
            color && `text-${color}`,
            uppercase && 'uppercase',
            weight && `font-${weight}`,
            className,
        )}
    >
        {children}
    </Component>
);
