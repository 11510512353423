window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.TutorBotVoiceAgent = window.locales.am.TutorBotVoiceAgent || {};window.locales.am.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.am.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.am.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.am.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "ወደ ጽሑፍ ሁነታ ቀይር",
    "transcriptNotAvailable": "ግልባጭ አይገኝም",
    "restartDiscussion": "ውይይት እንደገና ያስጀምሩ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.TutorBotVoiceAgent = window.locales.ar.TutorBotVoiceAgent || {};window.locales.ar.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.ar.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.ar.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.ar.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "التبديل إلى وضع النص",
    "transcriptNotAvailable": "النص غير متوفر",
    "restartDiscussion": "إعادة تشغيل المناقشة"
}
};window.locales.en = window.locales.en || {};window.locales.en.TutorBotVoiceAgent = window.locales.en.TutorBotVoiceAgent || {};window.locales.en.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.en.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.en.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.en.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "Switch to text mode",
    "transcriptNotAvailable": "Transcript not available",
    "restartDiscussion": "Restart Discussion"
}
};window.locales.es = window.locales.es || {};window.locales.es.TutorBotVoiceAgent = window.locales.es.TutorBotVoiceAgent || {};window.locales.es.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.es.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.es.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.es.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "Cambiar al modo de texto",
    "transcriptNotAvailable": "Transcripción no disponible",
    "restartDiscussion": "Reiniciar la discusión"
}
};window.locales.it = window.locales.it || {};window.locales.it.TutorBotVoiceAgent = window.locales.it.TutorBotVoiceAgent || {};window.locales.it.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.it.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.it.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.it.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "Passa alla modalità testo",
    "transcriptNotAvailable": "Trascrizione non disponibile",
    "restartDiscussion": "Riavvia discussione"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.TutorBotVoiceAgent = window.locales.zh.TutorBotVoiceAgent || {};window.locales.zh.TutorBotVoiceAgent.TutorBotVoiceAgent = window.locales.zh.TutorBotVoiceAgent.TutorBotVoiceAgent || {};window.locales.zh.TutorBotVoiceAgent.TutorBotVoiceAgent = {...window.locales.zh.TutorBotVoiceAgent.TutorBotVoiceAgent, ...{
    "switchToTextMode": "切换到文本模式",
    "transcriptNotAvailable": "暂无成绩单",
    "restartDiscussion": "重新开始讨论"
}
};