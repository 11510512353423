window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.ai_advisor_chat = window.locales.am.ai_advisor_chat || {};window.locales.am.ai_advisor_chat.ai_advisor_chat = window.locales.am.ai_advisor_chat.ai_advisor_chat || {};window.locales.am.ai_advisor_chat.ai_advisor_chat = {...window.locales.am.ai_advisor_chat.ai_advisor_chat, ...{
    "how_can_i_help": "እንዴት ልረዳ እችላለሁ?",
    "locked_welcome_modal_cta_message_signup_headline": "የሚቀጥለው የማመልከቻ የመጨረሻ ቀን፡-",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "አሁኑኑ ያመልክቱ",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "ቃለ መጠይቅዎን ያቅዱ፡",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "መርሐግብር",
    "locked_welcome_modal_cta_message_offered_admission_headline": "የምዝገባ የመጨረሻ ቀን፡-",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "ይመዝገቡ",
    "welcome_message": "ጥያቄ አለህ? {botName} ለመርዳት ዝግጁ ነው። የውይይት <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">መልእክት ታሪክ</a> የወደፊት የቦት መስተጋብርን ለማሻሻል ጥቅም ላይ ሊውል እንደሚችል ልብ ይበሉ።",
    "locked_welcome_modal": "የገቡት {institutionName} ተማሪዎች እና የቀድሞ ተማሪዎች የእድሜ ልክ የመማር ጉዟቸውን ለመደገፍ የተሰራ የላቀ ቻትቦት የሆነውን {botName} ያገኛሉ።",
    "available_to_students_and_alumni": "ለተማሪዎች እና ለተማሪዎች ይገኛል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.ai_advisor_chat = window.locales.ar.ai_advisor_chat || {};window.locales.ar.ai_advisor_chat.ai_advisor_chat = window.locales.ar.ai_advisor_chat.ai_advisor_chat || {};window.locales.ar.ai_advisor_chat.ai_advisor_chat = {...window.locales.ar.ai_advisor_chat.ai_advisor_chat, ...{
    "how_can_i_help": "كيف يمكنني أن أقدم المساعدة؟",
    "locked_welcome_modal_cta_message_signup_headline": "الموعد النهائي لتقديم الطلبات التالي:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "قدم الآن",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "حدد موعد المقابلة الخاصة بك:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "جدول",
    "locked_welcome_modal_cta_message_offered_admission_headline": "آخر موعد للتسجيل:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "يسجل",
    "welcome_message": "هل لديك سؤال؟ {botName} مستعد للمساعدة. يرجى ملاحظة أنه قد يتم استخدام <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">سجل رسائل</a> المحادثة لتحسين تفاعلات الروبوت المستقبلية.",
    "locked_welcome_modal": "حصل الطلاب والخريجون المقبولون {institutionName} على إمكانية الوصول إلى {botName} ، وهو روبوت محادثة متقدم تم تصميمه لدعم رحلة التعلم مدى الحياة الخاصة بهم.",
    "available_to_students_and_alumni": "متاح للطلاب والخريجين"
}
};window.locales.en = window.locales.en || {};window.locales.en.ai_advisor_chat = window.locales.en.ai_advisor_chat || {};window.locales.en.ai_advisor_chat.ai_advisor_chat = window.locales.en.ai_advisor_chat.ai_advisor_chat || {};window.locales.en.ai_advisor_chat.ai_advisor_chat = {...window.locales.en.ai_advisor_chat.ai_advisor_chat, ...{
    "welcome_message": "Have a question? {botName} is ready to help. Note that conversation <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">message history</a> may be used to improve future bot interactions.",
    "how_can_i_help": "How can I help?",
    "locked_welcome_modal": "Admitted {institutionName} students and alumni gain access to {botName}, an advanced chatbot built to support their lifelong learning journey.",
    "locked_welcome_modal_cta_message_signup_headline": "Next application deadline:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Apply Now",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Schedule your interview:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Schedule",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Registration deadline:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Register",
    "available_to_students_and_alumni": "Available to students and alumni"
}
};window.locales.es = window.locales.es || {};window.locales.es.ai_advisor_chat = window.locales.es.ai_advisor_chat || {};window.locales.es.ai_advisor_chat.ai_advisor_chat = window.locales.es.ai_advisor_chat.ai_advisor_chat || {};window.locales.es.ai_advisor_chat.ai_advisor_chat = {...window.locales.es.ai_advisor_chat.ai_advisor_chat, ...{
    "how_can_i_help": "¿Cómo puedo ayudar?",
    "locked_welcome_modal_cta_message_signup_headline": "Próxima fecha límite de solicitud:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Aplica ya",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Agenda tu entrevista:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Cronograma",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Fecha limite de registro:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Registro",
    "welcome_message": "¿Tiene alguna pregunta? {botName} está listo para ayudar. Tenga en cuenta que <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">el historial de mensajes</a> de la conversación se puede utilizar para mejorar las interacciones futuras del bot.",
    "locked_welcome_modal": "Los estudiantes y exalumnos admitidos en {institutionName} obtienen acceso a {botName} , un chatbot avanzado creado para apoyar su trayectoria de aprendizaje permanente.",
    "available_to_students_and_alumni": "Disponible para estudiantes y exalumnos"
}
};window.locales.it = window.locales.it || {};window.locales.it.ai_advisor_chat = window.locales.it.ai_advisor_chat || {};window.locales.it.ai_advisor_chat.ai_advisor_chat = window.locales.it.ai_advisor_chat.ai_advisor_chat || {};window.locales.it.ai_advisor_chat.ai_advisor_chat = {...window.locales.it.ai_advisor_chat.ai_advisor_chat, ...{
    "how_can_i_help": "Come posso aiutare?",
    "locked_welcome_modal_cta_message_signup_headline": "Prossima scadenza per le domande:",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "Applica ora",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "Pianifica il tuo colloquio:",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "Programma",
    "locked_welcome_modal_cta_message_offered_admission_headline": "Termine per la registrazione:",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "Registrati",
    "welcome_message": "Hai una domanda? {botName} è pronto ad aiutarti. Nota che <a class=\"text-white underline\" href=\"{faqUrl}\" target=\"_blank\">la cronologia dei messaggi</a> di conversazione potrebbe essere utilizzata per migliorare le interazioni future con i bot.",
    "locked_welcome_modal": "Gli studenti e gli ex studenti ammessi di {institutionName} hanno accesso a {botName} , un chatbot avanzato creato per supportare il loro percorso di apprendimento permanente.",
    "available_to_students_and_alumni": "Disponibile per studenti ed ex studenti"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.ai_advisor_chat = window.locales.zh.ai_advisor_chat || {};window.locales.zh.ai_advisor_chat.ai_advisor_chat = window.locales.zh.ai_advisor_chat.ai_advisor_chat || {};window.locales.zh.ai_advisor_chat.ai_advisor_chat = {...window.locales.zh.ai_advisor_chat.ai_advisor_chat, ...{
    "how_can_i_help": "我能提供什么帮助？",
    "locked_welcome_modal_cta_message_signup_headline": "下次申请截止日期：",
    "locked_welcome_modal_cta_message_signup_subtext": "{applicationDeadline}",
    "locked_welcome_modal_cta_button_text_signup": "现在申请",
    "locked_welcome_modal_cta_message_applied_interview_pending_headline": "安排面试：",
    "locked_welcome_modal_cta_button_text_applied_interview_pending": "日程",
    "locked_welcome_modal_cta_message_offered_admission_headline": "报名截止日期：",
    "locked_welcome_modal_cta_message_offered_admission_subtext": "{registrationDeadline}",
    "locked_welcome_modal_cta_button_text_offered_admission": "登记",
    "welcome_message": "有问题？ {botName}随时准备提供帮助。请注意，对话<a class=\"text-white underline\" href=\"{faqUrl}\", target=\"_blank\">消息历史记录</a>可用于改善未来的机器人交互。",
    "locked_welcome_modal": "被录取的{institutionName}学生和校友可以使用{botName} ，这是一个为支持他们的终身学习之旅而构建的先进聊天机器人。",
    "available_to_students_and_alumni": "面向学生和校友开放"
}
};