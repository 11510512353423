import {
    type TutorBotChatUnlockedConfig,
    getUnavailableConfig,
    useTutorBotLockedConfig,
    type TutorBotChatStatusConfig,
    type TutorBotChatLockedConfig,
    UnlockedTutorBotChatState,
} from 'TutorBotChat';
import { type I18NLocale } from 'Locale';
import { useNavigationHelper, useCurrentUserIguanaObject, useTargetBrandConfig } from 'FrontRoyalAngular';
import { type CurrentUserIguanaObject, getAdmissionOffer, getAoiRecord, getCohort, getPreferredName } from 'Users';
import { AoiRecordType } from 'ProgramAoi';
import { getStartDate } from 'Cohorts';
import { getRegistrationDeadline } from 'AdmissionOffer';
import { useErrorLogService } from 'ErrorLogging';
import { type DateHelper } from 'DateHelper';
import { angularInjectorProvider } from 'Injector';
import { type BrandConfig } from 'AppBranding';
import { determineBotPageState } from './determineBotPageState';
import { LockedBotPageState } from './TutorBotAiAdvisorChat.types';
import './locales/ai_advisor_chat/ai_advisor_chat-en.json';
import { AI_ADVISOR_BRAND_NAME } from './constants';

const haveAWonderfulDayLocale: I18NLocale = { key: 'ai_advisor_chat.greeting_message.haveWonderfulDay' } as const;
function getNotCurrentlyStudentPartialConfig({
    institutionName,
    name,
    botName,
    secondGreetingMessageLocale = haveAWonderfulDayLocale,
}: {
    name: string;
    institutionName: string;
    botName: string;
    secondGreetingMessageLocale?: I18NLocale;
}) {
    return {
        inputLabel: { key: 'ai_advisor_chat.ai_advisor_chat.available_to_students_and_alumni' },
        isLocked: true,
        welcomeModalTexts: [
            {
                key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal',
                params: { institutionName, botName },
            } as const,
        ] as const,
        greetingMessages: [
            { key: 'ai_advisor_chat.greeting_message.myMission', params: { name, institutionName } } as const,
            secondGreetingMessageLocale,
        ] as const,
    } as const;
}

function getUnlockedConfig({ brandConfig, botName }: { brandConfig: BrandConfig; botName: string }) {
    const config: TutorBotChatUnlockedConfig = {
        isLocked: false,
        greetingMessages: [],
        welcomeModalTexts: [
            {
                key: 'ai_advisor_chat.ai_advisor_chat.welcome_message',
                params: { institutionName: brandConfig.brandNameShort, faqUrl: brandConfig.tutorBotFaqUrl, botName },
            },
            { key: 'chat.chat.ai_welcome_message_stay_tuned' },
        ],
        inputLabel: { key: 'ai_advisor_chat.ai_advisor_chat.how_can_i_help' },
    };
    return config;
}

function getSignupConfig({
    navigationHelper,
    dateHelper,
    institutionName,
    currentUser,
    name,
    botName,
}: {
    navigationHelper: ReturnType<typeof useNavigationHelper>;
    dateHelper: DateHelper;
    institutionName: string;
    currentUser: CurrentUserIguanaObject;
    name: string;
    botName: string;
}): TutorBotChatLockedConfig {
    const applicationDeadline = dateHelper.formattedUserFacingMonthDayLong(
        currentUser?.relevantCohort?.applicationDeadline?.() as Date,
        true,
    );

    return {
        ...getNotCurrentlyStudentPartialConfig({
            institutionName,
            name,
            botName,
            secondGreetingMessageLocale: { key: 'ai_advisor_chat.greeting_message.hopeYouWillApply' },
        }),
        welcomeModalCta: {
            message: {
                headline: { key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_message_signup_headline' },
                subtext: {
                    key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_message_signup_subtext',
                    params: { applicationDeadline },
                },
            },
            buttonText: { key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_button_text_signup' },
            onClick: () => navigationHelper.loadRoute('/settings/application'),
        },
    };
}

function getAppliedInterviewPendingConfig({
    name,
    institutionName,
    currentUser,
    navigationHelper,
    botName,
}: {
    currentUser: CurrentUserIguanaObject;
    name: string;
    institutionName: string;
    navigationHelper: ReturnType<typeof useNavigationHelper>;
    botName: string;
}): TutorBotChatLockedConfig {
    const programApplication = getAoiRecord(currentUser, AoiRecordType.ProgramApplication);
    return {
        ...getNotCurrentlyStudentPartialConfig({
            institutionName,
            name,
            botName,
            secondGreetingMessageLocale: { key: 'ai_advisor_chat.greeting_message.completeInterview' },
        }),
        welcomeModalCta: {
            message: {
                headline: {
                    key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_message_applied_interview_pending_headline',
                },
            },
            buttonText: {
                key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_button_text_applied_interview_pending',
            },
            onClick: () => navigationHelper.loadUrl(programApplication!.invitedToInterviewUrl),
        },
    };
}

function getOfferedAdmissionConfig({
    name,
    institutionName,
    navigationHelper,
    currentUser,
    dateHelper,
    botName,
}: {
    name: string;
    institutionName: string;
    navigationHelper: ReturnType<typeof useNavigationHelper>;
    currentUser: CurrentUserIguanaObject;
    dateHelper: DateHelper;
    botName: string;
}): TutorBotChatLockedConfig {
    const cohort = getCohort(currentUser)!;
    const admissionOffer = getAdmissionOffer(currentUser);
    const unformattedRegistrationDeadline = admissionOffer && getRegistrationDeadline(admissionOffer, cohort);
    const registrationDeadline =
        unformattedRegistrationDeadline && dateHelper.formattedUserFacingMonthDayLong(unformattedRegistrationDeadline);

    return {
        ...getNotCurrentlyStudentPartialConfig({
            institutionName,
            name,
            botName,
            secondGreetingMessageLocale: { key: 'ai_advisor_chat.greeting_message.hopeYouWillJoin' },
        }),
        welcomeModalCta: {
            message: {
                headline: {
                    key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_message_offered_admission_headline',
                },
                subtext: {
                    key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_message_offered_admission_subtext',
                    params: { registrationDeadline },
                },
            },
            buttonText: {
                key: 'ai_advisor_chat.ai_advisor_chat.locked_welcome_modal_cta_button_text_offered_admission',
            },
            onClick: () => navigationHelper.loadRoute('/settings/application_status'),
        },
    };
}

function getRegisteredNotCurrentConfig({
    institutionName,
    name,
    currentUser,
    dateHelper,
    botName,
}: {
    institutionName: string;
    name: string;
    currentUser: CurrentUserIguanaObject;
    dateHelper: DateHelper;
    botName: string;
}): TutorBotChatLockedConfig {
    const cohort = getCohort(currentUser)!;
    const startDate = dateHelper.formattedUserFacingMonthDayLong(getStartDate(cohort), false);
    return {
        ...getNotCurrentlyStudentPartialConfig({ institutionName, name, botName }),
        greetingMessages: [
            {
                key: 'ai_advisor_chat.greeting_message.congratulationsOnAcceptance',
                params: { institutionName, name, startDate, botName },
            },
            { key: 'ai_advisor_chat.greeting_message.seeYouSoon' },
        ],
    };
}

export function useBotPageStatusConfig(botPageAccessForProspectiveStudents: boolean): TutorBotChatStatusConfig {
    const botName = AI_ADVISOR_BRAND_NAME;
    const tutorBotLockedConfig = useTutorBotLockedConfig({ botName });

    const currentUser = useCurrentUserIguanaObject();
    const navigationHelper = useNavigationHelper();
    const brandConfig = useTargetBrandConfig();
    const ErrorLogService = useErrorLogService();

    // If tutor bot is locked globally, then we just return that config
    if (tutorBotLockedConfig) return tutorBotLockedConfig;

    if (!currentUser) {
        ErrorLogService.notifyInProd(new Error('Expected to have a user when determining bot page state'), null);
        return getUnavailableConfig({ botName });
    }

    const state = determineBotPageState(currentUser, botPageAccessForProspectiveStudents);

    const dateHelper = angularInjectorProvider.get<DateHelper>('dateHelper');
    const institutionName = brandConfig?.brandNameShort;
    const name = getPreferredName(currentUser);

    switch (state) {
        case UnlockedTutorBotChatState.TutorBotUnlocked:
            return getUnlockedConfig({ brandConfig, botName });
        case LockedBotPageState.Signup:
            return getSignupConfig({ dateHelper, navigationHelper, institutionName, currentUser, name, botName });
        case LockedBotPageState.Applied:
            return getNotCurrentlyStudentPartialConfig({ institutionName, name, botName });
        case LockedBotPageState.AppliedInterviewPending:
            return getAppliedInterviewPendingConfig({ name, institutionName, currentUser, navigationHelper, botName });
        case LockedBotPageState.NotJoiningProgram:
            return getNotCurrentlyStudentPartialConfig({ institutionName, name, botName });
        case LockedBotPageState.ExpelledOrWithdrawn:
            return getNotCurrentlyStudentPartialConfig({ institutionName, name, botName });
        case LockedBotPageState.OfferedAdmission:
            return getOfferedAdmissionConfig({
                name,
                institutionName,
                navigationHelper,
                currentUser,
                dateHelper,
                botName,
            });
        case LockedBotPageState.RegisteredNotCurrent:
            return getRegisteredNotCurrentConfig({ institutionName, name, currentUser, dateHelper, botName });
        default:
            ErrorLogService.notifyInProd(new Error(`Unexpected TutorBotState "${state}"`), null);
            return getUnavailableConfig({ botName });
    }
}
