import {
    type UnlockedTutorBotChatState,
    type BotClientContextImplementation,
    type ConversationMetadataImplementation,
} from 'TutorBotChat';

// This state is "invalid" because you should not even be in the
// conversation UI in this case
export enum InvalidBotPageState {
    NoAccess = 'NoAccess',
}

export enum LockedBotPageState {
    Signup = 'Signup',
    AppliedInterviewPending = 'AppliedInterviewPending',
    Applied = 'Applied',
    ExpelledOrWithdrawn = 'ExpelledOrWithdrawn',
    NotJoiningProgram = 'NotJoiningProgram',
    OfferedAdmission = 'OfferedAdmission',
    RegisteredNotCurrent = 'RegisteredNotCurrent',
}

export type BotPageState = LockedBotPageState | InvalidBotPageState | UnlockedTutorBotChatState;

type BotPageUiContext = 'bot_page';

// FIXME: BotPageConversationMetadata isn't used yet, but it should be once we figure out how to
// handle the context-specific nature of the way useConversation calls setActiveConversation. Right now,
// those implementations are implicitly assuming we're on the bot page, but that's not at all obvious from
// looking at the code.
export type BotPageConversationMetadata = ConversationMetadataImplementation<BotPageUiContext>;
export type BotPageClientContext = BotClientContextImplementation<BotPageUiContext>;
