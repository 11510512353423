import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { type FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DismissableMessage } from 'DismissableMessage/DismissableMessage';
import { Typography } from 'Typography';
import clsx from 'clsx';
import { useChatContext } from '../Chat/ChatContext';

const WelcomeModalComponent: FC = () => {
    const { t } = useTranslation('back_royal');
    const { tutorBotStatusConfig, welcomeModalClassname } = useChatContext();
    const { isLocked } = tutorBotStatusConfig;
    // FIXME: swap to useCurrentUser hook, will need to create rtk user api to be able to
    // update hasSeenAiWelcomeMessage and ghostMode will need to be moved off of the user
    const currentUser = useCurrentUserIguanaObject();
    const hasSeenMessage = currentUser?.has_seen_ai_welcome_message;

    const [showUnlockedMessage, setShowUnlockedMessage] = useState(!hasSeenMessage);

    // When a user dismisses the welcome message, there are a few moments when
    // showUnlockedMessage switches to `false` but `hasSeenMessage` is also still `false`.
    // In that case, we continue to render the jsx as the message transitions off the screen.
    // Once the transition is complete, handleAfterLeave triggers, `hasSeenMessage` switches to `true`,
    // and this component returns null.
    const handleAfterLeave = useCallback(() => {
        if (!currentUser) return;

        currentUser.has_seen_ai_welcome_message = true;
        if (!currentUser.ghostMode) currentUser.save();
    }, [currentUser]);

    // Messages shown in the unlocked state are dismissable. Once one has been dismissed,
    // we don't show anything here.
    if (!tutorBotStatusConfig.isLocked && hasSeenMessage) return null;

    // Some locked statuses do not have a welcome message, only greeting messages. In those
    // cases we don't show anything here.
    if (tutorBotStatusConfig.welcomeModalTexts.length === 0) return null;

    return (
        <div className={clsx('flex', welcomeModalClassname)}>
            <div className="w-[95vw] max-w-[400px]">
                <DismissableMessage
                    disableDismiss={isLocked}
                    show={isLocked || showUnlockedMessage}
                    onDismiss={() => setShowUnlockedMessage(false)}
                    onAfterLeave={handleAfterLeave}
                    cta={
                        tutorBotStatusConfig.welcomeModalCta && {
                            text: {
                                headline: tutorBotStatusConfig.welcomeModalCta.message.headline,
                                subtext: tutorBotStatusConfig.welcomeModalCta.message.subtext,
                            },
                            buttonText: tutorBotStatusConfig.welcomeModalCta.buttonText,
                            onClick: tutorBotStatusConfig.welcomeModalCta.onClick,
                        }
                    }
                >
                    {tutorBotStatusConfig.welcomeModalTexts.map(welcomeModalText => (
                        <Typography variant="body4" className="mb-[15px]" key={welcomeModalText.key}>
                            {/* We need parseHtml here because the welcome_message_notice keys include links */}
                            {parseHtml(t(welcomeModalText.key, welcomeModalText.params ?? {}))}
                        </Typography>
                    ))}
                </DismissableMessage>
            </div>
        </div>
    );
};

export const WelcomeModal = memo(WelcomeModalComponent) as typeof WelcomeModalComponent;
