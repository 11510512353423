import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ConversationContext } from 'TutorBotConversation';
import { type VoiceAgentSliceState } from './voiceAgentSlice.types';
import { voiceAgentSliceName } from './constants';
import { type TranscribedAudioMessage } from '../../TutorBotVoiceAgent.types';

export const defaultVoiceAgentState: VoiceAgentSliceState = {
    messages: {},
    conversationMetadata: {},
};

export const voiceAgentSlice = createSlice({
    name: voiceAgentSliceName,
    initialState: defaultVoiceAgentState,
    reducers: {
        /* Define actions and how they modify the state of this store slice.
           You can use mutative operations, and RTK converts them to non-mutative operations behind the scenes */

        addMessage: (state: VoiceAgentSliceState, action: PayloadAction<{ message: TranscribedAudioMessage }>) => {
            state.messages[action.payload.message.id] = action.payload.message;
        },
        updateConversationMetadata: (
            state: VoiceAgentSliceState,
            action: PayloadAction<{
                conversationId: string;
                conversationContext?: ConversationContext;

                // With a voice agent, the botVersion and algorithm are features of the conversation, rather than
                // features of a particular message, like it with text Chat
                botVersion?: string;
                algorithm?: string;
            }>,
        ) => {
            // initialize entry to an empty object. Then merge in conversationContext,  botVersion and algorithm if provided
            const entry = state.conversationMetadata[action.payload.conversationId] || {};
            if (action.payload.conversationContext) entry.conversationContext = action.payload.conversationContext;
            if (action.payload.botVersion) entry.botVersion = action.payload.botVersion;
            if (action.payload.algorithm) entry.algorithm = action.payload.algorithm;
            state.conversationMetadata[action.payload.conversationId] = entry;
        },
    },
});
