import { type AnyObject } from '@Types';
import { useEventLogger } from 'FrontRoyalAngular';
import { useEffect } from 'react';
import { getLogInfo } from '../utils/getLogInfo';

export function useLogLaunchEvent({
    eventType,
    agentIdentifier,
    extraTokenAttributes,
}: {
    eventType: string;
    agentIdentifier: string;
    extraTokenAttributes?: AnyObject;
}) {
    const EventLogger = useEventLogger();

    useEffect(() => {
        EventLogger.log(eventType, getLogInfo({ agentIdentifier, extraTokenAttributes }));
    }, [EventLogger, eventType, agentIdentifier, extraTokenAttributes]);
}
