import { type LinkToLessonInStream } from 'Lessons';

// FIXME: All of this AnswerSource stoff is really only relevant in the bot page context right now. Really we should
// re-organize and move this stuff into the TutorBotAiAdvisorChat module. But, since it's used in generic middleware,
// that would take a bit of thought, so I don't want to mess with it at the moment.

// The SourceLocationType enum includes the types we know about, but we support the possibility of tutor bot adding
// new source location types that back_royal does not yet know about, so long as they have a url and a title. See
// GenericDisplayableSourceLocation and GenericUndisplayableSourceLocation below
export enum SourceLocationType {
    LessonFrame = 'lesson_frame',
    HelpScoutArticle = 'helpscout_article',
    LinkToFile = 'link_to_file',
}

// use `rake help_scout:list_collections` to get this data
export enum HelpScoutCollectionId {
    QuanticStudentHandbook = '5be30cdb2c7d3a31944dade5', // https://secure.helpscout.net/docs/5be30cdb2c7d3a31944dade5/
    QuanticApplicants = '5be30a8904286304a71bfd29', // https://secure.helpscout.net/docs/5be30a8904286304a71bfd29/
    QuanticStudentResourcesMbaEmba = '5be30d1104286304a71bfd53', // https://secure.helpscout.net/docs/5be30d1104286304a71bfd53/
    QuanticStudentResourcesMsba = '66424b1de29ec34ff9cfa675', // https://secure.helpscout.net/docs/66424b1de29ec34ff9cfa675/
    QuanticStudentResourcesMsse = '66424b348f1b9635c9960531', // https://secure.helpscout.net/docs/66424b348f1b9635c9960531/
    QuanticGeneral = '54ebf107e4b086c0c0968fe4', // https://secure.helpscout.net/docs/54ebf107e4b086c0c0968fe4/

    ValarStudentHandbook = '61897c8b2b380503dfe02884', // https://secure.helpscout.net/docs/61897c8b2b380503dfe02884/
    ValarApplicants = '616702cd2b380503dfdfa046', // https://secure.helpscout.net/docs/616702cd2b380503dfdfa046/
    ValarStudentResources = '61897cb92b380503dfe02887', // https://secure.helpscout.net/docs/61897cb92b380503dfe02887/
    ValarGeneral = '61897cc012c07c18afde577d', // https://secure.helpscout.net/docs/61897cc012c07c18afde577d/

    ExecEdStudentHandbook = '64d26a6087bc8b31f2cb9de0', // https://secure.helpscout.net/docs/64d26a6087bc8b31f2cb9de0/
    ExecEdApplicants = '64b97afdac752e2a94768b90', // https://secure.helpscout.net/docs/64b97afdac752e2a94768b90/
    ExecEdStudentResources = '64b97af02af2e15078a7e1a5', // https://secure.helpscout.net/docs/64b97af02af2e15078a7e1a5/
    ExecEdGeneral = '64b97b1bf7e57563daff704b', // https://secure.helpscout.net/docs/64b97b1bf7e57563daff704b/

    // Restricted Sites

    QuanticResourcesStudentResourcesMbaEmba = '671b9f46e25b255d8d34a776',
    QuanticResourcesStudentResourcesMsba = '671ba0f1e25b255d8d34a781',
    QuanticResourcesStudentResourcesMsse = '671ba0fcd0576266aafb6ad7',

    ValarResourcesStudentResources = '671b9fa5e25b255d8d34a77a',
}

export enum HelpScoutCollectionUrl {
    QuanticStudentHandbook = 'https://support.quantic.edu/collection/399-student-handbook',
    QuanticApplicants = 'https://support.quantic.edu/collection/389-applicants',
    QuanticStudentResourcesMbaEmba = 'https://support.quantic.edu/collection/405-mba-emba-resources',
    QuanticStudentResourcesMsba = 'https://support.quantic.edu/collection/1293-msba-resources',
    QuanticStudentResourcesMsse = 'https://support.quantic.edu/collection/1296-msse-resources',
    QuanticGeneral = 'https://support.quantic.edu/collection/1-general',

    ValarStudentHandbook = 'https://valar-support.quantic.edu/collection/541-student-handbook',
    ValarApplicants = 'https://valar-support.quantic.edu/collection/535-applicants',
    ValarStudentResources = 'https://valar-support.quantic.edu/collection/544-student-resources',
    ValarGeneral = 'https://valar-support.quantic.edu/collection/547-general',

    ExecEdStudentHandbook = 'https://exec-ed-support.quantic.edu/collection/1141-student-handbook',
    ExecEdApplicants = 'https://exec-ed-support.quantic.edu/collection/1109-applicants',
    ExecEdStudentResources = 'https://exec-ed-support.quantic.edu/collection/1106-student-resources',
    ExecEdGeneral = 'https://exec-ed-support.quantic.edu/collection/1112-general',

    // Restricted Sites

    QuanticResourcesStudentResourcesMbaEmba = 'https://resources.quantic.edu/collection/1442-student-resources---mba-emba',
    QuanticResourcesStudentResourcesMsba = 'https://resources.quantic.edu/collection/1472-student-resources---msba',
    QuanticResourcesStudentResourcesMsse = 'https://resources.quantic.edu/collection/1475-student-resources---msse',

    ValarResourcesStudentResources = 'https://valar-resources.quantic.edu/collection/1457-student-resources',
}

// When a message comes back from ChatGPT, there can be multiple sources that reference the same url. This
// type keeps track of the url and all of the sources that point to it.
export type SourceLink = {
    sourceIds: string[];
    link: DisplayableNonLessonFrameSourceLocation | LinkToLessonInStream;
};

export type HelpscoutArticleSourceLocation = {
    type: SourceLocationType.HelpScoutArticle;
    url: string;
    collectionId: HelpScoutCollectionId;
    title: string;
};

export type LessonFrameSourceLocation = {
    type: SourceLocationType.LessonFrame;
    lessonId: string;
    frameId: string;
    title: string;
};

export type LinkToFileSourceLocation = {
    type: SourceLocationType.LinkToFile;
    url: string;
    title: string;
};

// The two generic types here support the case where we add a type in tutorbot
// before adding support for it here.
// So long as we have a url and title, then we can display the location
export type GenericDisplayableSourceLocation = {
    type: string;
    url: string;
    title: string;
};

// If we don't have a url or a title, then we can't display the location
export type GenericUnDisplayableSourceLocation = {
    type: string;
};

export type DisplayableSourceLocation =
    | LessonFrameSourceLocation
    | HelpscoutArticleSourceLocation
    | LinkToFileSourceLocation
    | GenericDisplayableSourceLocation;

export type DisplayableNonLessonFrameSourceLocation =
    | HelpscoutArticleSourceLocation
    | LinkToFileSourceLocation
    | GenericDisplayableSourceLocation;

export type NonLessonFrameSourceLocation = DisplayableNonLessonFrameSourceLocation | GenericUnDisplayableSourceLocation;
export type SourceLocation = LessonFrameSourceLocation | NonLessonFrameSourceLocation;

export type SourceMetadata = {
    locations: SourceLocation[];
    id: string;
};

export type AnswerSourceFormattedForEventPayload = {
    lesson_id?: string;
    frame_id?: string;
    helpscout_collection_id?: string;
    url?: string;
    id?: string;
};
