import { useCallback, useEffect, useState } from 'react';
import {
    useMediaDeviceSelect,
    usePersistentUserChoices,
    useRoomContext,
    useTrackToggle,
    useVoiceAssistant,
} from '@livekit/components-react';
import { Track } from 'livekit-client';
import { twMerge } from 'tailwind-merge';
import { MicrophoneSelectButton } from './buttons/MicrophoneSelectButton';
import { MuteButton } from './buttons/MuteButton';
import { EndButton } from './buttons/EndButton';
import './locales/TutorBotVoiceAgent/audioControlBar-en.json';

type Props = {
    className?: string;
};

export function AudioControlBar({ className }: Props) {
    const room = useRoomContext();
    const { state } = useVoiceAssistant();
    const [hasLoadedDefaults, setHasLoadedDefaults] = useState(false);
    const { activeDeviceId, setActiveMediaDevice, devices } = useMediaDeviceSelect({
        kind: 'audioinput',
    });
    const {
        enabled: microphoneEnabled,
        pending: microphonePending,
        toggle: toggleMicrophone,
        track,
    } = useTrackToggle({
        source: Track.Source.Microphone,
    });
    const { saveAudioInputDeviceId, saveAudioInputEnabled, userChoices } = usePersistentUserChoices({
        defaults: { audioEnabled: false },
    });

    const handleMicrophoneSelect = useCallback(
        (deviceId: string) => {
            setActiveMediaDevice(deviceId);
            saveAudioInputDeviceId(deviceId);
        },
        [setActiveMediaDevice, saveAudioInputDeviceId],
    );

    const handleMutePress = useCallback(() => {
        const currentEnabled = microphoneEnabled;
        toggleMicrophone();
        saveAudioInputEnabled(!currentEnabled);
    }, [microphoneEnabled, saveAudioInputEnabled, toggleMicrophone]);

    // Restore the selected device and mute state on mount
    useEffect(() => {
        if (state === 'disconnected' || hasLoadedDefaults || !track) return;

        setActiveMediaDevice(userChoices.audioDeviceId);

        if (!userChoices.audioEnabled) track.mute();

        setHasLoadedDefaults(true);
    }, [state, userChoices, hasLoadedDefaults, microphoneEnabled, setActiveMediaDevice, track]);

    return (
        <AudioControlBarPresentation
            className={className}
            onMicrophoneSelect={handleMicrophoneSelect}
            onMutePress={handleMutePress}
            onEndPress={() => room.disconnect()}
            microphoneEnabled={microphoneEnabled}
            microphonePending={microphonePending}
            activeDeviceId={activeDeviceId}
            devices={devices}
        />
    );
}

export function AudioControlBarPresentation({
    className,
    onMicrophoneSelect,
    onMutePress,
    onEndPress,
    microphoneEnabled,
    microphonePending,
    activeDeviceId,
    devices,
}: {
    className?: string;
    onMicrophoneSelect: (deviceId: string) => void;
    onMutePress: () => void;
    onEndPress: () => void;
    microphoneEnabled: boolean;
    microphonePending: boolean;
    activeDeviceId: string;
    devices: MediaDeviceInfo[];
}) {
    return (
        <div className={twMerge('flex w-full justify-center gap-2.5', className)}>
            <MicrophoneSelectButton activeDeviceId={activeDeviceId} onSelect={onMicrophoneSelect} devices={devices} />
            <MuteButton onPress={onMutePress} enabled={microphoneEnabled} pending={microphonePending} />
            <EndButton onPress={onEndPress} />
        </div>
    );
}
