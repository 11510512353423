import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { useMemo } from 'react';
import { type CurrentUserIguanaObject, getPreferredName } from 'Users';
import { useErrorLogService } from 'ErrorLogging';
import { angularInjectorProvider } from 'Injector';
import { type StreamIguanaObject } from 'Lessons';
import { useInProgressExam } from './useInProgressExam';
import { determineTutorBotLockedState } from '../determineTutorBotLockedState';
import { LockedTutorBotChatState, type TutorBotChatLockedConfig } from '../types/TutorBotChatStatusConfig.types';
import { getUnavailableConfig } from '../tutorBotStatusConfigFactories';

function getInProgressExamConfig({
    currentUser,
    inProgressExam,
}: {
    currentUser: CurrentUserIguanaObject;
    inProgressExam: StreamIguanaObject | null;
}): TutorBotChatLockedConfig {
    return {
        isLocked: true as const,
        welcomeModalTexts: [],
        greetingMessages: [
            {
                key: 'chat.chat.exam_in_progress_message',
                // We always expect inProgressExam to be defined here, but the types don't guarantee it,
                // so we need the "?"
                params: { name: getPreferredName(currentUser), examTitle: inProgressExam?.title },
            },
        ],
        inputLabel: null,
    };
}

export function useTutorBotLockedConfig({ botName }: { botName: string }): TutorBotChatLockedConfig | null {
    const $injector = angularInjectorProvider.requireInjector();
    const { inProgressExam } = useInProgressExam();
    const tutorBotState = useMemo(
        () => determineTutorBotLockedState({ inProgressExam, $injector }),
        [inProgressExam, $injector],
    );
    const ErrorLogService = useErrorLogService();
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;

    if (!currentUser) {
        ErrorLogService.notifyInProd(new Error('Expected to have a user when determining tutor bot state'), null);
        return getUnavailableConfig({ botName });
    }

    switch (tutorBotState) {
        case null:
            return null;
        case LockedTutorBotChatState.Unavailable:
            return getUnavailableConfig({ botName });
        case LockedTutorBotChatState.InProgressExam:
            return getInProgressExamConfig({ currentUser, inProgressExam });
        default:
            ErrorLogService.notifyInProd(new Error(`Unexpected TutorBotState "${tutorBotState}"`), null);
            return getUnavailableConfig({ botName });
    }
}
