/* eslint-disable max-lines-per-function */
import clsx from 'clsx';
import { type BaseUser, getCohort, getPreferredName } from 'Users';
import { type TFunction } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { memo, useCallback, useEffect, useState } from 'react';
import Button from 'Button';
import { faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEventLogger } from 'FrontRoyalAngular';
import { type BotUiContext, TUTOR_BOT_ROUND_BUTTON_SIZE } from 'TutorBotConversation';
import { type OutgoingMessage, type PretendAiMessage } from '../types/ChatMessage.types';
import { type BotClientContext } from '../types/Context.types';
import {
    chatActions,
    getActiveConversationId,
    getHasChangedTopic,
    getAiMessageLoading,
    getActiveConversationMessages,
    getErroredAiMessage,
} from '../redux/chat';
import { createPretendAiMessage, isPretendAiMessage } from '../utils/ChatMessage';
import { useConversation } from '../hooks/useConversation';
import { type MessageFormValues } from '../types/OtherInternalTypes.types';
import { type GreetingMessageConfig } from '../types/GreetingMessageConfig.types';
import { type TutorBotChatStatusConfig } from '../types/TutorBotChatStatusConfig.types';
import { ClearButton } from '../MessageForm/ClearButton';
import { GreetingMessage } from '../GreetingMessage';
import { Message } from '../Message/Message';
import { MESSAGE_INPUT_MAX_LENGTH } from '../constants';
import { MessageForm } from '../MessageForm/MessageForm';
import { useScrollToBottomOfChat } from '../hooks/useScrollToBottomOfChat';
import { WelcomeModal } from '../WelcomeModal/WelcomeModal';
import { ChatContextProvider, useChatContext } from './ChatContext';

type ErrorMessageFactory = (conversationId: string, t: TFunction) => PretendAiMessage;
const getErrorMessage: ErrorMessageFactory = (conversationId: string, t: TFunction) =>
    createPretendAiMessage({
        content: t('chat.chat.error'),
        id: 'error-message',
        conversationId,
    });

type Props = {
    currentUser: BaseUser;
    clientContext: BotClientContext;
    tutorBotStatusConfig: TutorBotChatStatusConfig;
    greetingMessageConfig?: GreetingMessageConfig;
    botName: string;

    // See comment near the BaseMessage type about what it means to be an "initial" message
    initialMessage?: OutgoingMessage | PretendAiMessage;
    endConversationOnClose?: boolean;
    enableSetNewTopic?: boolean;
    supportTab?: boolean;
    welcomeModalClassname?: string;
    toggleAudioOrText?: () => void;
    spacingBeneathLowestButton?: number;
};

// There are certain cases where the precise amount of spacing at the bottom
// of the screen matters. See comments near where spacingBeneathLowestButton is set
// in order to understand that. When we're not trying to line up with anything
// else, 48px works
const DEFAULT_SPACING_BENEATH_LOWEST_BUTTON = 48;

function useLogLaunchEvent(uiContext: BotUiContext) {
    const EventLogger = useEventLogger();

    useEffect(() => {
        EventLogger.log('tutorbot:launched_text_chat', { label: uiContext, ui_context: uiContext });
    }, [uiContext, EventLogger]);
}

function ChatComponent({
    currentUser,
    botName,
    clientContext: currentClientContext,
    initialMessage: currentInitialMessage,
    endConversationOnClose = false,
    enableSetNewTopic = false,
    supportTab = false,
    toggleAudioOrText,
    spacingBeneathLowestButton = DEFAULT_SPACING_BENEATH_LOWEST_BUTTON,
}: Props): JSX.Element {
    const dispatch = useDispatch();
    const hasChangedTopic = useSelector(getHasChangedTopic);
    const { t } = useTranslation('back_royal');
    const activeConversationId = useSelector(getActiveConversationId);
    const aiMessageLoading = useSelector(getAiMessageLoading);
    const chatHistory = useSelector(getActiveConversationMessages);
    useLogLaunchEvent(currentClientContext.uiContext);

    const erroredAiMessage = useSelector(getErroredAiMessage);

    // clientContext, initialMessage, and user are only used on initialization, so we
    // don't pay attention to changes to the parameters after the component is rendered
    const [clientContext] = useState(() => currentClientContext);
    const [rawInitialMessage] = useState(() => currentInitialMessage);
    const [user] = useState(() => currentUser);
    const cohort = getCohort(currentUser);

    const { tutorBotStatusConfig } = useChatContext();
    const { isLocked } = tutorBotStatusConfig;

    // If the lesson player launches AI Tutor when it is locked (which, at least today, would only be
    // because DISABLE_TUTOR_BOT is true), then we don't send the initial message.
    let initialMessage: OutgoingMessage | PretendAiMessage | undefined | null = rawInitialMessage;
    if (initialMessage && isLocked) {
        initialMessage = null;
    }

    const userName = getPreferredName(user);

    const { chatWindowRef, chatMessagesContainerRef } = useScrollToBottomOfChat();

    const { retry, askUserMessage, startNewConversation, cancelCurrentStreamingMessage } = useConversation(
        clientContext,
        cohort?.id || null,
        initialMessage,
        endConversationOnClose,
    );
    //---------------------------
    // Conversations
    //---------------------------

    const handleSubmit = useCallback(
        (data: MessageFormValues) => {
            if (!data?.message) return;
            askUserMessage({ content: data.message, role: 'human' }, supportTab);
            dispatch(chatActions.setHasChangedTopic({ hasChangedTopic: false }));
        },
        [askUserMessage, dispatch, supportTab],
    );

    const handleNewTopicClick = useCallback(() => {
        if (hasChangedTopic) return;

        // Reset the scroll to the top in order to ensure
        // the scrollToBottomOfChat animates the sole GreetingMessage
        // into view when a user starts a new topic.
        if (chatWindowRef?.current) {
            chatWindowRef.current.scrollTop = 0;
        }

        startNewConversation();
        dispatch(chatActions.setHasChangedTopic({ hasChangedTopic: true }));
    }, [hasChangedTopic, startNewConversation, dispatch, chatWindowRef]);

    const handleCancelClick = useCallback(() => {
        cancelCurrentStreamingMessage('by_user');
    }, [cancelCurrentStreamingMessage]);

    const disableInput = aiMessageLoading || isLocked;

    // When we're showing the microphone button to toggle to voice, there's no
    // space for the character count
    const hideCharacterCount = !!toggleAudioOrText;

    return (
        <>
            <div className="absolute left-0 right-0 top-5 z-20 flex flex-col items-center">
                {/* mobile-only new topic button */}
                {enableSetNewTopic && (
                    <ClearButton
                        className="mb-5 basis-auto shadow-smallish sm:hidden"
                        onClick={handleNewTopicClick}
                        isLocked={isLocked}
                    />
                )}
                <WelcomeModal />
            </div>
            <div className={clsx('flex h-full flex-col')}>
                <div className={clsx('grow overflow-y-hidden', { 'px-7.5': supportTab })}>
                    <div
                        ref={chatWindowRef}
                        className="h-full w-full overflow-y-auto pb-3 pt-4 scrollbar-hide sm:pb-0 "
                        data-testid="chat-window"
                    >
                        <div data-testid="chat-buffer" className="flex h-full flex-col justify-end" />

                        <div data-testid="chat-messages-container" ref={chatMessagesContainerRef}>
                            {activeConversationId && <GreetingMessage name={userName} />}

                            {chatHistory.map(
                                (message, i) =>
                                    !message.hasError &&
                                    !message.hidden && (
                                        <Message
                                            message={message}
                                            key={message.id}
                                            // We are trying to avoid showing the fake spinner again when the
                                            // chat window is closed and re-opened. This logic works to accomplish
                                            // that if there are any messages after the pretend message, which
                                            // is good enough and keeps the logic simple.
                                            showPretendLoadingSpinner={
                                                isPretendAiMessage(message) && i === chatHistory.length - 1
                                            }
                                        />
                                    ),
                            )}

                            {erroredAiMessage && activeConversationId && (
                                <Message retryClick={retry} message={getErrorMessage(activeConversationId, t)} />
                            )}
                        </div>
                    </div>
                </div>
                <div data-testid="chat-controls" className={clsx('mb relative grow-0 pt-1', { 'px-7.5': supportTab })}>
                    <div
                        data-id="chat-input-wrapper"
                        className={clsx('relative z-10 sm:mb-12')}
                        style={{ marginBottom: spacingBeneathLowestButton }}
                    >
                        <div className={clsx('relative flex items-stretch gap-[14px]')}>
                            {/* desktop-only new topic button */}
                            {enableSetNewTopic && (
                                <ClearButton
                                    className="hidden basis-auto self-end sm:flex"
                                    onClick={handleNewTopicClick}
                                    isLocked={isLocked}
                                />
                            )}
                            <MessageForm
                                onSubmit={handleSubmit}
                                className="flex-1"
                                maxLength={MESSAGE_INPUT_MAX_LENGTH}
                                disableInput={disableInput}
                                onCancelClick={handleCancelClick}
                                canCancel={aiMessageLoading}
                                hideCharacterCount={hideCharacterCount}
                            />
                            {toggleAudioOrText && (
                                <Button
                                    isIconOnly
                                    color="primary-gradient"
                                    radius="full"
                                    size="lg"
                                    className={clsx(TUTOR_BOT_ROUND_BUTTON_SIZE, 'self-center')}
                                    onPress={toggleAudioOrText}
                                >
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </Button>
                            )}
                        </div>
                        <div
                            // This is absolutely positioned so that it doesn't interfere with the bottom margin of the input,
                            // which has to match another style (see comment in chat-input-wrapper element above)
                            className={clsx(
                                'absolute w-full pt-[10px] text-center text-[10px] text-beige-for-text',
                                disableInput && 'opacity-50',
                            )}
                        >
                            {t('chat.chat.bot_can_make_mistakes', { botName })}
                        </div>
                    </div>
                    <div
                        data-testid="scroll-haze"
                        className="absolute bottom-0 left-0 z-0 h-[115%] w-full bg-[linear-gradient(to_top,_rgba(251,251,251,1)_0%,_rgba(251,251,251,.95)_80%,_transparent_100%)] sm:h-[130%]"
                    />
                </div>
            </div>
        </>
    );
}

// We need a wrapper element so that the loading boundary can capture errors thrown while
// setting up the tutorBotStatusConfig, while that config is still accessible to the element
function Wrapper(props: Props) {
    const { tutorBotStatusConfig, welcomeModalClassname, greetingMessageConfig } = props;
    return (
        <ChatContextProvider
            tutorBotStatusConfig={tutorBotStatusConfig}
            greetingMessageConfig={greetingMessageConfig}
            welcomeModalClassname={welcomeModalClassname}
        >
            <ChatComponent {...props} />
        </ChatContextProvider>
    );
}

export const Chat = memo(Wrapper) as typeof Wrapper;

export default Chat;
