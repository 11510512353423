window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.startScreenButtonWithTooltip = window.locales.am.startScreenButtonWithTooltip || {};window.locales.am.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.am.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.am.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.am.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "እንጀምር",
    "tooltipTitle": "ለማቆየት ይናገሩ!",
    "tooltipDescription": "ወደ ፊት ከመሄድዎ በፊት ትምህርትዎን ያጠናክሩ። የመጨረሻውን ትምህርት ቁልፍ ነጥቦች ለመገምገም በይነተገናኝ፣ በድምጽ ላይ የተመሰረተ ውይይት ውስጥ ይሳተፉ።",
    "beginExam": "ፈተና ጀምር",
    "beginAssessment": "Smartcase ጀምር"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.startScreenButtonWithTooltip = window.locales.ar.startScreenButtonWithTooltip || {};window.locales.ar.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.ar.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.ar.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.ar.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "البدء",
    "tooltipTitle": "قلها لتحتفظ بها!",
    "tooltipDescription": "عزز ما تعلمته قبل المضي قدمًا. شارك في مناقشة تفاعلية تعتمد على الصوت لمراجعة النقاط الرئيسية في الدرس الأخير.",
    "beginExam": "بدء الامتحان",
    "beginAssessment": "ابدأ Smartcase"
}
};window.locales.en = window.locales.en || {};window.locales.en.startScreenButtonWithTooltip = window.locales.en.startScreenButtonWithTooltip || {};window.locales.en.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.en.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.en.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.en.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "Get Started",
    "tooltipTitle": "Say it to retain it!",
    "tooltipDescription": "Reinforce your learning before moving ahead. Engage in an interactive, voice-based discussion to review the last lesson's key points.",
    "beginExam": "Begin Exam",
    "beginAssessment": "Begin Smartcase"
}
};window.locales.es = window.locales.es || {};window.locales.es.startScreenButtonWithTooltip = window.locales.es.startScreenButtonWithTooltip || {};window.locales.es.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.es.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.es.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.es.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "Empezar",
    "tooltipTitle": "¡Dilo para retenerlo!",
    "tooltipDescription": "Refuerza lo aprendido antes de seguir adelante. Participa en un debate interactivo por voz para repasar los puntos clave de la última lección.",
    "beginExam": "Comenzar el examen",
    "beginAssessment": "Iniciar Smartcase"
}
};window.locales.it = window.locales.it || {};window.locales.it.startScreenButtonWithTooltip = window.locales.it.startScreenButtonWithTooltip || {};window.locales.it.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.it.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.it.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.it.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "Iniziare",
    "tooltipTitle": "Dillo per ricordartelo!",
    "tooltipDescription": "Rafforza il tuo apprendimento prima di procedere. Partecipa a una discussione interattiva basata sulla voce per rivedere i punti chiave dell'ultima lezione.",
    "beginExam": "Inizia l'esame",
    "beginAssessment": "Inizia Smartcase"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.startScreenButtonWithTooltip = window.locales.zh.startScreenButtonWithTooltip || {};window.locales.zh.startScreenButtonWithTooltip.startScreenButtonWithTooltip = window.locales.zh.startScreenButtonWithTooltip.startScreenButtonWithTooltip || {};window.locales.zh.startScreenButtonWithTooltip.startScreenButtonWithTooltip = {...window.locales.zh.startScreenButtonWithTooltip.startScreenButtonWithTooltip, ...{
    "getStarted": "立即开始",
    "tooltipTitle": "说出来是为了留住它！",
    "tooltipDescription": "在继续学习之前巩固所学内容。参与互动式语音讨论，复习上一课的要点。",
    "beginExam": "开始考试",
    "beginAssessment": "开始 Smartcase"
}
};