window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.chat = window.locales.am.chat || {};window.locales.am.chat.chat = window.locales.am.chat.chat || {};window.locales.am.chat.chat = {...window.locales.am.chat.chat, ...{
    "new_topic": "አዲስ ርዕስ",
    "submit_message": "መልእክት አስገባ",
    "talk_about_next": "ቀጥሎ ስለምን መነጋገር ይፈልጋሉ?",
    "hi_user": "ሰላም፣ {userName}! እንኳን ደህና መጣህ. ዛሬ እንዴት መርዳት እችላለሁ?",
    "learn_more": "ተጨማሪ እወቅ:",
    "rate_thumbs_up": "ይህንን መልስ በአውራ ጣት ወደ ላይ ደረጃ ይስጡት።",
    "rate_thumbs_down": "ይህንን መልስ በአውራ ጣት ወደ ታች ደረጃ ይስጡት።",
    "ai_welcome_message_stay_tuned": "ለማሻሻያዎች እና አዳዲስ መስተጋብር መንገዶችን ይጠብቁ!",
    "error": "ውይ። የሆነ ስህተት ተከስቷል. እባክዎ ዳግም ይሞክሩ.",
    "exam_in_progress_message": "ሰላም፣ {name}! {examTitle}ን እንደጨረሱ ቻታችን እንደገና ይገኛል። መልካም እድል!",
    "cancel_response": "ሰርዝ",
    "locked_unavailable": "{botName} በአሁኑ ጊዜ አይገኝም፣ ለተፈጠረው ችግር እናዝናለን።",
    "bot_can_make_mistakes": "{botName} ስህተት ሊሠራ ይችላል። ጠቃሚ መረጃን ለማጣራት ያስቡበት."
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.chat = window.locales.ar.chat || {};window.locales.ar.chat.chat = window.locales.ar.chat.chat || {};window.locales.ar.chat.chat = {...window.locales.ar.chat.chat, ...{
    "new_topic": "موضوع جديد",
    "submit_message": "تقديم رسالة",
    "talk_about_next": "عن ماذا تريد أن نتحدث بعد ذلك؟",
    "hi_user": "مرحبًا {userName}! مرحبًا بعودتك. كيف يمكنني المساعدة اليوم؟",
    "learn_more": "يتعلم أكثر:",
    "rate_thumbs_up": "قيم هذه الإجابة بإبهام لأعلى.",
    "rate_thumbs_down": "قيم هذه الإجابة بإعجاب.",
    "ai_welcome_message_stay_tuned": "ترقبوا الترقيات والطرق الجديدة للتفاعل!",
    "error": "أُووبس. هناك خطأ ما. حاول مرة اخرى.",
    "exam_in_progress_message": "مرحبًا {name}! ستكون محادثتنا متاحة مرة أخرى بمجرد إكمال {examTitle}. حظا سعيدا!",
    "cancel_response": "يلغي",
    "locked_unavailable": "{botName} غير متاح حاليًا، ونحن نأسف للإزعاج.",
    "bot_can_make_mistakes": "قد يرتكب {botName} أخطاءً. فكر في التحقق من المعلومات المهمة."
}
};window.locales.en = window.locales.en || {};window.locales.en.chat = window.locales.en.chat || {};window.locales.en.chat.chat = window.locales.en.chat.chat || {};window.locales.en.chat.chat = {...window.locales.en.chat.chat, ...{
    "new_topic": "New Topic",
    "submit_message": "Submit Message",
    "talk_about_next": "What would you like to talk about next?",
    "cancel_response": "Cancel",
    "ai_welcome_message_stay_tuned": "Stay tuned for upgrades and new ways to interact!",
    "learn_more": "Learn More:",
    "rate_thumbs_up": "Rate this answer with thumbs up.",
    "rate_thumbs_down": "Rate this answer with thumbs down.",
    "error": "Oops. Something went wrong. Please try again.",
    "exam_in_progress_message": "Hi, {name}! Our chat will be available again once you've completed the {examTitle}. Best of luck!",
    "locked_unavailable": "{botName} is currently unavailable, we are sorry for the inconvenience.",
    "bot_can_make_mistakes": "{botName} can make mistakes. Consider checking important information."
}
};window.locales.es = window.locales.es || {};window.locales.es.chat = window.locales.es.chat || {};window.locales.es.chat.chat = window.locales.es.chat.chat || {};window.locales.es.chat.chat = {...window.locales.es.chat.chat, ...{
    "new_topic": "Nuevo tema",
    "submit_message": "Enviar mensaje",
    "talk_about_next": "¿De qué te gustaría hablar a continuación?",
    "hi_user": "¡Hola, {userName}! Bienvenido de nuevo. ¿Cómo puedo ayudar hoy?",
    "learn_more": "Aprende más:",
    "rate_thumbs_up": "Califica esta respuesta con pulgares arriba.",
    "rate_thumbs_down": "Califica esta respuesta con los pulgares hacia abajo.",
    "ai_welcome_message_stay_tuned": "¡Estén atentos a las actualizaciones y nuevas formas de interactuar!",
    "error": "Ups. Algo salió mal. Inténtalo de nuevo.",
    "exam_in_progress_message": "Hola, {name}! Nuestro chat volverá a estar disponible una vez que haya completado el {examTitle}. ¡Toda la suerte!",
    "cancel_response": "Cancelar",
    "locked_unavailable": "{botName} no está disponible actualmente, lamentamos las molestias.",
    "bot_can_make_mistakes": "{botName} puede cometer errores. Considere verificar la información importante."
}
};window.locales.it = window.locales.it || {};window.locales.it.chat = window.locales.it.chat || {};window.locales.it.chat.chat = window.locales.it.chat.chat || {};window.locales.it.chat.chat = {...window.locales.it.chat.chat, ...{
    "new_topic": "Nuovo argomento",
    "submit_message": "Invia messaggio",
    "talk_about_next": "Di cosa vorresti parlare dopo?",
    "hi_user": "Ciao, {userName}! Bentornato. Come posso aiutare oggi?",
    "learn_more": "Saperne di più:",
    "rate_thumbs_up": "Vota questa risposta con pollice in su.",
    "rate_thumbs_down": "Valuta questa risposta con il pollice in giù.",
    "ai_welcome_message_stay_tuned": "Resta sintonizzato per aggiornamenti e nuovi modi di interagire!",
    "error": "Ops. Qualcosa è andato storto. Per favore riprova.",
    "exam_in_progress_message": "Ciao, {name}! La nostra chat sarà di nuovo disponibile una volta completato il {examTitle}. Buona fortuna!",
    "cancel_response": "Annulla",
    "locked_unavailable": "{botName} non è al momento disponibile, ci scusiamo per l'inconveniente.",
    "bot_can_make_mistakes": "{botName} può commettere errori. Valuta di controllare le informazioni importanti."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.chat = window.locales.zh.chat || {};window.locales.zh.chat.chat = window.locales.zh.chat.chat || {};window.locales.zh.chat.chat = {...window.locales.zh.chat.chat, ...{
    "new_topic": "新话题",
    "submit_message": "提交消息",
    "talk_about_next": "接下来你想谈什么？",
    "hi_user": "嗨，{userName}！欢迎回来。今天我能提供什么帮助？",
    "learn_more": "了解更多：",
    "rate_thumbs_up": "竖起大拇指评价这个答案。",
    "rate_thumbs_down": "用大拇指向下评价这个答案。",
    "ai_welcome_message_stay_tuned": "请继续关注升级和新的互动方式！",
    "error": "哎呀。出了些问题。请再试一次。",
    "exam_in_progress_message": "嗨，{name}！完成 {examTitle} 后，我们的聊天将再次可用。祝你好运！",
    "cancel_response": "取消",
    "locked_unavailable": "{botName}目前不可用，我们对此造成的不便深感抱歉。",
    "bot_can_make_mistakes": "{botName}可能会出错。请考虑检查重要信息。"
}
};