/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { type Hexagon } from './Hexagon';
import { useTrackAudioData } from './useTrackAudioData';

type AnimateHexagonsParams = {
    ctx: CanvasRenderingContext2D;
    movementAreaWidth: number;
    movementAreaHeight: number;
    maskRadius: number;
    maxMaskSizeIncrease: number;
    hexagonsRef: React.MutableRefObject<Hexagon[]>;
    hexagonsBackRef: React.MutableRefObject<Hexagon[]>;
    gradientColors: { inner: string; outer: string };
};

export const useAnimateHexagons = () => {
    const { getUserAudioData, getAgentAudioData } = useTrackAudioData();

    const animate = useCallback(
        (params: AnimateHexagonsParams) => {
            let animationFrameId: number;

            const _animate = () => {
                const {
                    ctx,
                    movementAreaHeight,
                    movementAreaWidth,
                    maskRadius,
                    maxMaskSizeIncrease,
                    hexagonsRef,
                    hexagonsBackRef,
                    gradientColors,
                } = params;

                ctx.clearRect(0, 0, movementAreaWidth, movementAreaHeight);

                // Get the audio data from both sources
                const { userAmplitude, userFrequencyData } = getUserAudioData();
                const { agentAmplitude, agentFrequencyData } = getAgentAudioData();

                // Use the audio data from the source with the highest amplitude
                const amplitude = Math.max(userAmplitude, agentAmplitude);
                const frequencyData = userAmplitude > agentAmplitude ? userFrequencyData : agentFrequencyData;

                const dynamicMaskRadius = maskRadius + (amplitude / 255) * maxMaskSizeIncrease;
                const pulseFactor = 1 + amplitude / 255;
                const halfHeight = movementAreaHeight / 2;
                const halfWidth = movementAreaWidth / 2;

                // Draw the visualization
                ctx.save();
                ctx.beginPath();
                ctx.arc(halfWidth, halfHeight, dynamicMaskRadius, 0, Math.PI * 2);
                ctx.clip();

                // Background gradient
                const gradient = ctx.createRadialGradient(
                    halfWidth,
                    halfHeight,
                    0,
                    halfWidth,
                    halfHeight,
                    dynamicMaskRadius,
                );
                gradient.addColorStop(0, gradientColors.inner);
                gradient.addColorStop(1, gradientColors.outer);
                ctx.fillStyle = gradient;
                ctx.beginPath();
                ctx.arc(halfWidth, halfHeight, dynamicMaskRadius, 0, Math.PI * 2);
                ctx.fill();

                // Draw hexagons
                // Start with background hexagons at reduced opacity
                ctx.save();
                ctx.globalAlpha = 0.5;
                hexagonsBackRef.current.forEach((hex, i) => {
                    const freqValue = frequencyData ? frequencyData[i % frequencyData.length] : amplitude;
                    hex.update(
                        amplitude,
                        freqValue,
                        halfWidth,
                        halfHeight,
                        0,
                        movementAreaWidth,
                        0,
                        movementAreaHeight,
                    );
                    hex.draw(ctx, pulseFactor);
                });
                ctx.restore();

                // Draw foreground hexagons
                hexagonsRef.current.forEach((hex, i) => {
                    const freqValue = frequencyData ? frequencyData[i % frequencyData.length] : amplitude;
                    hex.update(
                        amplitude,
                        freqValue,
                        halfWidth,
                        halfHeight,
                        0,
                        movementAreaWidth,
                        0,
                        movementAreaHeight,
                    );
                    hex.draw(ctx, pulseFactor);
                });

                ctx.restore();
                animationFrameId = requestAnimationFrame(_animate);
            };

            animationFrameId = requestAnimationFrame(_animate);

            return () => cancelAnimationFrame(animationFrameId);
        },
        [getAgentAudioData, getUserAudioData],
    );

    return animate;
};
