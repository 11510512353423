import { type FC } from 'react';
import { Tooltip as HeroUITooltip } from '@heroui/react';
import { twMerge } from 'tailwind-merge';
import { type TooltipProps } from './Tooltip.types';

export const Tooltip: FC<TooltipProps> = ({
    children,
    content,
    size = 'md',
    color = 'default',
    classNames,
    placement = 'top',
    delay = 0,
    closeDelay = 500,
    offset = 7,
    showArrow = true,
    defaultOpen = false,
    isOpen,
    onOpenChange,
}) => (
    <HeroUITooltip
        content={content}
        size={size}
        radius="full"
        shadow="none"
        color={color}
        classNames={{
            base: twMerge('shadow-smallish rounded-full border border-solid border-slate-grey-200', classNames?.base),
            content: twMerge('text-black text-base px-4 py-[.15rem]', classNames?.content),
        }}
        placement={placement}
        delay={delay}
        closeDelay={closeDelay}
        offset={offset}
        showArrow={showArrow}
        defaultOpen={defaultOpen}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
    >
        {children}
    </HeroUITooltip>
);
