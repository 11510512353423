import { useTranslation } from 'react-i18next';
import { type GreetingMessageConfig } from 'TutorBotChat';
import { type I18NLocale } from 'Locale';
import './locales/ai_advisor_chat/greeting_message-en.json';
import { useMemo } from 'react';

const initialGreetingLocaleKeys = [
    'ai_advisor_chat.greeting_message.niceToMeetYou',
    'ai_advisor_chat.greeting_message.getToKnowYou',
    'ai_advisor_chat.greeting_message.newAroundHere',
    'ai_advisor_chat.greeting_message.doYouHaveAQuestion',
] as const;

const subsequentGreetingLocaleKeys = [
    'ai_advisor_chat.greeting_message.helloAgain',
    'ai_advisor_chat.greeting_message.goodDay',
    'ai_advisor_chat.greeting_message.welcomeBack',
    'ai_advisor_chat.greeting_message.dayOfWeek',
    'ai_advisor_chat.greeting_message.welcomeBackName',
    'ai_advisor_chat.greeting_message.hello',
    'ai_advisor_chat.greeting_message.hi',
    'ai_advisor_chat.greeting_message.greetingsName',
    'ai_advisor_chat.greeting_message.hiThere',
    'ai_advisor_chat.greeting_message.helloHuman',
    'ai_advisor_chat.greeting_message.morning',
    'ai_advisor_chat.greeting_message.afternoon',
    'ai_advisor_chat.greeting_message.evening',
    'ai_advisor_chat.greeting_message.salutations',
    'ai_advisor_chat.greeting_message.niceToSeeYou',
    'ai_advisor_chat.greeting_message.helloAndNiceToSeeYou',
    'ai_advisor_chat.greeting_message.niceToSeeYouName',
    'ai_advisor_chat.greeting_message.greetingsAndHappyDay',
    'ai_advisor_chat.greeting_message.greatToHaveYou',
    'ai_advisor_chat.greeting_message.heyThere',
] as const;

const subsequentPromptLocaleKeys = [
    'ai_advisor_chat.greeting_message.doYouHave',
    'ai_advisor_chat.greeting_message.whatCanI',
    'ai_advisor_chat.greeting_message.whatQuery',
    'ai_advisor_chat.greeting_message.whatTopic',
    'ai_advisor_chat.greeting_message.anyQuestions',
    'ai_advisor_chat.greeting_message.whatSubjectMatter',
    'ai_advisor_chat.greeting_message.whatSubject',
    'ai_advisor_chat.greeting_message.whatTopicRequires',
    'ai_advisor_chat.greeting_message.howMayI',
    'ai_advisor_chat.greeting_message.anythingICanHelp',
    'ai_advisor_chat.greeting_message.whatQuestion',
    'ai_advisor_chat.greeting_message.howCanI',
    'ai_advisor_chat.greeting_message.whatInformation',
    'ai_advisor_chat.greeting_message.areaOfInterest',
    'ai_advisor_chat.greeting_message.whatChallenges',
    'ai_advisor_chat.greeting_message.whichConcept',
    'ai_advisor_chat.greeting_message.whatsOnYourMind',
] as const;

function toLocales(keys: readonly string[], params: Record<string, string> = {}): I18NLocale[] {
    return keys.map(key => ({ key, params }));
}

const filterSubsequentGreetingsByTime = () => {
    const hours = new Date().getHours();
    if (hours >= 3 && hours < 11) return subsequentGreetingLocaleKeys.filter(key => !key.match(/afternoon|evening/));
    if (hours >= 12 && hours < 15) return subsequentGreetingLocaleKeys.filter(key => !key.match(/morning|evening/));
    return subsequentGreetingLocaleKeys.filter(key => !key.match(/morning|afternoon/));
};

function getSubsequentGreetingLocales({ dayOfWeek }: { dayOfWeek: string }) {
    const filteredGreetings = filterSubsequentGreetingsByTime();

    return toLocales(filteredGreetings, { dayOfWeek });
}

export function useBotPageGreetingConfig(): GreetingMessageConfig {
    const { t } = useTranslation('back_royal');
    const dayOfWeek = t(`ai_advisor_chat.greeting_message.${new Date().getDay()}`);

    const config = useMemo(
        () => ({
            hasSeenInitialGreetingLocalStorageKey: 'hasSeenInitialGreeting',
            initialGreetingLocales: toLocales(initialGreetingLocaleKeys),
            subsequentGreetingLocales: getSubsequentGreetingLocales({ dayOfWeek }),
            subsequentPromptLocales: toLocales(subsequentPromptLocaleKeys),
        }),
        [dayOfWeek],
    );

    return config;
}
