window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.TutorBotVoiceAgent = window.locales.am.TutorBotVoiceAgent || {};window.locales.am.TutorBotVoiceAgent.StartDiscussionButton = window.locales.am.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.am.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.am.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "ውይይት ጀምር"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.TutorBotVoiceAgent = window.locales.ar.TutorBotVoiceAgent || {};window.locales.ar.TutorBotVoiceAgent.StartDiscussionButton = window.locales.ar.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.ar.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.ar.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "ابدأ المناقشة"
}
};window.locales.en = window.locales.en || {};window.locales.en.TutorBotVoiceAgent = window.locales.en.TutorBotVoiceAgent || {};window.locales.en.TutorBotVoiceAgent.StartDiscussionButton = window.locales.en.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.en.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.en.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "Start Discussion"
}
};window.locales.es = window.locales.es || {};window.locales.es.TutorBotVoiceAgent = window.locales.es.TutorBotVoiceAgent || {};window.locales.es.TutorBotVoiceAgent.StartDiscussionButton = window.locales.es.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.es.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.es.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "Iniciar discusión"
}
};window.locales.it = window.locales.it || {};window.locales.it.TutorBotVoiceAgent = window.locales.it.TutorBotVoiceAgent || {};window.locales.it.TutorBotVoiceAgent.StartDiscussionButton = window.locales.it.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.it.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.it.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "Inizia la discussione"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.TutorBotVoiceAgent = window.locales.zh.TutorBotVoiceAgent || {};window.locales.zh.TutorBotVoiceAgent.StartDiscussionButton = window.locales.zh.TutorBotVoiceAgent.StartDiscussionButton || {};window.locales.zh.TutorBotVoiceAgent.StartDiscussionButton = {...window.locales.zh.TutorBotVoiceAgent.StartDiscussionButton, ...{
    "startDiscussion": "开始讨论"
}
};