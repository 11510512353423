import Button from 'Button';
import { type FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { TutorBotMessageContent } from 'TutorBotMessageContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons/faRefresh';
import { faMessageSlash } from '@fortawesome/pro-regular-svg-icons/faMessageSlash';
import clsx from 'clsx';
import { Text } from 'Text';
import { useTranslation } from 'react-i18next';
import { TUTOR_BOT_BUTTON_HEIGHT } from 'TutorBotConversation';
import { type TranscribedAudioMessage } from '../../TutorBotVoiceAgent.types';
import { getVoiceAgentMessages } from '../../redux/voiceAgent';
import { BottomAlignedWithButton } from '../BottomAlignedWithButton';
import '../../locales/TutorBotVoiceAgent/TutorBotVoiceAgent-en.json';

type Props = {
    conversationId: string;
    restartDiscussion: () => void;
    spacingBeneathLowestButton: number;
};

// Maybe this logic should be inside of TutorBotMessageContent, but that's kinda tricky
// when it is rendering `children` rather than text
function maybeWrapInQuotes(message: TranscribedAudioMessage) {
    return message.role === 'human' ? `"${message.content}"` : message.content;
}

const TranscriptMessages = ({ transcript }: { transcript: TranscribedAudioMessage[] }) =>
    transcript.map(message => (
        <TutorBotMessageContent
            className="first:mt-auto"
            key={message.id}
            isHumanMessage={message.role === 'human'}
            contentType={message.contentType}
        >
            {maybeWrapInQuotes(message)}
        </TutorBotMessageContent>
    ));

const TranscriptNotAvailable = ({ text }: { text: string }) => (
    <div className="flex flex-1 flex-col items-center justify-center gap-y-4.5">
        <FontAwesomeIcon size="4x" className="text-slate-grey-200" icon={faMessageSlash} />
        <Text>{text}</Text>
    </div>
);

const PostDiscussionComponent: FC<Props> = ({ conversationId, restartDiscussion, spacingBeneathLowestButton }) => {
    const transcript = useSelector(getVoiceAgentMessages(conversationId));
    const { t } = useTranslation('back_royal', {
        keyPrefix: 'TutorBotVoiceAgent.TutorBotVoiceAgent',
    });
    return (
        <BottomAlignedWithButton
            Main={
                <>
                    {transcript.length ? (
                        <TranscriptMessages transcript={transcript} />
                    ) : (
                        <TranscriptNotAvailable text={t('transcriptNotAvailable')} />
                    )}
                </>
            }
            Button={
                <Button
                    size="lg"
                    radius="full"
                    variant="bordered"
                    className={clsx('text-slate-grey', TUTOR_BOT_BUTTON_HEIGHT)}
                    onPress={restartDiscussion}
                >
                    <FontAwesomeIcon className="text-md" icon={faRefresh} />
                    <span className="me-2 text-md font-semibold">{t('restartDiscussion')}</span>
                </Button>
            }
            spacingBeneathLowestButton={spacingBeneathLowestButton}
        />
    );
};

export const PostDiscussion = memo(PostDiscussionComponent) as typeof PostDiscussionComponent;
