/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Action, type Middleware, type MiddlewareAPI, isAction } from '@reduxjs/toolkit';
import EventLoggerProvider from 'EventLogger/EventLoggerProvider';
import { type EventLogger } from 'EventLogger';
import { merge } from 'lodash/fp';
import { type EmptyObject, type AnyObject } from '@Types';
import { logTutorBotMessage, type BotUiContext } from 'TutorBotConversation';
import { type RealAiMessageSentEventExtraPayloadProperties, type ChatMessage } from '../../types/ChatMessage.types';
import { isRealAiMessage } from '../../utils/ChatMessage';
import { getSourceLocation } from '../../utils/sourceLocationHelpers';
import { chatActions } from './chatSlice';
import { getActiveConversationId, getActiveConversationUiContext, getMessageFromMessageId } from './selectors';

const { addMessage, mergeAiMessageAttrs } = chatActions;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isActionWithPayload = (action: unknown): action is Action & { payload: any } =>
    isAction(action) && 'payload' in action;

const isNewMessage = (action: unknown): action is ReturnType<typeof addMessage> =>
    isActionWithPayload(action) && action.type === addMessage.type && !action.payload?.skipLogging;

const isCompletedMessage = (action: unknown): action is ReturnType<typeof mergeAiMessageAttrs> =>
    isActionWithPayload(action) &&
    action.type === mergeAiMessageAttrs.type &&
    !!action.payload?.attrs?.complete &&
    !action.payload?.attrs?.hasError;

const isErrorMessage = (
    action: unknown,
): action is ReturnType<typeof addMessage> | ReturnType<typeof mergeAiMessageAttrs> => {
    if (!isActionWithPayload(action)) return false;

    const isAddMessageError =
        action.type === chatActions.addMessage.type &&
        !!action.payload?.message?.hasError &&
        !action.payload?.skipLogging;

    const isMergeAttrsError = action.type === chatActions.mergeAiMessageAttrs.type && !!action.payload?.attrs?.hasError;

    return isAddMessageError || isMergeAttrsError;
};

const logMessage = (
    message: ChatMessage,
    activeConversationId: string,
    EventLogger: EventLogger,
    uiContext: BotUiContext,
) => {
    let extraPayloadProperties: RealAiMessageSentEventExtraPayloadProperties | EmptyObject = {};

    if (isRealAiMessage(message)) {
        const aiMessagePayload: RealAiMessageSentEventExtraPayloadProperties = {
            label: message.role,
            sender_role: message.role,
            ai_answer_sources: message.sources.map(getSourceLocation),
            bot_version: message.aiMessageMetadata.botVersion,
            algorithm: message.aiMessageMetadata.algorithm,
            total_tokens: message.aiMessageMetadata.totalTokens,
            total_tokens_from_cache: message.aiMessageMetadata.totalTokensFromCache,
            total_cost: message.aiMessageMetadata.totalCost,
            total_cost_from_cache: message.aiMessageMetadata.totalCostFromCache,
            prompt_tokens: message.aiMessageMetadata.promptTokens,
            prompt_tokens_from_cache: message.aiMessageMetadata.promptTokensFromCache,
            completion_tokens: message.aiMessageMetadata.completionTokens,
            completion_tokens_from_cache: message.aiMessageMetadata.completionTokensFromCache,
            seconds_to_first_token: message.aiMessageMetadata.secondsToFirstToken,
            seconds_to_completion: message.aiMessageMetadata.secondsToCompletion,
            conversation_context: message.conversationContext,
            canceled: message.canceled,
            hasError: message.hasError,
        };
        extraPayloadProperties = aiMessagePayload;
    }

    // if the message was sent from the support tab
    // log a custom event for metrics tracking in statsig
    if ((message as AnyObject).supportTab) {
        EventLogger.log('tutorbot:message_sent_from_support_modal', {
            tutor_bot_message_id: message.id,
            tutor_bot_conversation_id: activeConversationId,
            sender_role: message.role,
        });
    }

    return logTutorBotMessage({ message, activeConversationId, uiContext, extraPayloadProperties });
};

export const logMessageMiddleware: Middleware =
    ({ getState }: MiddlewareAPI) =>
    next =>
    action => {
        const state = getState();
        const activeConversationId = getActiveConversationId(state);
        const uiContext = getActiveConversationUiContext(state);
        const EventLogger = EventLoggerProvider.get();

        if (!activeConversationId || !uiContext || !EventLogger) return next(action);

        if (isNewMessage(action)) {
            logMessage(action.payload.message, activeConversationId, EventLogger, uiContext);
        }

        if (isCompletedMessage(action)) {
            const { messageId, attrs = {} } = action.payload;

            const message = getMessageFromMessageId(state, messageId);
            logMessage(merge(message, attrs), activeConversationId, EventLogger, uiContext);
        }

        if (isErrorMessage(action)) {
            if (action.type === chatActions.addMessage.type) {
                logMessage(action.payload.message, activeConversationId, EventLogger, uiContext);
            } else {
                const { messageId, attrs = {} } = action.payload;

                const message = getMessageFromMessageId(state, messageId);
                logMessage(merge(message, attrs), activeConversationId, EventLogger, uiContext);
            }
        }

        return next(action);
    };
