import { type ConfigFactory } from 'FrontRoyalConfig';
import { type StreamIguanaObject } from 'Lessons';
import { LockedTutorBotChatState } from './types/TutorBotChatStatusConfig.types';

// This function implements global rules that should apply to all tutorbot UI components.
// If this returns a locked state, then all tutorbot UI components will be locked. But, individual
// components will also implement further rules determining when they should be locked.
export function determineTutorBotLockedState({
    inProgressExam,
    $injector,
}: {
    $injector: ng.auto.IInjectorService;
    inProgressExam: StreamIguanaObject | null;
}): LockedTutorBotChatState | null {
    const ConfigFactory = $injector.get<ConfigFactory>('ConfigFactory');
    if (ConfigFactory.getSync().tutorBotDisabled()) return LockedTutorBotChatState.Unavailable;

    if (inProgressExam) return LockedTutorBotChatState.InProgressExam;

    return null;
}
