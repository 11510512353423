import { useLocalParticipant, useVoiceAssistant } from '@livekit/components-react';
import { type RemoteAudioTrack, createAudioAnalyser, type LocalAudioTrack } from 'livekit-client';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export const useTrackAudioData = () => {
    const { microphoneTrack, isMicrophoneEnabled } = useLocalParticipant();
    const { audioTrack, state } = useVoiceAssistant();
    const userAnalyserRef = useRef<AnalyserNode | null>(null);
    const agentAnalyserRef = useRef<AnalyserNode | null>(null);

    const track = useMemo(() => microphoneTrack?.track, [microphoneTrack?.track]);

    useEffect(() => {
        if (!track) return undefined;
        const { cleanup, analyser } = createAudioAnalyser(track as LocalAudioTrack, {
            fftSize: 256,
            minDecibels: -100,
            maxDecibels: -30,
            cloneTrack: true,
        });
        userAnalyserRef.current = analyser;

        return () => {
            cleanup();
        };
    }, [track]);

    useEffect(() => {
        if (!audioTrack?.publication.track) return undefined;
        const { cleanup, analyser } = createAudioAnalyser(audioTrack.publication.track as RemoteAudioTrack, {
            fftSize: 256,
            minDecibels: -100,
            maxDecibels: -30,
        });

        agentAnalyserRef.current = analyser;

        return () => {
            cleanup();
        };
    }, [audioTrack, audioTrack?.publication.track]);

    const getUserAudioData = useCallback(() => {
        if (!track || !isMicrophoneEnabled) return { userAmplitude: 0, userFrequencyData: null };
        if (!userAnalyserRef.current) return { userAmplitude: 0, userFrequencyData: null };

        const userFrequencyData = new Uint8Array(userAnalyserRef.current.frequencyBinCount);
        userAnalyserRef.current.getByteFrequencyData(userFrequencyData);

        const userAmplitude = userFrequencyData.reduce((sum, value) => sum + value, 0) / userFrequencyData.length;

        return { userAmplitude, userFrequencyData };
    }, [isMicrophoneEnabled, track]);

    const getAgentAudioData = useCallback(() => {
        if (!agentAnalyserRef.current || state !== 'speaking') return { agentAmplitude: 0, agentFrequencyData: null };

        const agentFrequencyData = new Uint8Array(agentAnalyserRef.current.frequencyBinCount);
        agentAnalyserRef.current.getByteFrequencyData(agentFrequencyData);

        const agentAmplitude = agentFrequencyData.reduce((sum, value) => sum + value, 0) / agentFrequencyData.length;

        return { agentAmplitude, agentFrequencyData };
    }, [state]);

    return { getUserAudioData, getAgentAudioData };
};
