import clsx from 'clsx';
import { type ContentProps, Popover } from 'Popover';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'Text';
import { LaunchBotButton } from '../AiAdvisorTooltip/LaunchBotButtons';
import './locales/startScreenButtonWithTooltip/startScreenButtonWithTooltip-en.json';

type Props = {
    onClose: () => void;
    onIntentionalDismiss: () => void;
    onButtonClick: () => void;
    allowOpen: boolean;
    launchBot: () => void;
    isTest: boolean;
    isAssessment: boolean;
    lessonSupportsRecap: boolean;
};

const MICROPHONE_EMOJI = '\u{1F399}\uFE0F'; // 🎙️

export const TooltipContent = ({ titleProps, launchBot }: ContentProps & { launchBot: Props['launchBot'] }) => {
    const { t } = useTranslation('back_royal', {
        keyPrefix: 'startScreenButtonWithTooltip.startScreenButtonWithTooltip',
    });

    return (
        <div className="p-4">
            <div {...titleProps} className="mb-2.5 inline-flex">
                <Text as="h2" className="my-auto me-1.5">
                    {MICROPHONE_EMOJI}
                </Text>
                <Text as="h2">{t('tooltipTitle')}</Text>
            </div>
            <div className="mb-2.5">
                <Text>{t('tooltipDescription')}</Text>
            </div>
            <div>
                <LaunchBotButton
                    className="mt-2.5 min-h-[50px] w-full max-w-[448px] rounded-8 px-4 py-2.5"
                    backwardsClass=""
                    continueButtonVisible={false}
                    buttonKey="recap_last_lesson"
                    launchBot={launchBot}
                />
            </div>
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
export const StartScreenButtonWithTooltip: React.FC<Props> = ({
    onClose,
    onIntentionalDismiss,
    onButtonClick,
    allowOpen,
    launchBot,
    isTest,
    isAssessment,
    lessonSupportsRecap,
}: Props) => {
    const { t } = useTranslation('back_royal', {
        keyPrefix: 'startScreenButtonWithTooltip.startScreenButtonWithTooltip',
    });
    const [isOpen, setIsOpen] = useState(allowOpen);

    const buttonText = useMemo(() => {
        if (isTest) return t('beginExam');
        if (isAssessment) return t('beginAssessment');
        return t('getStarted');
    }, [isTest, isAssessment, t]);

    const tooltipContent = useCallback(
        (props: ContentProps) => <TooltipContent {...props} launchBot={launchBot} />,
        [launchBot],
    );

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                onButtonClick();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onButtonClick]);

    return (
        <Popover
            Content={tooltipContent}
            onClose={() => {
                onClose();
                setIsOpen(false);
            }}
            classNames={{
                base: ['w-full max-w-[448px]', 'data-[placement=top-start]:before:left-[105px]'],
                content: 'p-0',
            }}
            closeOnOverlayClick={false}
            onCloseButtonClick={onIntentionalDismiss}
            isOpenedInitially
            allowOpen={allowOpen}
            arrow={false}
            placement="top"
            closeButton
        >
            <div className="flex flex-col items-center gap-5">
                {/* Show the recap button if the tooltip is closed and the lesson supports recap */}
                {!isOpen && lessonSupportsRecap && (
                    <LaunchBotButton
                        className="min-h-[50px] w-[95%] max-w-[448px] rounded-8 px-4 py-3.5"
                        backwardsClass=""
                        continueButtonVisible={false}
                        buttonKey="recap_last_lesson"
                        launchBot={launchBot}
                    />
                )}
                <button
                    data-testid="start-screen-button"
                    type="button"
                    className={clsx(
                        'flat continue mx-auto my-0 flex min-h-[50px] w-full items-center justify-center px-4 py-2.5 uppercase xs:max-w-[448px] sm:rounded-8',
                        { green: !isAssessment && !isTest, turquoise: isAssessment || isTest },
                    )}
                    onClick={onButtonClick}
                    onTouchStart={onButtonClick}
                >
                    <Text as="h2" className="my-2 text-lg font-semibold text-white">
                        {buttonText}
                    </Text>
                </button>
            </div>
        </Popover>
    );
};
