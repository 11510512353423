import { createSelector } from '@reduxjs/toolkit';
import { type RootStateWithVoiceAgentSlice } from './voiceAgentSlice.types';
import { voiceAgentSlice } from './voiceAgentSlice';
import { type TranscribedAudioMessage } from '../../TutorBotVoiceAgent.types';

export const getVoiceAgentSlice = (rootState: RootStateWithVoiceAgentSlice) => rootState[voiceAgentSlice.name];

export const getAllVoiceAgentMessages = createSelector(getVoiceAgentSlice, voiceAgentState => voiceAgentState.messages);

// Get messages for a specific conversation
const getVoiceAgentMessagesForConversation = (conversationId: string | null) =>
    createSelector(getAllVoiceAgentMessages, (messages): TranscribedAudioMessage[] =>
        Object.values(messages)
            .filter(message => message.conversationId === conversationId)
            .sort((a, b) => a.createdAt - b.createdAt),
    );

// Factory function to create a selector for a specific conversation
export const getVoiceAgentMessages = (conversationId: string | null) =>
    getVoiceAgentMessagesForConversation(conversationId);

const getConversationMetadata = (conversationId: string | null) =>
    createSelector(getVoiceAgentSlice, voiceAgentState =>
        conversationId ? voiceAgentState.conversationMetadata[conversationId] : null,
    );

export const getConversationContext = (conversationId: string | null) =>
    createSelector(
        getConversationMetadata(conversationId),
        conversationMetadata => conversationMetadata?.conversationContext,
    );

export const getAlgorithm = (conversationId: string | null) =>
    createSelector(getConversationMetadata(conversationId), conversationMetadata => conversationMetadata?.algorithm);

export const getBotVersion = (conversationId: string | null) =>
    createSelector(getConversationMetadata(conversationId), conversationMetadata => conversationMetadata?.botVersion);
