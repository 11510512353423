export const EVENT_MESSAGE_SENT = 'tutorbot:message_sent';
export const UI_CONTEXT_EXPLAIN_SCREEN = 'explain_screen';
export const UI_CONTEXT_REVIEW_LESSON = 'review_lesson';
export const UI_CONTEXT_LESSON_PLAYER_SUPPORT = 'lesson_player_support';
export const UI_CONTEXT_BOT_PAGE = 'bot_page';
export const UI_CONTEXT_REVIEW_PREVIOUS_MATERIAL = 'review_previous_material';

// TUTOR_BOT_BUTTON_HEIGHT and TUTOR_BOT_ROUND_BUTTON_SIZE are defined here in
// TutorBotConversation so they can be shared
// between TutorBotChat and TutorBotVoiceAgent. Needs to match the size of the
// "Return to Screen" button on desktop in the LessonPlayer UI (set to 50px in .return-to-screen class in scss),
// because they're lined up next to each other on desktop
export const TUTOR_BOT_BUTTON_HEIGHT = 'h-11 sm:h-12.5';
export const TUTOR_BOT_ROUND_BUTTON_SIZE = `${TUTOR_BOT_BUTTON_HEIGHT} w-11 sm:w-12.5`;
export const DELAY_BETWEEN_PRETEND_MESSAGES_MS = 1350;
export const PRETEND_MESSAGE_SPINNER_DURATION_MS = 750;
