import { generateGuid } from 'guid';
import {
    type OutgoingMessage,
    type PretendAiMessage,
    type RealAiMessage,
    type HumanMessage,
    type OutgoingMessageInputs,
} from '../types/ChatMessage.types';
import { createOutgoingMessage } from '../utils/createOutgoingMessage';
import { createHumanMessage, createPretendAiMessage, createRealAiMessage } from '../utils/ChatMessage';

export function createMockHumanMessage({
    content = 'some content',
    contentType = 'text',
    conversationId,
}: {
    content?: HumanMessage['content'];
    conversationId?: HumanMessage['conversationId'];
    contentType?: HumanMessage['contentType'];
}) {
    conversationId ||= generateGuid();
    return createHumanMessage({ content, conversationId, contentType });
}

export function createMockOutgoingMessage({
    inputs,
    conversationId,
    contentType = 'text',
}: {
    inputs: OutgoingMessageInputs;
    conversationId?: OutgoingMessage['conversationId'];
    contentType?: OutgoingMessage['contentType'];
}) {
    conversationId ||= generateGuid();
    return createOutgoingMessage({ conversationId, contentType, inputs });
}

export function createMockRealAiMessage({
    conversationId,
    contentType = 'text',
}: {
    conversationId?: RealAiMessage['conversationId'];
    contentType?: RealAiMessage['contentType'];
}) {
    conversationId ||= generateGuid();
    return createRealAiMessage({ conversationId, contentType });
}

export function createMockPretendAiMessage({
    conversationId,
    content = 'some content',
    id,
    isInitialMessage = false,
}: {
    content?: HumanMessage['content'];
    conversationId?: PretendAiMessage['conversationId'];
    id?: PretendAiMessage['id'];
    isInitialMessage?: PretendAiMessage['isInitialMessage'];
}) {
    conversationId ||= generateGuid();
    return createPretendAiMessage({ conversationId, content, id, isInitialMessage });
}
