import { Hexagon } from './Hexagon';

type CreateHexagonParams = {
    movementAreaWidth: number;
    movementAreaHeight: number;
    hexagonGradientStart: string;
    hexagonGradientEnd: string;
    hexagonStrokeColor: string;
    maskRadius: number;
    hexagonSizeFactor: number;
};

type BackgroundOrForegroundHexagonsParams = CreateHexagonParams & {
    hexagonBaseSize: number;
    maxSizeVariation: number;
};

const createBackgroundHexagons = ({
    hexagonBaseSize,
    maxSizeVariation,
    movementAreaWidth,
    movementAreaHeight,
    hexagonGradientStart,
    hexagonGradientEnd,
    hexagonStrokeColor,
}: BackgroundOrForegroundHexagonsParams) =>
    Array(100)
        .fill(0)
        .map(() => {
            const x = 0 + Math.random() * movementAreaWidth;
            const y = 0 + Math.random() * movementAreaHeight;
            const size = (hexagonBaseSize + Math.random() * maxSizeVariation) / 2;
            const baseSpeed = (0.5 + Math.random()) * 0.3;
            return new Hexagon(x, y, size, baseSpeed, hexagonGradientStart, hexagonGradientEnd, hexagonStrokeColor);
        });

const createForegroundHexagons = ({
    hexagonBaseSize,
    maxSizeVariation,
    movementAreaWidth,
    movementAreaHeight,
    hexagonGradientStart,
    hexagonGradientEnd,
    hexagonStrokeColor,
}: BackgroundOrForegroundHexagonsParams) =>
    Array(100)
        .fill(0)
        .map(() => {
            const x = 0 + Math.random() * movementAreaWidth;
            const y = 0 + Math.random() * movementAreaHeight;
            const size = hexagonBaseSize + Math.random() * maxSizeVariation;
            const baseSpeed = 0.5 + Math.random();
            return new Hexagon(x, y, size, baseSpeed, hexagonGradientStart, hexagonGradientEnd, hexagonStrokeColor);
        });

export const createHexagons = ({
    maskRadius,
    hexagonSizeFactor,
    movementAreaWidth,
    movementAreaHeight,
    hexagonGradientStart,
    hexagonGradientEnd,
    hexagonStrokeColor,
}: CreateHexagonParams) => {
    const hexagonBaseSize = maskRadius * 0.08; // Calculate base size from mask radius
    const maxSizeVariation = hexagonBaseSize * hexagonSizeFactor; // Calculate variation based on factor

    const backGroundHexagons = createBackgroundHexagons({
        hexagonBaseSize,
        maxSizeVariation,
        movementAreaWidth,
        movementAreaHeight,
        hexagonGradientStart,
        hexagonGradientEnd,
        hexagonStrokeColor,
        maskRadius,
        hexagonSizeFactor,
    });

    const foregroundHexagons = createForegroundHexagons({
        hexagonBaseSize,
        maxSizeVariation,
        movementAreaWidth,
        movementAreaHeight,
        hexagonGradientStart,
        hexagonGradientEnd,
        hexagonStrokeColor,
        maskRadius,
        hexagonSizeFactor,
    });

    return { backGroundHexagons, foregroundHexagons };
};
