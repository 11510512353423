import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'Container';
import { type CurrentUserIguanaObject } from 'Users';
import { Chat, type OutgoingMessage, type PretendAiMessage } from 'TutorBotChat';
import { LoadingBoundary } from 'LoadingBoundary';
import clsx from 'clsx';
import { ReviewPreviousMaterialVoiceAgentRoom } from 'ReviewPreviousMaterialVoiceAgentRoom';
import { useSuspenseFeatureGate } from 'FrontRoyalStatsig';
import { useEventLogger } from 'FrontRoyalAngular';
import { AI_TUTOR_BRAND_NAME } from '../constants';
import { useAiTutorStatusConfig } from '../useAiTutorStatusConfig';
import {
    type LessonPlayerBotUiContext,
    type AiTutorClientContext,
    type ReviewPreviousMaterialClientContext,
    type LessonPlayerClientContext,
    type LessonPlayerBotChatUiContext,
    type LessonPlayerBotContentInfo,
} from '../TutorBotAiTutorChat.types';
import { isLessonPlayerAlgorithmUiContext } from '../isLessonPlayerAlgorithmUiContext';

type Props = {
    currentUser: CurrentUserIguanaObject;
    contentInfo: LessonPlayerBotContentInfo;
    uiContext: LessonPlayerBotChatUiContext;

    // the initialMessage will be passed through to Chat, so we borrow the type from there
    initialMessage?: OutgoingMessage | PretendAiMessage;
    className?: string;
    supportTab?: boolean;
};

function Wrapper(props: Props) {
    return (
        <LoadingBoundary>
            <LessonPlayerBotComponent {...props} />
        </LoadingBoundary>
    );
}

// This is a bit brittle, but we are making room for the "Return to Screen", which
// only shows up in this position in mobile in the lesson player. 86px is:
// * 40px absolute positioning above the Return to Screen Button
// * minus 20px padding above the Chat element, which overlaps the 40px above the Return to Screen (=20px)
// * plus the 46px height of the Return to Screen Button (=66px)
// * plus 20px of spacing between the button and the welcome message (=86px)
const welcomeModalClassname = 'mt-[86px] sm:mt-0';

function getClientContext(
    uiContext: LessonPlayerBotUiContext,
    contentInfo: LessonPlayerBotContentInfo,
): AiTutorClientContext {
    if (isLessonPlayerAlgorithmUiContext(uiContext)) {
        const clientContext: LessonPlayerClientContext = {
            uiContext: 'lesson_player',
            lessonId: contentInfo.lessonId,
            lessonTitle: contentInfo.lessonTitle,
            lessonVersionId: contentInfo.lessonVersionId,
        };
        return clientContext;
    }

    if (uiContext === 'review_previous_material') {
        if (!contentInfo.previousLessonOutline) {
            throw new Error('LessonPlayerBot: review_previous_material uiContext but no previousLessonOutline');
        }
        const clientContext: ReviewPreviousMaterialClientContext = {
            uiContext: 'review_previous_material',
            previousLessonOutline: contentInfo.previousLessonOutline,
        };
        return clientContext;
    }

    throw new Error(`Unsupported uiContext: ${uiContext}`);
}

function isTextChatUiContext(uiContext: LessonPlayerBotUiContext): uiContext is LessonPlayerBotChatUiContext {
    return [
        'lesson_player',
        'review_previous_material',
        'lesson_player_support',
        'explain_screen',
        'review_lesson',
    ].includes(uiContext);
}

// See comment near HandlerProps
type ChatHandler = {
    component: 'Chat';
    uiContext: LessonPlayerBotChatUiContext;
    clientContext: AiTutorClientContext;
};

// See comment near HandlerProps
type ReviewPreviousMaterialAudioHandler = {
    component: 'ReviewPreviousMaterialVoiceAgentRoom';
    streamId: string;
};

// This bit with getHandler is a little confusing. There are some things (clientContext and streamId)
// that are not guaranteed to be non-null in all cases, but must be non-null given certain ui contexts.
// This function ensures that we have everything we need to render the given component.
type Handler = ChatHandler | ReviewPreviousMaterialAudioHandler;
function getHandler({
    uiContext,
    contentInfo,
}: Pick<Props, 'contentInfo'> & { uiContext: LessonPlayerBotUiContext }): Handler {
    if (isTextChatUiContext(uiContext)) {
        return {
            component: 'Chat',
            uiContext,
            clientContext: getClientContext(uiContext, contentInfo),
        };
    }

    if (uiContext === 'review_previous_material_audio') {
        if (!contentInfo.streamId) throw new Error('review_previous_material_audio uiContext requires a streamId');

        return {
            component: 'ReviewPreviousMaterialVoiceAgentRoom',
            streamId: contentInfo.streamId,
        };
    }

    throw new Error('Cannot determine handler');
}

function useLogLaunchEvent({
    uiContext,
    contentInfo,
}: {
    uiContext: LessonPlayerBotUiContext;
    contentInfo: LessonPlayerBotContentInfo;
}) {
    const EventLogger = useEventLogger();

    useEffect(() => {
        EventLogger.log('tutorbot:opened_ai_tutor', {
            label: uiContext,
            ui_context: uiContext,
            lesson_id: contentInfo.lessonId,
            stream_id: contentInfo.streamId,
        });
    }, [EventLogger, uiContext, contentInfo]);
}

function LessonPlayerBotComponent({
    currentUser,
    uiContext: providedUiContext,
    contentInfo,
    initialMessage,
    className,
    supportTab,
}: Props): JSX.Element {
    const botName = AI_TUTOR_BRAND_NAME;
    const tutorBotStatusConfig = useAiTutorStatusConfig({ botName });
    const supportsReviewPreviousMaterialAudio = useSuspenseFeatureGate('audio_recap_lesson');
    const [autoStartVoiceConversation, setAutoStartVoiceConversation] = useState(false);
    useLogLaunchEvent({ uiContext: providedUiContext, contentInfo });

    // The UiContext that's passed in is always one of the Chat UiContexts. When it's `review_previous_material`,
    // we switch over to the `_audio` ui context.
    const [uiContext, setUiContext] = useState<LessonPlayerBotUiContext>(() =>
        providedUiContext === 'review_previous_material' && supportsReviewPreviousMaterialAudio
            ? 'review_previous_material_audio'
            : providedUiContext,
    );

    const toggleAudioOrText = useCallback(() => {
        if (uiContext === 'review_previous_material') {
            // If the user clicks to toggle from text-mode to voice-mode, we want to
            // skip the start screen and just start the conversation
            setAutoStartVoiceConversation(true);
            setUiContext('review_previous_material_audio');
        } else if (uiContext === 'review_previous_material_audio') setUiContext('review_previous_material');
        else throw new Error('Unsupported uiContext');
    }, [uiContext]);

    const handler = useMemo(() => getHandler({ uiContext, contentInfo }), [uiContext, contentInfo]);

    // We need a bottom margin that matches the value in
    // show_frame_player.scss#.return-to-screen-wrapper (see comment there)
    const spacingBeneathLowestButton = 48;

    const showToggleAudioOrTextForChat = useMemo(
        () => uiContext === 'review_previous_material' && supportsReviewPreviousMaterialAudio,
        [supportsReviewPreviousMaterialAudio, uiContext],
    );

    return (
        <Container className={clsx('h-full', className)}>
            {handler.component === 'Chat' && (
                <Chat
                    botName={botName}
                    currentUser={currentUser}
                    clientContext={handler.clientContext}
                    initialMessage={initialMessage}
                    tutorBotStatusConfig={tutorBotStatusConfig}
                    supportTab={supportTab}
                    welcomeModalClassname={welcomeModalClassname}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                    toggleAudioOrText={showToggleAudioOrTextForChat ? toggleAudioOrText : undefined}
                />
            )}
            {handler.component === 'ReviewPreviousMaterialVoiceAgentRoom' && (
                <ReviewPreviousMaterialVoiceAgentRoom
                    nextLessonId={contentInfo.lessonId}
                    streamId={handler.streamId}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                    toggleAudioOrText={toggleAudioOrText}
                    autoStart={autoStartVoiceConversation}
                />
            )}
        </Container>
    );
}

export const LessonPlayerBot = memo(Wrapper) as typeof Wrapper;

export default LessonPlayerBot;
