import { useMemo } from 'react';
import { type BotUiContext } from 'TutorBotConversation';
import { useBuildTranscriptionSegmentsFromEvents } from './useBuildTranscriptionSegmentsFromEvents';
import { mergeTranscriptionSegmentsIntoMessages } from './mergeTranscriptionSegmentsIntoMessages';
import { useStoreTranscriptionMessages } from './useStoreTranscriptionMessages';

/*
    useStoreAudioTranscriptions is a top-level hook which is actually used in VoiceAgentRoom. The other hooks
    in this directory are private to this one.
*/
export function useStoreAudioTranscriptions({
    conversationId,
    uiContext,
}: {
    conversationId: string | null;
    uiContext: BotUiContext;
}) {
    // Listen for the events that Livekit broadcasts when audio is detected and store an object
    // whose keys are ids and whose values are TranscriptionSegment objects
    const transcriptionSegments = useBuildTranscriptionSegmentsFromEvents(conversationId);

    // Combine consecutive segments for a single participant into a single message
    const transcriptionMessages = useMemo(
        () => mergeTranscriptionSegmentsIntoMessages(transcriptionSegments),
        [transcriptionSegments],
    );

    // Store messages to redux and log them via EventLogger
    useStoreTranscriptionMessages({
        transcriptionMessages,
        uiContext,
        conversationId,
    });
}
