import { useRef, useEffect } from 'react';
import { type NonOptional } from '@Types';
import { type AudioVisualizerProps } from '../TutorBotVoiceAgent.types';
import { createHexagons } from './createHexagons';
import { type Hexagon } from './Hexagon';
import { setupCanvasAndReturnContextAndSizeInfo } from './setupCanvasAndReturnContextAndSizeInfo';
import { useAnimateHexagons } from './useAnimateHexagons';

export const useAudioVisualizer = ({
    maskRadiusSizeFactor,
    gradientColors,
    hexagonGradientStart,
    hexagonGradientEnd,
    hexagonStrokeColor,
    hexagonSizeFactor,
}: NonOptional<AudioVisualizerProps>) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const hexagonsRef = useRef<Hexagon[]>([]);
    const hexagonsBackRef = useRef<Hexagon[]>([]);
    const animateHexagons = useAnimateHexagons();

    useEffect(() => {
        const sizeInfo = setupCanvasAndReturnContextAndSizeInfo(canvasRef.current, maskRadiusSizeFactor);
        if (!sizeInfo) return undefined;

        // Initialize hexagons
        if (hexagonsRef.current.length === 0) {
            const { backGroundHexagons, foregroundHexagons } = createHexagons({
                ...sizeInfo,
                hexagonSizeFactor,
                hexagonGradientStart,
                hexagonGradientEnd,
                hexagonStrokeColor,
            });
            hexagonsRef.current = foregroundHexagons;
            hexagonsBackRef.current = backGroundHexagons;
        }

        const cleanupAnimate = animateHexagons({
            ...sizeInfo,
            hexagonsRef,
            hexagonsBackRef,
            gradientColors,
        });

        return () => {
            cleanupAnimate();
        };
    }, [
        maskRadiusSizeFactor,
        hexagonGradientStart,
        hexagonGradientEnd,
        hexagonStrokeColor,
        hexagonSizeFactor,
        animateHexagons,
        gradientColors,
    ]);

    return canvasRef;
};
