import '@livekit/components-styles';
import { memo, useCallback, useEffect, useState } from 'react';
import { LoadingBoundary } from 'LoadingBoundary';
import { generateGuid } from 'guid';
import { useEventLogger } from 'FrontRoyalAngular';
import { type ConnectLiveKitRoomProps } from '../TutorBotVoiceAgent.types';
import { LiveKitRoomConversation } from './LiveKitRoomConversation';
import { StartScreen } from './StartScreen/StartScreen';
import { PostDiscussion } from './PostDiscussion/PostDiscussion';
import { useLogLaunchEvent } from '../hooks/useLogLaunchEvent';
import { getLogInfo } from '../utils/getLogInfo';

type PropsPassedThroughToConnectLiveKitRoom = Omit<ConnectLiveKitRoomProps, 'conversationId' | 'onDisconnected'>;
type Props = {
    IntroComponent: React.ReactNode;
    spacingBeneathLowestButton: number;
    toggleAudioOrText: () => void;
    autoStart?: boolean;
} & PropsPassedThroughToConnectLiveKitRoom;

const VoiceAgentRoomComponent = ({
    agentIdentifier,
    extraTokenAttributes,
    uiContext,
    spacingBeneathLowestButton,
    IntroComponent,
    toggleAudioOrText,
    autoStart = false,
}: Props) => {
    const [conversationId, setConversationId] = useState<string>(() => generateGuid());
    const [conversationComplete, setConversationComplete] = useState<boolean>();
    const [shouldConnectVoiceRoom, setShouldConnectVoiceRoom] = useState<boolean>(autoStart);
    const EventLogger = useEventLogger();
    useLogLaunchEvent({ eventType: 'tutorbot:opened_voice_agent_room', agentIdentifier, extraTokenAttributes });

    const onDisconnected = useCallback(() => {
        setConversationComplete(true);
    }, []);

    useEffect(() => {
        if (autoStart) setShouldConnectVoiceRoom(true);
    }, [autoStart]);

    const restartDiscussion = useCallback(() => {
        setConversationComplete(false);
        setShouldConnectVoiceRoom(true);
        setConversationId(generateGuid());
        EventLogger.log(
            'tutorbot:restart_voice_agent_conversation_clicked',
            getLogInfo({ agentIdentifier, extraTokenAttributes }),
        );
    }, [setShouldConnectVoiceRoom, EventLogger, agentIdentifier, extraTokenAttributes]);

    return (
        <LoadingBoundary>
            {conversationComplete ? (
                <PostDiscussion
                    conversationId={conversationId}
                    restartDiscussion={restartDiscussion}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                />
            ) : shouldConnectVoiceRoom ? (
                <LiveKitRoomConversation
                    uiContext={uiContext}
                    agentIdentifier={agentIdentifier}
                    extraTokenAttributes={extraTokenAttributes}
                    toggleAudioOrText={toggleAudioOrText}
                    conversationId={conversationId}
                    onDisconnected={onDisconnected}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                />
            ) : (
                <StartScreen
                    IntroComponent={IntroComponent}
                    onStartDiscussionClick={() => setShouldConnectVoiceRoom(true)}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                    toggleAudioOrText={toggleAudioOrText}
                />
            )}
        </LoadingBoundary>
    );
};

export const VoiceAgentRoom = memo(VoiceAgentRoomComponent) as typeof VoiceAgentRoomComponent;
