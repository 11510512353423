import { EventLoggerProvider } from 'EventLogger';
import transformKeyCase from 'Utils/transformKeyCase';
import { type AnyObject } from '@Types';
import { EVENT_MESSAGE_SENT } from '../constants';
import { type TutorBotMessage, type BotUiContext, type MessageSentEventPayload } from '../TutorBotConversation.types';

export function logTutorBotMessage({
    message,
    activeConversationId,
    uiContext,
    extraPayloadProperties,
}: {
    message: TutorBotMessage;
    activeConversationId: string;
    uiContext: BotUiContext;
    extraPayloadProperties?: AnyObject | null;
}) {
    const EventLogger = EventLoggerProvider.require();

    const basePayload: MessageSentEventPayload = {
        label: message.role,
        tutor_bot_conversation_id: activeConversationId,
        ui_context: uiContext,
        tutor_bot_message_id: message.id,
        sender_role: message.role,
        message: message.content,
        content_type: message.contentType,
        tutor_bot_message_payload: message.payload,
    };

    EventLogger.log(
        EVENT_MESSAGE_SENT,
        transformKeyCase({ ...basePayload, ...extraPayloadProperties }, { to: 'snakeCase' }),
    );
}
