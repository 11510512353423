import { type FC } from 'react';
import { useTargetBrandConfig } from 'FrontRoyalAngular';
import { type BrandConfig, Brand } from 'AppBranding';
import { twMerge } from 'tailwind-merge';
import { useAudioVisualizer } from '../utils';
import { type AudioVisualizerProps } from '../TutorBotVoiceAgent.types';

const AudioVisualizerTheme = {
    coral: {
        // If you find yourself searching for these colors,
        // we should ask if they should be named and added to our common colors.
        inner: '#FF3850', // Vibrant coral-red
        outer: '#FF7676', // Soft luminous coral-pink
    },
    blue: {
        inner: '#2B44FF', // Rich royal blue
        outer: '#60A5FA', // Light sky blue
    },
} as const;

const getAudioVisualizerTheme = (brandConfig: BrandConfig) => {
    const key = brandConfig.branding === Brand.valar ? 'blue' : 'coral';
    return AudioVisualizerTheme[key];
};

export const AudioVisualizer: FC<AudioVisualizerProps> = ({
    maskRadiusSizeFactor = 0.4,
    gradientColors,
    hexagonGradientStart = 'rgba(255,255,255,0.8)',
    hexagonGradientEnd = 'rgba(255,255,255,0.2)',
    hexagonStrokeColor = 'rgba(255,255,255,0.9)',
    hexagonSizeFactor = 0.5,
}) => {
    const brandConfig = useTargetBrandConfig();
    gradientColors = gradientColors || getAudioVisualizerTheme(brandConfig);

    const canvasRef = useAudioVisualizer({
        maskRadiusSizeFactor,
        gradientColors,
        hexagonGradientStart,
        hexagonGradientEnd,
        hexagonStrokeColor,
        hexagonSizeFactor,
    });

    return (
        <div className={twMerge('flex flex-grow flex-col items-center justify-center')}>
            <div className="relative mx-auto flex animate-fade-in flex-col items-center justify-center overflow-visible">
                <div style={{ width: '282px', height: '282px' }}>
                    <canvas ref={canvasRef} />
                </div>
            </div>
        </div>
    );
};
