import { type ReactNode, createContext, memo, useContext, useMemo } from 'react';
import { type GreetingMessageConfig } from '../types/GreetingMessageConfig.types';
import { type TutorBotChatStatusConfig } from '../types/TutorBotChatStatusConfig.types';

// The ChatContext is used to pass down a single tutorBotStatusConfig object to all components in the chat. It would probably
// work to have each component call useTutorBotStatusConfig directly, but theoretically due to asynchronous loading of dashboard
// content, that could mean we'd have moments when different components disagree on what the config should be. I THINK that
// the possibility of that issue would go away once we switch to loading dashboard content via RTKQuery, but I kind of like the
// way this makes it clear that there is one config value for the whole chat, so I'm not sure I'd change it anyway.

type ContextValue = {
    tutorBotStatusConfig: TutorBotChatStatusConfig;
    greetingMessageConfig?: GreetingMessageConfig;
    welcomeModalClassname?: string;
};

// So long as you use ChatContextProvider, the value here will never actually be undefined. It
// will always be ContextValue
export const ChatContext = createContext<ContextValue | undefined>(undefined);

export function useChatContext(): ContextValue {
    const context = useContext(ChatContext);
    if (!context) throw new Error('Expected tutorBotStatusConfig to be set in ChatContext');

    return context;
}

function ChatContextProviderComponent({
    tutorBotStatusConfig,
    greetingMessageConfig,
    welcomeModalClassname,
    children,
}: ContextValue & {
    children: ReactNode;
}) {
    const contextValue = useMemo(
        () => ({
            tutorBotStatusConfig,
            welcomeModalClassname,
            greetingMessageConfig,
        }),
        [tutorBotStatusConfig, greetingMessageConfig, welcomeModalClassname],
    );
    return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
}

export const ChatContextProvider = memo(ChatContextProviderComponent) as typeof ChatContextProviderComponent;
