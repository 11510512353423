import { type AnyObject } from '@Types';
import { angularInjectorProvider } from 'Injector';
import memoizeOne from 'memoize-one';
import transformKeyCase from 'Utils/transformKeyCase';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { type DataMessage } from '../TutorBotVoiceAgent.types';
import { isDataMessage } from './DataMessage';

// memoize parseMessage so that multiple RoomEventListeners in the same room don't all
// have to memoize (and potentially log errors about) the same message
export const parseMessage = memoizeOne((rawMessage: Uint8Array): DataMessage | null => {
    let messageText: string | undefined;
    let messageObject: AnyObject | undefined;
    try {
        messageText = new TextDecoder().decode(rawMessage); // Convert
        const snakeCasedMessageObject = JSON.parse(messageText) as AnyObject;
        messageObject = transformKeyCase<AnyObject>(snakeCasedMessageObject, { to: 'camelCase' });
        if (isDataMessage(messageObject)) return messageObject;
    } catch {
        const ErrorLogService = angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService');
        ErrorLogService.notifyInProd('Error parsing data message', null, {
            rawMessage,
            messageText,
            messageObject,
            isDataMessage: messageObject ? isDataMessage(messageObject) : null,
        });
    }

    return null;
});
