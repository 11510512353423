import { faMicrophone, faMicrophoneSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'Tooltip';
import { AudioControlBarButton } from './AudioControlBarButton';

type Props = {
    onPress: () => void;
    enabled: boolean;
    pending: boolean;
};

export const MuteButton: FC<Props> = ({ onPress, enabled, pending }) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.audioControlBar' });

    const muted = !enabled && !pending;
    const text = muted ? t('unmute') : t('mute');

    return (
        <Tooltip content={text}>
            <div>
                <AudioControlBarButton
                    onPress={onPress}
                    controlState={muted ? 'highlight' : 'default'}
                    disabled={pending}
                >
                    {muted ? (
                        <FontAwesomeIcon className="text-md text-coral" icon={faMicrophoneSlash} />
                    ) : (
                        <FontAwesomeIcon className="text-md" icon={faMicrophone} />
                    )}
                    <span className="sr-only">{text}</span>
                </AudioControlBarButton>
            </div>
        </Tooltip>
    );
};
