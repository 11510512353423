import { type ButtonHTMLAttributes } from 'react';
import { faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'Button';
import { TUTOR_BOT_BUTTON_HEIGHT } from 'TutorBotConversation';
import { getConversationIncludesCompleteAiResponse } from '../redux/chat';

type Props = {
    className?: string;
    onClick: () => void;
    isLocked: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ClearButton({ className, onClick, isLocked }: Props) {
    const { t } = useTranslation('back_royal');
    const conversationIncludesCompleteAiResponse = useSelector(getConversationIncludesCompleteAiResponse);
    return (
        <Button
            data-testid="new-topic-button"
            className={clsx(TUTOR_BOT_BUTTON_HEIGHT, 'py-3 pe-7 ps-5 font-semibold', className)}
            isDisabled={isLocked || !conversationIncludesCompleteAiResponse}
            color="primary-gradient"
            size="lg"
            radius="full"
            onPress={onClick}
        >
            <FontAwesomeIcon fontStyle="solid" icon={faWandMagicSparkles} />
            <span>{t('chat.chat.new_topic')}</span>
        </Button>
    );
}
