import { memo, useEffect, useMemo, useRef, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { type PretendAiMessage } from '../types/ChatMessage.types';
import { chatActions, getActiveConversationGreetingMessages, getActiveConversationId } from '../redux/chat';
import { createPretendAiMessage } from '../utils/ChatMessage';
import { type GreetingMessageConfig } from '../types/GreetingMessageConfig.types';

type Props = {
    name: string;
    subsequentGreetingLocales: NonNullable<GreetingMessageConfig['subsequentGreetingLocales']>;
    subsequentPromptLocales: NonNullable<GreetingMessageConfig['subsequentPromptLocales']>;
};

const Component: FC<Props> = ({ name, subsequentGreetingLocales, subsequentPromptLocales }) => {
    const { t } = useTranslation('back_royal');
    const hasAddedMessage = useRef(false);
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);
    if (!activeConversationId) throw new Error('No active conversation ID');
    const greetingMessages = useSelector(getActiveConversationGreetingMessages);

    const message = useMemo<PretendAiMessage>(() => {
        const greetingKeyIndex = Math.floor(Math.random() * (subsequentGreetingLocales.length - 1));
        const promptKeyIndex = Math.floor(Math.random() * (subsequentPromptLocales.length - 1));

        const { key: greetingKey, params: greetingParams } = subsequentGreetingLocales[greetingKeyIndex];
        const { key: promptKey, params: promptParams } = subsequentPromptLocales[promptKeyIndex];
        const greeting = t(greetingKey, { ...greetingParams, name });
        const prompt = t(promptKey, { ...promptParams });

        return createPretendAiMessage({
            content: `${greeting} ${prompt}`,
            conversationId: activeConversationId,
        });
    }, [activeConversationId, name, subsequentGreetingLocales, subsequentPromptLocales, t]);

    useEffect(() => {
        if (hasAddedMessage.current || greetingMessages.length) return;

        dispatch(chatActions.addMessage({ message }));

        hasAddedMessage.current = true;
    }, [dispatch, message, greetingMessages]);

    // This component is only used to add a greeting message to the chat
    return null;
};

export const SubsequentGreetingMessage = memo(Component);
