import { storeProvider } from 'ReduxHelpers';
import { isAlgorithmDataMessage } from './DataMessage';
import { voiceAgentSlice } from '../redux/voiceAgent';
import { parseMessage } from './parseMessage';

/*
getAlgorithmMetadataEventListener returns a listener that listens for the agent to send
down information about the algorithm (title and version) in an event, and stores that
information in redux
*/
export const getAlgorithmMetadataEventListener =
    ({ conversationId }: { conversationId: string }) =>
    (message: Uint8Array) => {
        const parsedMessage = parseMessage(message);
        if (parsedMessage && isAlgorithmDataMessage(parsedMessage)) {
            storeProvider.dispatch(
                voiceAgentSlice.actions.updateConversationMetadata({ conversationId, ...parsedMessage.payload }),
            );
        }
    };
