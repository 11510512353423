import { type ConversationContext } from 'TutorBotConversation';
import { type HumanTranscribedAudioMessage, type VoiceAgentTranscribedAudioMessage } from '../TutorBotVoiceAgent.types';

export function createTranscribedAudioMessage<T extends 'ai' | 'human'>({
    id,
    conversationId,
    content,
    role,
    conversationContext,
}: {
    id: string;
    conversationId: string;
    content: string;
    role: T;
    conversationContext: ConversationContext;
}): T extends 'ai' ? VoiceAgentTranscribedAudioMessage : HumanTranscribedAudioMessage {
    return {
        id,
        role,
        createdAt: new Date().getTime(),
        payload: null,
        contentType: 'transcribed_audio',
        conversationId,
        content,
        conversationContext,
    } as T extends 'ai' ? VoiceAgentTranscribedAudioMessage : HumanTranscribedAudioMessage;
}
