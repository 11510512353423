window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.TutorBotVoiceAgent = window.locales.am.TutorBotVoiceAgent || {};window.locales.am.TutorBotVoiceAgent.audioControlBar = window.locales.am.TutorBotVoiceAgent.audioControlBar || {};window.locales.am.TutorBotVoiceAgent.audioControlBar = {...window.locales.am.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "ውይይቱን ጨርስ",
    "mute": "ድምጸ-ከል አድርግ",
    "unmute": "አለመደሰት",
    "microphoneSelect": "ማይክሮፎን ይምረጡ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.TutorBotVoiceAgent = window.locales.ar.TutorBotVoiceAgent || {};window.locales.ar.TutorBotVoiceAgent.audioControlBar = window.locales.ar.TutorBotVoiceAgent.audioControlBar || {};window.locales.ar.TutorBotVoiceAgent.audioControlBar = {...window.locales.ar.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "انهاء المناقشة",
    "mute": "صامت",
    "unmute": "استياء",
    "microphoneSelect": "اختيار الميكروفون"
}
};window.locales.en = window.locales.en || {};window.locales.en.TutorBotVoiceAgent = window.locales.en.TutorBotVoiceAgent || {};window.locales.en.TutorBotVoiceAgent.audioControlBar = window.locales.en.TutorBotVoiceAgent.audioControlBar || {};window.locales.en.TutorBotVoiceAgent.audioControlBar = {...window.locales.en.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "End discussion",
    "mute": "Mute",
    "unmute": "Unmute",
    "microphoneSelect": "Microphone select"
}
};window.locales.es = window.locales.es || {};window.locales.es.TutorBotVoiceAgent = window.locales.es.TutorBotVoiceAgent || {};window.locales.es.TutorBotVoiceAgent.audioControlBar = window.locales.es.TutorBotVoiceAgent.audioControlBar || {};window.locales.es.TutorBotVoiceAgent.audioControlBar = {...window.locales.es.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "Fin de la discusión",
    "mute": "Silenciar",
    "unmute": "disgusto",
    "microphoneSelect": "Seleccionar micrófono"
}
};window.locales.it = window.locales.it || {};window.locales.it.TutorBotVoiceAgent = window.locales.it.TutorBotVoiceAgent || {};window.locales.it.TutorBotVoiceAgent.audioControlBar = window.locales.it.TutorBotVoiceAgent.audioControlBar || {};window.locales.it.TutorBotVoiceAgent.audioControlBar = {...window.locales.it.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "Termina la discussione",
    "mute": "Muto",
    "unmute": "dispiacere",
    "microphoneSelect": "Selezione microfono"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.TutorBotVoiceAgent = window.locales.zh.TutorBotVoiceAgent || {};window.locales.zh.TutorBotVoiceAgent.audioControlBar = window.locales.zh.TutorBotVoiceAgent.audioControlBar || {};window.locales.zh.TutorBotVoiceAgent.audioControlBar = {...window.locales.zh.TutorBotVoiceAgent.audioControlBar, ...{
    "endDiscussion": "结束讨论",
    "mute": "沉默的",
    "unmute": "不快",
    "microphoneSelect": "麦克风选择"
}
};