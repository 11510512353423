import { type BrandConfig } from 'AppBranding';
import { useErrorLogService } from 'ErrorLogging';
import { useTargetBrandConfig } from 'FrontRoyalAngular';
import { type TutorBotChatStatusConfig, type TutorBotChatUnlockedConfig, useTutorBotLockedConfig } from 'TutorBotChat';
import './locales/ai_tutor_chat/ai_tutor_chat-en.json';

export function getUnlockedConfig({
    brandNameShort,
    tutorBotFaqUrl,
    botName,
}: Pick<BrandConfig, 'brandNameShort' | 'tutorBotFaqUrl'> & { botName: string }) {
    const config: TutorBotChatUnlockedConfig = {
        isLocked: false,
        greetingMessages: [],
        welcomeModalTexts: [
            {
                key: 'ai_tutor_chat.ai_tutor_chat.welcome_message',
                params: { institutionName: brandNameShort, faqUrl: tutorBotFaqUrl, botName },
            },
            { key: 'chat.chat.ai_welcome_message_stay_tuned' },
        ],

        inputLabel: { key: 'ai_tutor_chat.ai_tutor_chat.message_ai_tutor', params: { botName } },
    };
    return config;
}

export function useAiTutorStatusConfig({ botName }: { botName: string }): TutorBotChatStatusConfig {
    const tutorBotLockedConfig = useTutorBotLockedConfig({ botName });
    const { brandNameShort, tutorBotFaqUrl } = useTargetBrandConfig();
    const ErrorLogService = useErrorLogService();

    if (!tutorBotFaqUrl) {
        ErrorLogService.notifyInProd('Missing TutorBot FAQ URL', undefined, {
            brand: brandNameShort,
            level: 'warning',
        });
    }

    // If tutor bot is locked globally, then we just return that config
    if (tutorBotLockedConfig) return tutorBotLockedConfig;

    return getUnlockedConfig({ brandNameShort, tutorBotFaqUrl, botName });
}
