import { type TutorBotSystemMessage } from '../types/ChatMessage.types';
import { createBaseMessageAttrs } from './ChatMessage';

type BaseMessage = Omit<ReturnType<typeof createBaseMessageAttrs>, 'hidden' | 'content'> &
    Pick<TutorBotSystemMessage, 'conversationId' | 'complete' | 'role' | 'isRetryOfMessageId' | 'hidden' | 'canceled'>;

export function createTutorBotSystemMessage({
    content,
    payload,
    conversationId,
    isRetryOfMessageId = null,
    isInitialMessage = false,
}: {
    content: TutorBotSystemMessage['content'];
    payload: TutorBotSystemMessage['payload'];
    conversationId: TutorBotSystemMessage['conversationId'];
    isRetryOfMessageId?: TutorBotSystemMessage['isRetryOfMessageId'];
    isInitialMessage?: TutorBotSystemMessage['isInitialMessage'];
}): TutorBotSystemMessage {
    const baseMessage: BaseMessage = {
        ...createBaseMessageAttrs(),
        conversationId,
        complete: true as const,
        role: 'tutor_bot_system' as const,
        isRetryOfMessageId,
        hidden: true as const,
        canceled: null,
    };

    // Explicitly construct the message object ensuring type safety
    const message: TutorBotSystemMessage = {
        ...baseMessage,
        content,
        contentType: 'text', // The content in tutor_bot_system_message is always text
        payload,
        isInitialMessage,
    };

    return message;
}
