import { type Dispatch, type SetStateAction, type FC, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTriggerCallbackOnInterval } from 'useTriggerCallbackOnInterval';
import { DELAY_BETWEEN_PRETEND_MESSAGES_MS } from 'TutorBotConversation';
import { getActiveConversationId, chatActions } from '../redux/chat';
import { createPretendAiMessage } from '../utils/ChatMessage';
import { type GreetingMessageConfig } from '../types/GreetingMessageConfig.types';

type Props = {
    name: string;
    initialGreetingLocales: NonNullable<GreetingMessageConfig['initialGreetingLocales']>;
    setHasSeenInitialGreeting: Dispatch<SetStateAction<boolean | undefined>> | null;
};

export const InitialGreetingMessage: FC<Props> = memo(({ name, setHasSeenInitialGreeting, initialGreetingLocales }) => {
    const { t, ready } = useTranslation('back_royal');
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);

    const dispatchGreetingMessages = useCallback(
        ({ key, params }: (typeof initialGreetingLocales)[number], isLastTrigger?: boolean) => {
            if (!ready) return;
            const message = createPretendAiMessage({
                content: t(key, { ...params, name }),
                conversationId: activeConversationId!,
            });

            dispatch(chatActions.addMessage({ message }));

            if (isLastTrigger) setHasSeenInitialGreeting?.(true);
        },
        [ready, t, name, activeConversationId, dispatch, setHasSeenInitialGreeting],
    );

    useTriggerCallbackOnInterval({
        callback: dispatchGreetingMessages,
        intervalMs: DELAY_BETWEEN_PRETEND_MESSAGES_MS,
        listToTrigger: initialGreetingLocales,
        delayOnStart: false,
    });

    return null;
});
