import { faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Button';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import './locales/TutorBotVoiceAgent/StartDiscussionButton-en.json';
import { TUTOR_BOT_BUTTON_HEIGHT } from 'TutorBotConversation';

type Props = {
    onPress: () => void;
    iconOnly?: boolean;
};

export function StartDiscussionButton({ onPress, iconOnly }: Props) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.StartDiscussionButton' });

    return (
        <Button
            isIconOnly={iconOnly}
            onPress={onPress}
            radius="full"
            color="primary-gradient"
            size="lg"
            className={TUTOR_BOT_BUTTON_HEIGHT}
        >
            <span className="flex items-center justify-start">
                <FontAwesomeIcon className={clsx('text-md', { 'pe-3.5': !iconOnly })} icon={faMicrophone} />
                <span className={clsx('me-2 text-md font-semibold', { 'sr-only': iconOnly })}>
                    {t('startDiscussion')}
                </span>
            </span>
        </Button>
    );
}
