import { useCallback, useEffect, useState } from 'react';
import {
    type TranscriptionSegment as TranscriptionSegmentWithoutParticipant,
    type Participant,
    RoomEvent,
} from 'livekit-client';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { angularInjectorProvider } from 'Injector';
import { type TranscriptionSegmentMap } from './useStoreAudioTranscriptions.types';
import { useRoomEventListener } from '../useRoomEventListener';

/*
useBuildTranscriptionSegmentsFromEvents is the first step in storing audio transcription messages.
This hook listens for the events from livekit and builds a map whose keys are ids and whose
values are TranscriptionMessage objects
*/

function updateTranscriptionSegments({
    existingSegments,
    newSegments,
    participant,
    conversationId,
}: {
    existingSegments: TranscriptionSegmentMap;
    newSegments: TranscriptionSegmentWithoutParticipant[];
    participant: Participant;
    conversationId: string;
}) {
    const result = { ...existingSegments };
    // The new segments may or may not already exist in the TranscriptionSegmentMap. When
    // they do already exist, we can just replace the existing entry, since the new one
    // will have the updated text
    newSegments
        // filter out segments that don't have any text
        .filter(segment => !!segment.text)
        .forEach(segmentWithoutParticipant => {
            result[segmentWithoutParticipant.id] = {
                participant,
                conversationId,
                ...segmentWithoutParticipant,
            };
        });
    return result;
}

function maybeUpdateTranscriptionSegments({
    newSegments,
    conversationId,
    ErrorLogService,
    participant,
    setTranscriptionSegments,
}: {
    newSegments: TranscriptionSegmentWithoutParticipant[];
    conversationId: string | null;
    ErrorLogService: typeof ErrorLogServiceClass;
    participant?: Participant;
    setTranscriptionSegments: React.Dispatch<React.SetStateAction<TranscriptionSegmentMap>>;
}) {
    if (!conversationId) {
        ErrorLogService.notifyInProd('Observed TranscriptionReceived event without a conversationId', null, {
            segments: newSegments,
        });
        return;
    }

    if (!participant) {
        ErrorLogService.notifyInProd('No participant found in transcription event', null, {
            segments: newSegments,
        });
        return;
    }

    setTranscriptionSegments(prev =>
        updateTranscriptionSegments({ existingSegments: prev, newSegments, participant, conversationId }),
    );
}

function useResetMapOnConversationStart({
    conversationId,
    setTranscriptionSegments,
}: {
    conversationId: string | null;
    setTranscriptionSegments: React.Dispatch<React.SetStateAction<TranscriptionSegmentMap>>;
}) {
    // Reset the transcription segments when a new conversation starts. This only works assuming
    // the conversationId is not unset immediately upon disconnecting from the room, as we need
    // access to the messages so that we can store them. We preserve the conversationId in that
    // case so we can show the transcript from the conversation that was just disconnected, so this
    // shouldn't be an issue
    useEffect(() => {
        setTranscriptionSegments({});
    }, [conversationId, setTranscriptionSegments]);
}

function useTranscriptionReceivedCallback({
    conversationId,
    ErrorLogService,
    setTranscriptionSegments,
}: {
    conversationId: string | null;
    ErrorLogService: typeof ErrorLogServiceClass;
    setTranscriptionSegments: React.Dispatch<React.SetStateAction<TranscriptionSegmentMap>>;
}) {
    return useCallback(
        (newSegments: TranscriptionSegmentWithoutParticipant[], participant?: Participant) => {
            maybeUpdateTranscriptionSegments({
                newSegments,
                conversationId,
                ErrorLogService,
                participant,
                setTranscriptionSegments,
            });
        },
        [conversationId, ErrorLogService, setTranscriptionSegments],
    );
}

export function useBuildTranscriptionSegmentsFromEvents(conversationId: string | null) {
    const [transcriptionSegments, setTranscriptionSegments] = useState<TranscriptionSegmentMap>({});
    const ErrorLogService = angularInjectorProvider.get<typeof ErrorLogServiceClass>('ErrorLogService');

    const transcriptionReceivedCallback = useTranscriptionReceivedCallback({
        conversationId,
        ErrorLogService,
        setTranscriptionSegments,
    });

    useResetMapOnConversationStart({
        conversationId,
        setTranscriptionSegments,
    });

    useRoomEventListener(RoomEvent.TranscriptionReceived, transcriptionReceivedCallback);

    return transcriptionSegments;
}
