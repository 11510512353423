import { type Action, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash/fp';
import { type ConversationMetadata } from '../../types/Context.types';
import { type RealAiMessage, type ChatMessage, type AiMessageMetadata } from '../../types/ChatMessage.types';
import { type StudentMessageEvaluation } from '../../types/OtherInternalTypes.types';
import { type SourceMetadata } from '../../types/AnswerSource.types';
import { type ChatSliceState } from './chatSlice.types';
import { isRealAiMessage } from '../../utils/ChatMessage';
import { chatSliceName } from './constants';

export const defaultChatState: ChatSliceState = {
    activeConversationId: null,
    conversationMetadataMap: {},
    hasChangedTopic: false,
    messages: {},
    studentEvaluations: {},
};

function mergeAiMessageAttrs({
    state,
    messageId,
    attrs,
    aiMessageMetadata,
}: {
    state: ChatSliceState;
    messageId: string;
    attrs?: Partial<RealAiMessage>;
    aiMessageMetadata?: Partial<AiMessageMetadata>;
}) {
    let message = state.messages[messageId];
    if (!message) {
        throw new Error('Cannot merge attrs into a message that does not exist');
    }
    if (!isRealAiMessage(message)) {
        throw new Error('mergeAiMessageAttrs can only be called on RealAiMessages');
    }
    if (attrs) {
        message = merge(message)(attrs);
    }

    if (aiMessageMetadata) {
        message.aiMessageMetadata = merge(message.aiMessageMetadata || {})(aiMessageMetadata);
    }

    state.messages[messageId] = message;
    return message;
}

/**
 * SLICE
 */
export const chatSlice = createSlice({
    name: chatSliceName,
    initialState: defaultChatState,
    reducers: {
        /* Define actions and how they modify the state of this store slice.
           You can use mutative operations, and RTK converts them to non-mutative operations behind the scenes */

        addMessage: (state: ChatSliceState, action: PayloadAction<{ message: ChatMessage; skipLogging?: boolean }>) => {
            state.messages[action.payload.message.id] = action.payload.message;
        },
        mergeAiMessageAttrs: (
            state: ChatSliceState,
            action: PayloadAction<{
                messageId: string;
                attrs?: Partial<RealAiMessage>;
                aiMessageMetadata?: Partial<AiMessageMetadata>;
            }>,
        ) => {
            const { messageId, attrs, aiMessageMetadata } = action.payload;
            mergeAiMessageAttrs({ state, messageId, attrs, aiMessageMetadata });
        },
        mergeAiMessageResult: (
            state: ChatSliceState,
            action: PayloadAction<{
                messageId: string;
                sources: SourceMetadata[];
                footnoteReferences: Record<string, string>;
                answer: string;
                completedAt: number;
            }>,
        ) => {
            const { messageId, sources, footnoteReferences, answer } = action.payload;
            const attrs: Partial<RealAiMessage> = {
                sources,
                footnoteReferences,
            };
            const message = state.messages[messageId];
            if (!message?.content?.length) {
                attrs.content = answer;
            }
            const aiMessageMetadata: Partial<AiMessageMetadata> = {
                secondsToCompletion: action.payload.completedAt - message.createdAt,
            };
            mergeAiMessageAttrs({ state, messageId, attrs, aiMessageMetadata });
        },
        appendContentToken: (
            state: ChatSliceState,
            action: PayloadAction<{ messageId: string; token: string; now: number }>,
        ) => {
            const { messageId, token, now } = action.payload;
            const message = state.messages[messageId];
            message.content += token;

            if (isRealAiMessage(message) && !message.aiMessageMetadata.secondsToFirstToken) {
                message.aiMessageMetadata.secondsToFirstToken = now - message.createdAt;
            }
        },
        setActiveConversation: (
            state: ChatSliceState,
            action: PayloadAction<{ conversationId: string; conversationMetadata: ConversationMetadata }>,
        ) => {
            const { conversationId, conversationMetadata } = action.payload;
            state.activeConversationId = conversationId;
            state.conversationMetadataMap[conversationId] = conversationMetadata;
        },
        setHasChangedTopic: (state: ChatSliceState, action: PayloadAction<{ hasChangedTopic: boolean }>) => {
            state.hasChangedTopic = action.payload.hasChangedTopic;
        },
        hideMessage: (state: ChatSliceState, action: PayloadAction<{ messageId: string }>) => {
            const { messageId } = action.payload;
            state.messages[messageId] = merge(state.messages[messageId])({ hidden: true });
        },
        hideIncompleteMessages: (state: ChatSliceState) => {
            Object.keys(state.messages).forEach(messageId => {
                if (!state.messages[messageId].complete) {
                    state.messages[messageId] = merge(state.messages[messageId])({ hidden: true });
                }
            });
        },
        setStudentEvaluation: (
            state: ChatSliceState,
            action: PayloadAction<{ messageId: string; evaluation: StudentMessageEvaluation }>,
        ) => {
            state.studentEvaluations[action.payload.messageId] = action.payload.evaluation;
        },
        reset: (_state: ChatSliceState, _action: Action) => defaultChatState,
    },
});

/**
 * EXPORT REDUCER AND ACTIONS
 */
export const chatReducer = chatSlice.reducer;
export const chatActions = chatSlice.actions;
