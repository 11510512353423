import { useCurrentUser } from 'FrontRoyalAngular';
import { useAppHeaderViewModel } from 'NavigationHelpers';
import { Chat } from 'TutorBotChat';
import { memo, useMemo } from 'react';
import { useSuspenseFeatureGate } from 'FrontRoyalStatsig';
import { AI_ADVISOR_BRAND_NAME } from '../constants';
import { useBotPageGreetingConfig } from '../useBotPageGreetingConfig';
import { useBotPageStatusConfig } from '../useBotPageStatusConfig';
import { type BotPageClientContext } from '../TutorBotAiAdvisorChat.types';

const clientContext: BotPageClientContext = {
    uiContext: 'bot_page',
};

export function BotPageComponent() {
    const currentUser = useCurrentUser()!;
    const AppHeaderViewModel = useAppHeaderViewModel();
    const botName = AI_ADVISOR_BRAND_NAME;

    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(botName.toLocaleUpperCase());
    const botPageAccessForProspectiveStudents = useSuspenseFeatureGate('ai_advisor_access_for_prospective_students');
    const tutorBotStatusConfig = useBotPageStatusConfig(botPageAccessForProspectiveStudents);
    const greetingMessageConfig = useBotPageGreetingConfig();

    // When the mobile menu is showing, we need to add extra bottom spacing
    // in the mobile breakpoint to pull the input box above the menu.
    const spacingBeneathLowestButton = useMemo(
        () => (!AppHeaderViewModel.hideMobileMenu ? 84 : undefined),
        [AppHeaderViewModel.hideMobileMenu],
    );

    return (
        <div className="relative h-full w-full overflow-hidden bg-gradient-to-bl from-white to-[#F7F7F7] pt-[1px]">
            <div className="relative mx-auto h-full w-full max-w-full px-3.5 sm:w-[750px] md:w-[970px] lg:w-[1170px]">
                <Chat
                    botName={botName}
                    tutorBotStatusConfig={tutorBotStatusConfig}
                    greetingMessageConfig={greetingMessageConfig}
                    currentUser={currentUser}
                    clientContext={clientContext}
                    endConversationOnClose
                    enableSetNewTopic
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                />
            </div>
        </div>
    );
}

export const BotPage = memo(BotPageComponent);
