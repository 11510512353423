import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';
import { ProtectedRoute } from 'ProtectedRoute';
import { type CurrentUser, getCohort } from 'Users';
import { getProgramConfigValue } from 'Program';

export const ROUTES = {
    BOT: route('bot', {}),
};

export function hasBotPageAccess(currentUser: CurrentUser) {
    const cohort = getCohort(currentUser);
    if (!cohort) return false;

    return !!getProgramConfigValue(cohort.programType, 'supportsBotPage');
}

async function getLazyTutorbotComponent() {
    const { BotPage } = await import(/* webpackPrefetch: true */ './BotPage/BotPage');
    return {
        Component: () => (
            <ProtectedRoute canAccessRoute={hasBotPageAccess}>
                <BotPage />
            </ProtectedRoute>
        ),
    };
}

export function getTutorBotAiAdvisorRoutes() {
    return <Route path={ROUTES.BOT.relativePath} lazy={getLazyTutorbotComponent} />;
}
