import { type ContentProps, Popover } from 'Popover';
import { Text } from 'Text';
import { useTranslation } from 'react-i18next';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { memo, useEffect, useState } from 'react';
import { LaunchBotButton } from './LaunchBotButtons';
import { type AiAdvisorTooltipProps } from './AiAdvisorTooltip.types';
import './locales/aiAdvisorTooltip/aiAdvisorTooltip-en.json';

// This component should have been called AiTutorTooltip. Not renaming it now just because the column in the db
// is has_seen_ai_advisor_tooltip, and that's annoying to change.

const StarEmoticon = '\u{1F31F}';
const MicrophoneEmoticon = '\u{1F399}\uFE0F'; // 🎙️

const TooltipContent = ({ titleProps, buttonKey }: ContentProps & { buttonKey: string }) => {
    const { t } = useTranslation('back_royal');
    const isRecapLastLesson = buttonKey === 'recap_last_lesson';

    const emoticon = isRecapLastLesson ? MicrophoneEmoticon : StarEmoticon;
    const title = isRecapLastLesson
        ? t('startScreenButtonWithTooltip.startScreenButtonWithTooltip.tooltipTitle')
        : t('aiAdvisorTooltip.aiAdvisorTooltip.curious');
    const description = isRecapLastLesson
        ? t('startScreenButtonWithTooltip.startScreenButtonWithTooltip.tooltipDescription')
        : t('aiAdvisorTooltip.aiAdvisorTooltip.hereToHelp');

    return (
        <div className="relative p-5 sm:max-w-72">
            <div {...titleProps} className="mb-2.5 inline-flex">
                <Text as="h2" className="my-auto me-1.5">
                    {emoticon}
                </Text>
                <Text as="h2">{title}</Text>
            </div>
            <div>
                <Text>{description}</Text>
            </div>
        </div>
    );
};

export const AiAdvisorTooltip = memo(
    ({ onClose, backwardsClass, continueButtonVisible, buttonKey, launchBot, allowOpen }: AiAdvisorTooltipProps) => {
        const { isMobile } = useIsMobileMixin();
        const EventLogger = useEventLogger();
        const isRecapLastLesson = buttonKey === 'recap_last_lesson';

        // Because of the way the continue button animates on mobile we create
        // this state variable to tell heroui that the popover should move;
        const [triggerAnimationKey, setTriggerAnimationKey] = useState(0);

        const tooltipContent = (props: ContentProps) => <TooltipContent {...props} buttonKey={buttonKey} />;

        useEffect(() => {
            let timeout: NodeJS.Timeout;

            if (isMobile && continueButtonVisible && allowOpen) {
                let duration = 1000; /* This is how long the continue button takes to animate */
                const intervalMs = 10;
                timeout = setInterval(() => {
                    if (duration <= 1000) {
                        setTriggerAnimationKey(v => v + 1);
                        duration += intervalMs;
                    } else {
                        clearInterval(timeout);
                    }
                }, intervalMs);
            }

            return () => {
                clearInterval(timeout);
            };
        });

        return (
            <Popover
                Content={tooltipContent}
                onClose={onClose}
                classNames={{
                    base: [
                        'max-w-[calc(100%_-_25px)] sm:max-w-full',
                        'data-[placement=top-start]:before:left-[105px]',
                        'rtl:data-[placement=top-start]:before:right-[105px]',
                        'rtl:right-[25px]',
                    ],
                    content: 'p-0',
                }}
                borderColor="slate-grey-200"
                closeOnOverlayClick={false}
                openDelayMs={isMobile && continueButtonVisible ? 1000 : 100}
                isOpenedInitially
                allowOpen={allowOpen}
                placement={isMobile ? 'top' : 'top-start'}
                updatePositionDeps={[triggerAnimationKey]}
                onCloseButtonClick={() => {
                    const eventName = isRecapLastLesson
                        ? 'tutorbot:dismissed_audio_recap_lesson_tooltip'
                        : 'tutorbot:dismissed_tooltip';
                    EventLogger.log(eventName, {});
                }}
                closeButton
            >
                <LaunchBotButton
                    backwardsClass={backwardsClass}
                    continueButtonVisible={continueButtonVisible}
                    buttonKey={buttonKey}
                    launchBot={launchBot}
                />
            </Popover>
        );
    },
);
