import { type I18NLocale } from 'Locale';

export enum LockedTutorBotChatState {
    Unavailable = 'Unavailable',
    InProgressExam = 'InProgressExam',
}

export enum UnlockedTutorBotChatState {
    TutorBotUnlocked = 'TutorBotUnlocked',
}

export type TutorBotChatState = LockedTutorBotChatState | UnlockedTutorBotChatState;

type TutorBotChatBaseConfig<T extends { isLocked: boolean }> = {
    isLocked: T['isLocked'];
    welcomeModalTexts: readonly I18NLocale[];
    welcomeModalCta?: {
        message: { headline: I18NLocale; subtext?: I18NLocale };
        buttonText: I18NLocale;
        onClick: () => void;
    };
    greetingMessages: readonly I18NLocale[];
    inputLabel: I18NLocale | null;
};

export type TutorBotChatLockedConfig = TutorBotChatBaseConfig<{ isLocked: true }>;
export type TutorBotChatUnlockedConfig = TutorBotChatBaseConfig<{ isLocked: false }>;

// There is an invalid state, but there is no invalid status config, because if we
// ever somehow run into an invalid state (which is unexpected), we will just show
// the Unavailable status config (see useTutorBotStatusConfig)
export type TutorBotChatStatusConfig = TutorBotChatLockedConfig | TutorBotChatUnlockedConfig;
