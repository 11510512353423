import { type AnyObject } from '@Types';
import { type AlgorithmMetadataMessage, type ErrorMessage, type DataMessage } from '../TutorBotVoiceAgent.types';

export function isDataMessage(message: AnyObject): message is DataMessage {
    return typeof message.type === 'string';
}

export function isErrorMessage(message: AnyObject): message is ErrorMessage {
    return message.type === 'error';
}

export function isAlgorithmDataMessage(message: AnyObject): message is AlgorithmMetadataMessage {
    return message.type === 'algorithmMetadata';
}
