import { type FC, memo, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockLink } from 'BlockLink';
import { BottomAlignedWithButton } from '../BottomAlignedWithButton';
import { StartDiscussionButton } from '../StartDiscussionButton/StartDiscussionButton';
import '../../locales/TutorBotVoiceAgent/TutorBotVoiceAgent-en.json';

type Props = {
    onStartDiscussionClick: () => void;
    IntroComponent: ReactNode;
    spacingBeneathLowestButton: number;
    toggleAudioOrText: () => void;
};

const StartScreenComponent: FC<Props> = ({
    onStartDiscussionClick,
    IntroComponent,
    spacingBeneathLowestButton,
    toggleAudioOrText,
}) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.TutorBotVoiceAgent' });

    return (
        <BottomAlignedWithButton
            Main={IntroComponent}
            Button={<StartDiscussionButton onPress={onStartDiscussionClick} />}
            Bottom={
                <BlockLink onPress={toggleAudioOrText} color="slate-grey">
                    {t('switchToTextMode')}
                </BlockLink>
            }
            spacingBeneathLowestButton={spacingBeneathLowestButton}
        />
    );
};

export const StartScreen = memo(StartScreenComponent) as typeof StartScreenComponent;
