import { useMemo } from 'react';
import clsx from 'clsx';
import { isHelpscoutSourceLocation } from '../../utils/sourceLocationHelpers';
import {
    type DisplayableNonLessonFrameSourceLocation,
    type HelpscoutArticleSourceLocation,
} from '../../types/AnswerSource.types';
import SourceLink from './SourceLink';
import HelpScoutCollectionLink from './HelpScoutCollectionLink';

type Props = {
    nonLessonLink: DisplayableNonLessonFrameSourceLocation;
    isFirstInHelpScoutCollection: boolean;
    isFirstRow: boolean;
};

export default function NonLessonLinkRow({ nonLessonLink, isFirstInHelpScoutCollection, isFirstRow }: Props) {
    const isHelpScoutLink = useMemo(() => isHelpscoutSourceLocation(nonLessonLink), [nonLessonLink]);

    if (isHelpScoutLink) {
        return (
            <>
                {isFirstInHelpScoutCollection && !isFirstRow && (
                    <>
                        <div className={clsx('col-[left-col]', '[&:(:first-of-type)]:hidden:-mt-2')} />
                        <div className={clsx('col-[right-col]', '[&:(:first-of-type)]:hidden:-mt-2')} />
                    </>
                )}
                {/* For the first HelpScout article in each collection we include a link to the collection in the first cell */}
                {isFirstInHelpScoutCollection && (
                    <div className={clsx('col-[left-col]')}>
                        <HelpScoutCollectionLink link={nonLessonLink as HelpscoutArticleSourceLocation} />
                    </div>
                )}
                <SourceLink url={nonLessonLink.url!} title={nonLessonLink.title!} />
            </>
        );
    }

    return <SourceLink url={nonLessonLink.url!} title={nonLessonLink.title!} fullWidth />;
}
