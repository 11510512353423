import { memo, useEffect, useMemo, useRef, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { type PretendAiMessage } from '../types/ChatMessage.types';
import { getActiveConversationGreetingMessages, getActiveConversationId, chatActions } from '../redux/chat';
import { createPretendAiMessage } from '../utils/ChatMessage';
import '../locales/chat/chat-en.json';

const Component: FC = () => {
    const { t } = useTranslation('back_royal');
    const dispatch = useDispatch();
    const activeConversationId = useSelector(getActiveConversationId);
    const hasAddedMessage = useRef(false);
    const greetingMessages = useSelector(getActiveConversationGreetingMessages);

    const message: PretendAiMessage = useMemo(
        () =>
            createPretendAiMessage({
                content: t('chat.chat.talk_about_next'),
                conversationId: activeConversationId!,
            }),
        [activeConversationId, t],
    );

    useEffect(() => {
        if (hasAddedMessage.current || !!greetingMessages.length) return;

        dispatch(chatActions.addMessage({ message }));

        hasAddedMessage.current = true;
    }, [dispatch, message, greetingMessages]);

    // This component is only used to add a greeting message to the chat
    return null;
};

export const NewTopicMessage = memo(Component);
