import { type AnyObject } from '@Types';
import transformKeyCase from 'Utils/transformKeyCase';

export function getLogInfo({
    agentIdentifier,
    extraTokenAttributes = {},
}: {
    agentIdentifier: string;
    extraTokenAttributes?: AnyObject;
}) {
    return transformKeyCase<AnyObject>(
        { ...extraTokenAttributes, agentIdentifier, label: agentIdentifier },
        { to: 'snakeCase' },
    );
}
