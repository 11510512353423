import { createHumanMessage, isHumanMessageInputs, isTutorBotMessageInputs } from './ChatMessage';
import { createTutorBotSystemMessage } from './createTutorBotSystemMessage';
import { type OutgoingMessageInputs, type OutgoingMessage } from '../types/ChatMessage.types';

export function createOutgoingMessage({
    // see comment above OutgoingMessageInputs definition about the oddity of the `inputs` parameter
    inputs,
    conversationId,
    isRetryOfMessageId,
    contentType,
}: {
    inputs: OutgoingMessageInputs;
    conversationId: string;
    isRetryOfMessageId?: string | null;
    contentType: OutgoingMessage['contentType'];
}): OutgoingMessage {
    if (isHumanMessageInputs(inputs)) {
        const { content } = inputs;
        return createHumanMessage({ content, conversationId, isRetryOfMessageId, contentType });
    }

    if (isTutorBotMessageInputs(inputs)) {
        const { content, payload } = inputs;
        return createTutorBotSystemMessage({
            content,
            payload,
            conversationId,
            isRetryOfMessageId,
        });
    }
    throw new Error('Unsupported role for outgoing message');
}
