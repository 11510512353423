export const setupCanvasAndReturnContextAndSizeInfo = (
    canvas: HTMLCanvasElement | null,
    maskRadiusSizeFactor: number,
) => {
    if (!canvas) return null;

    const ctx = canvas.getContext('2d');
    if (!ctx) return null;

    const container = canvas.parentElement;
    if (!container) return null;

    const { width, height } = container.getBoundingClientRect();

    // Calculate mask radius to fit within container
    const totalRadius = Math.min(width, height) / 2; // 175px for 350px container
    const maskRadius = totalRadius / (1 + maskRadiusSizeFactor); // For 0.4 factor: 175/1.4 = 125px
    const maxMaskSizeIncrease = maskRadius * maskRadiusSizeFactor; // 125 * 0.4 = 50px

    // Calculate movement area using the factor as a multiplier
    const movementAreaWidth = Math.floor((maskRadius + maxMaskSizeIncrease) * 2.3);
    const movementAreaHeight = Math.floor((maskRadius + maxMaskSizeIncrease) * 2.3);

    // Center the canvas relative to container
    const offsetX = (width - movementAreaWidth) / 2;
    const offsetY = (height - movementAreaHeight) / 2;
    canvas.style.maxWidth = 'unset';
    canvas.style.position = 'absolute';
    canvas.style.left = `${offsetX}px`;
    canvas.style.top = `${offsetY}px`;

    // Set up canvas with correct size and DPR
    const dpr = window.devicePixelRatio || 1;
    canvas.width = movementAreaWidth * dpr;
    canvas.height = movementAreaHeight * dpr;
    canvas.style.width = `${movementAreaWidth}px`;
    canvas.style.height = `${movementAreaHeight}px`;
    ctx.setTransform(dpr, 0, 0, dpr, 0, 0);

    return { maskRadius, movementAreaWidth, movementAreaHeight, maxMaskSizeIncrease, ctx };
};
