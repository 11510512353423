import { useMaybeRoomContext } from '@livekit/components-react';
import { useCallback, useEffect } from 'react';
import { type RoomEvent } from 'livekit-client';
import { type RoomEventCallbacks } from 'livekit-client/dist/src/room/Room';

export const useRoomEventListener = <Type extends RoomEvent>(type: Type, ...listeners: RoomEventCallbacks[Type][]) => {
    const room = useMaybeRoomContext();

    const handler = useCallback(
        (...params: Parameters<RoomEventCallbacks[Type]>) => {
            // @ts-expect-error TS is not able to infer the correct type for the listener, but the type of the
            // parent hook infers the correct type.
            listeners.forEach(listener => listener(...params));
        },
        [listeners],
    ) as RoomEventCallbacks[Type];

    useEffect(() => {
        if (!room) return undefined;
        room.on(type, handler);

        return () => {
            room.off(type, handler);
        };
    }, [room, handler, type]);
};
