export class Hexagon {
    // Position coordinates
    private x: number;
    private y: number;
    // Size of the hexagon
    private size: number;
    // Base movement speed before audio reactivity
    private baseSpeed: number;
    // Direction of movement in radians
    private angle: number;
    // Rotation angle of the hexagon
    private rotation: number;
    // Base rotation speed before audio reactivity
    private baseSpinSpeed: number;
    // Gradient colors for hexagon fill
    private gradientStart: string;
    private gradientEnd: string;
    private strokeColor: string;

    constructor(
        x: number,
        y: number,
        size: number,
        baseSpeed: number,
        gradientStart = 'rgba(255,255,255,0.8)',
        gradientEnd = 'rgba(255,255,255,0.2)',
        strokeColor = 'rgba(255,255,255,0.9)',
    ) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.baseSpeed = baseSpeed;
        // Random initial movement direction (0 to 2π radians)
        this.angle = Math.random() * Math.PI * 2;
        // Random initial rotation (0 to 2π radians)
        this.rotation = Math.random() * Math.PI * 2;
        // Random spin speed between 0.01 and 0.02
        this.baseSpinSpeed = 0.01 + Math.random() * 0.01;
        this.gradientStart = gradientStart;
        this.gradientEnd = gradientEnd;
        this.strokeColor = strokeColor;
    }

    update(
        globalAmp: number,
        freqValue: number,
        centerX: number,
        centerY: number,
        areaMinX: number,
        areaMaxX: number,
        areaMinY: number,
        areaMaxY: number,
    ): void {
        // Calculate movement speed based on audio input
        // Increases with both global amplitude and frequency values
        const speed = (this.baseSpeed + globalAmp / 40 + freqValue / 200) * 0.5;
        // Move hexagon based on its angle and speed
        // cos(angle) gives x component, sin(angle) gives y component
        this.x += Math.cos(this.angle) * speed;
        this.y += Math.sin(this.angle) * speed;

        // Wrap around screen edges
        // If hexagon goes off screen, move it to the opposite side
        if (this.x < areaMinX - this.size) {
            this.x = areaMaxX + this.size;
        } else if (this.x > areaMaxX + this.size) {
            this.x = areaMinX - this.size;
        }
        if (this.y < areaMinY - this.size) {
            this.y = areaMaxY + this.size;
        } else if (this.y > areaMaxY + this.size) {
            this.y = areaMinY - this.size;
        }

        // Swirl effect calculation
        const baseSwirl = 0.001; // Base swirl speed
        const reactiveSwirl = (globalAmp / 255) * 0.02; // Audio-reactive swirl component
        const swirlSpeed = baseSwirl + reactiveSwirl;

        // Calculate distance and angle from center point
        const dx = this.x - centerX;
        const dy = this.y - centerY;
        const dist = Math.sqrt(dx * dx + dy * dy); // Distance using Pythagorean theorem
        let currentAngle = Math.atan2(dy, dx); // Current angle relative to center

        // Add to the angle to create swirl
        currentAngle += swirlSpeed;

        // Convert back to cartesian coordinates
        this.x = centerX + dist * Math.cos(currentAngle);
        this.y = centerY + dist * Math.sin(currentAngle);

        // Update rotation based on base spin and frequency value
        this.rotation += this.baseSpinSpeed + (freqValue / 255) * 0.04;
    }

    draw(ctx: CanvasRenderingContext2D, pulseFactor: number): void {
        ctx.save(); // Save current canvas state

        // Move to hexagon's position and apply rotation/scale
        ctx.translate(this.x, this.y);
        ctx.rotate(this.rotation);
        ctx.scale(pulseFactor, pulseFactor);

        // Draw hexagon shape
        ctx.beginPath();
        for (let i = 0; i < 6; i++) {
            // Calculate vertex positions using regular hexagon geometry
            // Each vertex is 60° (π/3 radians) apart
            const theta = (Math.PI / 3) * i;
            const px = this.size * Math.cos(theta);
            const py = this.size * Math.sin(theta);
            if (i === 0) {
                ctx.moveTo(px, py);
            } else {
                ctx.lineTo(px, py);
            }
        }
        ctx.closePath();

        // Apply gradient fill
        const grad = ctx.createLinearGradient(-this.size, -this.size, this.size, this.size);
        grad.addColorStop(0, this.gradientStart);
        grad.addColorStop(1, this.gradientEnd);
        ctx.fillStyle = grad;
        ctx.fill();

        // Add stroke
        ctx.lineWidth = 1;
        ctx.strokeStyle = this.strokeColor;
        ctx.stroke();

        ctx.restore(); // Restore canvas state
    }
}
