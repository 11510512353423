import { memo, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { type ContentType } from 'TutorBotConversation';

type Props = {
    children: ReactNode;
    isHumanMessage: boolean;
    isLoading?: boolean;
    contentType: ContentType;
    className?: string;
};

const coloredBackground = [
    'bg-gradient-to-r',
    'text-white',
    'from-coral to-orange-500-alt',
    'valar:from-blue-500-alt valar:to-blue-400-alt',
    'turquoise-frame:from-turquoise-600-alt turquoise-frame:to-turquoise-500-alt',
    'purple-frame:from-purple-500-alt purple-frame:to-purple-450-alt',
    'blue-frame:from-blue-500-alt blue-frame:to-blue-400-alt',
];
const whiteBackground = 'border border-tutorbot-border bg-white text-tutorbot-chat shadow-smallish';
const greyBackground = 'bg-slate-grey-200 text-slate-grey';

const alignedRight = 'ml-auto rtl:ml-0 rtl:mr-auto';

const getMessageContentClasses = (
    isHumanMessage: boolean,
    contentType: ContentType,
    isLoading: boolean,
    className?: string,
) =>
    twMerge(
        'max-w-[75%] mb-2 sm:mb-5 select-text px-3.5 py-2.5 flex flex-col leading-5 sm:leading-6 w-fit',
        isHumanMessage && alignedRight,
        isLoading && 'py-[13px]',

        // background
        isHumanMessage && contentType === 'text' && coloredBackground,
        isHumanMessage && contentType === 'transcribed_audio' && greyBackground,
        !isHumanMessage && whiteBackground,

        // border radius
        contentType === 'text' && 'rounded-6',
        contentType === 'transcribed_audio' && 'rounded-11',

        // italics
        contentType === 'transcribed_audio' && isHumanMessage && 'italic',
        className,
    );

function TutorBotMessageContentComponent({
    children,
    isHumanMessage,
    contentType,
    isLoading = false,
    className,
}: Props) {
    return (
        <div
            data-testid="message-content"
            className={getMessageContentClasses(isHumanMessage, contentType, isLoading, className)}
        >
            {children}
        </div>
    );
}

export const TutorBotMessageContent = memo(TutorBotMessageContentComponent) as typeof TutorBotMessageContentComponent;
