import clsx from 'clsx';
import React, { forwardRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { type LaunchBotButtonProps } from './AiAdvisorTooltip.types';

const REVIEW_THIS_LESSON_BUTTON_KEY = 'review_this_lesson';
const EXPLAIN_BUTTON_KEY = 'explain';
const REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY = 'recap_last_lesson';

const botLaunchButtonKeys = [
    REVIEW_THIS_LESSON_BUTTON_KEY,
    EXPLAIN_BUTTON_KEY,
    REVIEW_PREVIOUS_MATERIAL_BUTTON_KEY,
] as const;

export const LaunchBotButton = forwardRef<HTMLButtonElement, LaunchBotButtonProps>(
    ({ backwardsClass, continueButtonVisible, buttonKey, launchBot, className }, ref) => {
        const { t } = useTranslation('back_royal', { keyPrefix: 'lessons.lesson' });

        return (
            <>
                {botLaunchButtonKeys.map(key => (
                    <Fragment key={key}>
                        {key === buttonKey ? (
                            <button
                                type="button"
                                ref={ref}
                                className={twMerge(
                                    clsx(
                                        'launch-bot-button flat white-and-beige-outline animated flex text-md font-semibold',
                                        'items-center justify-center px-4 py-2.5',
                                        'min-h-[50px] border border-solid border-beige-dark',
                                        // Quantic theme
                                        'bg-white text-coral hover:bg-coral hover:text-white',
                                        // Valar frame theme
                                        'valar-frame:bg-white valar-frame:text-blue valar-frame:hover:bg-blue valar-frame:hover:text-white',
                                        // Turquoise frame theme
                                        'turquoise-frame:bg-transparent turquoise-frame:text-white turquoise-frame:hover:bg-white turquoise-frame:hover:text-turquoise-dark',
                                        // Purple frame theme
                                        'purple-frame:bg-transparent purple-frame:text-white purple-frame:hover:bg-white purple-frame:hover:text-purple-dark',
                                        // Blue frame theme
                                        'blue-frame:bg-transparent blue-frame:text-white blue-frame:hover:bg-white blue-frame:hover:text-blue-dark',
                                        backwardsClass,
                                        { 'showing-continue-button': continueButtonVisible },
                                        className,
                                    ),
                                )}
                                onClick={() => launchBot()}
                                onTouchStart={() => launchBot()}
                            >
                                <i className="fa-solid fa-sparkles me-[0.2em]" />
                                <div className=" ms-2 flex flex-col items-start justify-start leading-[.8] ">
                                    <span className="mb-[4px] justify-start text-xxs font-normal uppercase">
                                        {t('show_frames_player.aiTutor', { ai: 'ai' })}
                                    </span>

                                    <span>{t(`show_frames_player.${buttonKey}`)}</span>
                                </div>
                            </button>
                        ) : null}
                    </Fragment>
                ))}
            </>
        );
    },
);
