import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'Tooltip';
import { Dropdown } from 'Dropdown';
import { useClickAway } from 'react-use';
import { AudioControlBarButton } from './AudioControlBarButton';

type Props = {
    activeDeviceId: string;
    onSelect: (deviceId: string) => void;
    devices: MediaDeviceInfo[];
};

export function MicrophoneSelectButton({ activeDeviceId, onSelect, devices }: Props) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.audioControlBar' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useClickAway(tooltipRef, () => {
        if (dropdownOpen) {
            setTimeout(() => {
                setDropdownOpen(false);
            }, 150);
        }
    });

    const handleMicrophoneSelect = useCallback(
        (selectedKeys: string[]) => {
            onSelect(selectedKeys[0]);
            setTimeout(() => {
                setDropdownOpen(false);
            }, 150);
        },
        [onSelect],
    );

    const dropdownItems = useMemo(
        () =>
            devices.map(device => ({
                key: device.deviceId,
                label: device.label,
                isReadOnly: activeDeviceId === device.deviceId,
            })),
        [devices, activeDeviceId],
    );

    return (
        <Tooltip
            content={t('microphoneSelect')}
            classNames={{ base: dropdownOpen ? 'opacity-0' : undefined }}
            placement="top"
        >
            <div ref={tooltipRef}>
                <Dropdown
                    items={dropdownItems}
                    selectedKeys={[activeDeviceId]}
                    selectionMode="single"
                    onSelectionChange={handleMicrophoneSelect}
                    placement="top"
                    isOpen={dropdownOpen}
                >
                    <div>
                        <AudioControlBarButton onPress={() => setDropdownOpen(prev => !prev)}>
                            <FontAwesomeIcon className="text-md" icon={faGear} />
                            <span className="sr-only">{t('microphoneSelect')}</span>
                        </AudioControlBarButton>
                    </div>
                </Dropdown>
            </div>
        </Tooltip>
    );
}
