import { memo, useMemo } from 'react';
import { type MediaDeviceFailure } from 'livekit-client';
import { LiveKitRoom } from '@livekit/components-react';
import { useConnectionDetails } from '../hooks/useConnectionDetails';
import { type TokenAttributes, type ConnectLiveKitRoomProps } from '../TutorBotVoiceAgent.types';
import { ConnectedLiveKitRoomUi } from './ConnectedLiveKitRoomUi';
import { useStoreConversationContext } from '../hooks/useStoreConversationContext';
import { useLogLaunchEvent } from '../hooks/useLogLaunchEvent';

// These values are duplicated in turor_bot_voice_agent.py
const DEFAULT_THRESHOLD = 0.7; // livekit default is 0.5
const DEFAULT_PREFIX_PADDING_MS = 300; // livekit default is 300
const DEFAULT_SILENCE_DURATION_MS = 750; // livekit default is 500
const defaultTurnDetection = {
    threshold: DEFAULT_THRESHOLD,
    prefixPaddingMs: DEFAULT_PREFIX_PADDING_MS,
    silenceDurationMs: DEFAULT_SILENCE_DURATION_MS,
} as const;

function LiveKitRoomConversationComponent({
    agentIdentifier,
    extraTokenAttributes,
    uiContext,
    toggleAudioOrText,
    conversationId,
    onDisconnected,
    spacingBeneathLowestButton,
}: ConnectLiveKitRoomProps) {
    const tokenAttributes: TokenAttributes = useMemo(
        () => ({ agentIdentifier, ...defaultTurnDetection, ...extraTokenAttributes }),
        [agentIdentifier, extraTokenAttributes],
    );

    useLogLaunchEvent({
        eventType: 'tutorbot:started_voice_agent_conversation',
        agentIdentifier,
        extraTokenAttributes,
    });

    const { connectionDetails } = useConnectionDetails({
        tokenAttributes,
    });

    // conversationContext is just passed through to the server and stored in tutor_bot_conversations.
    // Adding all of the tokenAttributes gives us all of the context information that the agent has
    // for managing the conversation
    useStoreConversationContext({ conversationId, conversationContext: tokenAttributes });

    return (
        <div className="h-full content-center">
            <LiveKitRoom
                token={connectionDetails?.participantToken}
                serverUrl={connectionDetails?.serverUrl}
                connect={!!connectionDetails}
                audio
                video={false}
                onMediaDeviceFailure={onDeviceFailure}
                onDisconnected={onDisconnected}
                className="items-between flex h-full flex-col"
            >
                <ConnectedLiveKitRoomUi
                    conversationId={conversationId}
                    uiContext={uiContext}
                    toggleAudioOrText={toggleAudioOrText}
                    spacingBeneathLowestButton={spacingBeneathLowestButton}
                />
            </LiveKitRoom>
        </div>
    );
}

function onDeviceFailure(error?: MediaDeviceFailure) {
    // eslint-disable-next-line no-console
    console.error(error);
    alert(
        'Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab',
    );
}

export const LiveKitRoomConversation = memo(
    LiveKitRoomConversationComponent,
) as typeof LiveKitRoomConversationComponent;
