import { type BotUiContext } from 'TutorBotConversation';
import { memo } from 'react';
import { RoomAudioRenderer } from '@livekit/components-react';
import { BlockLink } from 'BlockLink';
import { useTranslation } from 'react-i18next';
import { RoomEvent } from 'livekit-client';
import { useStoreAudioTranscriptions } from '../hooks/useStoreAudioTranscriptions';
import { AudioVisualizer } from './AudioVisualizer';
import { AudioControlBar } from './AudioControlBar/AudioControlBar';
import { useRoomEventListener } from '../hooks/useRoomEventListener';
import { getAlgorithmMetadataEventListener, errorEventListener } from '../utils';

// In order to get access to the room context, we split out the UI inside the LiveKitRoom
// into a separate component.
function ConnectedLiveKitRoomUiComponent({
    toggleAudioOrText,
    uiContext,
    conversationId,
    spacingBeneathLowestButton,
}: {
    toggleAudioOrText: () => void;
    uiContext: BotUiContext;
    conversationId: string;
    spacingBeneathLowestButton: number;
}) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'TutorBotVoiceAgent.TutorBotVoiceAgent' });
    useStoreAudioTranscriptions({ conversationId, uiContext });
    useRoomEventListener(
        RoomEvent.DataReceived,
        getAlgorithmMetadataEventListener({ conversationId }),
        errorEventListener,
    );
    return (
        <>
            <AudioVisualizer />
            <div className="flex w-full flex-col items-center justify-end">
                <AudioControlBar />
                <div className="flex w-full items-center justify-center" style={{ height: spacingBeneathLowestButton }}>
                    <BlockLink onPress={toggleAudioOrText} color="slate-grey">
                        {t('switchToTextMode')}
                    </BlockLink>
                </div>
            </div>
            <RoomAudioRenderer />
            {/* <NoAgentNotification /> */}
            {/* We're no longer showing the LiveTranscript in the UI, but you can uncomment this if it's useful for debugging */}
            {/* <LiveTranscript token={connectionDetails?.participantToken} /> */}
        </>
    );
}

export const ConnectedLiveKitRoomUi = memo(ConnectedLiveKitRoomUiComponent) as typeof ConnectedLiveKitRoomUiComponent;
