import { AdmissionOfferStatus } from 'AdmissionOffer';
import { AoiRecordType } from 'ProgramAoi';
import { isPendingProgramApplication } from 'ProgramApplication';
import { ProgramInclusionStatus } from 'ProgramInclusion';
import { type CurrentUserIguanaObject, getAoiRecord, getNotJoiningProgram } from 'Users';
import { UnlockedTutorBotChatState } from 'TutorBotChat';
import { type BotPageState, LockedBotPageState, InvalidBotPageState } from './TutorBotAiAdvisorChat.types';

// This function only gets called if we've already determined that there is not a global
// reason for TutorBot to be locked, as defined in determineTutorBotLockedState. See how this is
// used in useBotPageStatusConfig.
export function determineBotPageState(
    currentUser: CurrentUserIguanaObject | null,
    botPageAccessForProspectiveStudents: boolean,
): BotPageState {
    if (!currentUser?.hasBotPageAccess) return InvalidBotPageState.NoAccess;

    // See Statsig gate 'ai_advisor_access_for_prospective_students'.
    if (botPageAccessForProspectiveStudents) {
        // TODO: this may become more complex in a future ticket where we will display different
        // messages to students based on their status.
        return UnlockedTutorBotChatState.TutorBotUnlocked;
    }

    const programApplication = getAoiRecord(currentUser, AoiRecordType.ProgramApplication);
    const admissionOffer = getAoiRecord(currentUser, AoiRecordType.AdmissionOffer);
    const programInclusion = getAoiRecord(currentUser, AoiRecordType.ProgramInclusion);

    const pendingApplication = !!programApplication && isPendingProgramApplication(programApplication);
    const notJoiningProgram = getNotJoiningProgram(currentUser);

    if (currentUser.canUseBotPageWithoutProgramInclusion) return UnlockedTutorBotChatState.TutorBotUnlocked;

    if (programInclusion?.status === ProgramInclusionStatus.Included) {
        if (programInclusion.contentUnlocked) {
            return UnlockedTutorBotChatState.TutorBotUnlocked;
        }

        // FIXME: We should add a specific state for these users. For now, we'll show them the
        // RegisteredNotCurrent state, even though the text is not quite right.
        // if (programInclusion.current) {
        //     return UnlockedAiAdvisorState.AdvisorContentLocked;
        // }

        return LockedBotPageState.RegisteredNotCurrent;
    }

    if (programInclusion?.status === ProgramInclusionStatus.Graduated) {
        return UnlockedTutorBotChatState.TutorBotUnlocked;
    }

    if (programInclusion?.status === ProgramInclusionStatus.WillNotGraduate) {
        return LockedBotPageState.ExpelledOrWithdrawn;
    }

    if (admissionOffer?.status === AdmissionOfferStatus.OfferedAdmission) {
        return LockedBotPageState.OfferedAdmission;
    }

    if (notJoiningProgram) {
        return LockedBotPageState.NotJoiningProgram;
    }

    if (pendingApplication && programApplication.invitedToInterview && !programApplication.interviewScheduled) {
        return LockedBotPageState.AppliedInterviewPending;
    }

    if (pendingApplication) {
        return LockedBotPageState.Applied;
    }

    return LockedBotPageState.Signup;
}
