import { useMemo } from 'react';
import { fetchBrandConfig, Brand } from 'AppBranding';
import clsx from 'clsx';
import { useErrorLogService } from 'ErrorLogging';
import {
    type HelpscoutArticleSourceLocation,
    HelpScoutCollectionId,
    HelpScoutCollectionUrl,
} from '../../types/AnswerSource.types';
import { linkStyles } from './shared';
import { helpScoutCollectionTitle } from '../../utils/helpScoutCollectionTitle';

export default function HelpScoutCollectionLink({ link }: { link: HelpscoutArticleSourceLocation }) {
    const collectionId = link.collectionId;
    const ErrorLogService = useErrorLogService();

    // The branding check doesn't warn Sentry if we encounter an unknown collection ID, but the title lookup (below) does.
    const isValarLink = useMemo(
        () =>
            [
                HelpScoutCollectionId.ValarStudentHandbook,
                HelpScoutCollectionId.ValarApplicants,
                HelpScoutCollectionId.ValarStudentResources,
                HelpScoutCollectionId.ValarGeneral,
                HelpScoutCollectionId.ValarResourcesStudentResources,
            ].includes(collectionId),
        [collectionId],
    );
    const brandConfig = useMemo(() => fetchBrandConfig(isValarLink ? Brand.valar : Brand.quantic), [isValarLink]);

    const collectionTitle = useMemo(
        () => helpScoutCollectionTitle(collectionId, ErrorLogService),
        [collectionId, ErrorLogService],
    );

    const linkToCollection = useMemo(() => {
        const collectionName = Object.keys(HelpScoutCollectionId)[
            Object.values(HelpScoutCollectionId).indexOf(collectionId)
        ] as keyof typeof HelpScoutCollectionUrl;
        return HelpScoutCollectionUrl[collectionName];
    }, [collectionId]);

    return (
        <div className="relative flex items-start">
            <img
                className="absolute left-[2px] -mt-1 max-h-[25px] rtl:left-auto rtl:right-0"
                src={brandConfig.appMenuProgramLogo as string}
                alt={collectionTitle}
            />
            <a
                href={linkToCollection}
                data-testid="stream-link"
                target="_blank"
                rel="noreferrer"
                className={clsx(linkStyles, 'ps-8')}
            >
                {collectionTitle}
            </a>
        </div>
    );
}
